import { createSelector } from 'reselect';
import { titleSelector, subtitleSelector, isLiveSelector, pictureSquareSelector, } from './dataSelector';
import { currentChannelNameSelector } from './channelSelector';
import { currentProgramPictureSquareSelector } from './programSelector';
/**
 * @returns {function(object): string}
 */
export var mediaSessionTitleSelector = createSelector(titleSelector, function (title) {
    if (!title) {
        return undefined;
    }
    return title;
});
/**
 * @returns {function(object): ?string}
 */
export var mediaSessionArtistSelector = createSelector(isLiveSelector, currentChannelNameSelector, subtitleSelector, function (isLive, name, subtitle) {
    if (isLive) {
        return name;
    }
    if (!subtitle) {
        return undefined;
    }
    return subtitle;
});
/**
 * @returns {function(object): string}
 */
export var mediaSessionArtworkSrcSelector = createSelector(isLiveSelector, currentProgramPictureSquareSelector, pictureSquareSelector, function (isLive, livePictureSquare, hapiPictureSquare) {
    return isLive ? livePictureSquare : hapiPictureSquare;
});
/**
 * @returns {function(object): ?[string]}
 */
export var mediaSessionArtworkSelector = createSelector(mediaSessionArtworkSrcSelector, function (src) { return (src ? [{ src: src }] : undefined); });
