var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { debugSelector, liveTimeSelector } from '@oneplayer/selectors';
import { isLiveProgramChannel } from '@canalplus/oneplayer-utils';
import { setupLocalProxy, destroyLocalProxy } from './proxy';
/**
 * The goal of this simple function is to setup local proxy when expert mode is requested
 * from the demo page through the debug object passed from the config
 * @param state - the entire Redux store state
 */
export function DEBUGSetupLocalProxyExpertMode(state) {
    var debugParams = debugSelector(state);
    // Only for DEV purposes
    if (__DEV__ && !__LOCAL__) {
        // make sure we do not have an other request interceptor ongoing before creating a new one
        destroyLocalProxy();
        if (debugParams === null || debugParams === void 0 ? void 0 : debugParams.enableExpertMode) {
            setupLocalProxy(debugParams);
        }
    }
}
/**
 * @param state - the entire Redux store state
 * @param program - the ongoing program
 * @returns the ongoing program with a rating 5 to trigger parentalCode
 */
export function DEBUGSetOnGoingProgramCat5(state, program) {
    if (__DEV__) {
        var debugParams = debugSelector(state);
        var liveTime = liveTimeSelector(state) || new Date();
        if ((debugParams === null || debugParams === void 0 ? void 0 : debugParams.enableCat5) && isLiveProgramChannel(program, liveTime)) {
            return __assign(__assign(__assign({}, program), { parentalCodeLock: {
                    isLockedByDefault: true,
                    isUserOverloadable: false,
                } }), ((debugParams === null || debugParams === void 0 ? void 0 : debugParams.morality) && {
                morality: debugParams === null || debugParams === void 0 ? void 0 : debugParams.morality,
            }));
        }
    }
    return program;
}
/**
 * Simulate ParentalRatingHours so it's always possible to have rating 5 content
 * This way we could always display ParentalCode modal.
 * @param state - the entire Redux store state
 * @param parentalRatingHours - the start and end hours for parental rating
 * @returns the new parental rating hours
 */
export function DEBUGSimulateParentalRatingHours(state, parentalRatingHours) {
    var _a;
    if (__DEV__) {
        var debugParams = debugSelector(state);
        return (_a = debugParams === null || debugParams === void 0 ? void 0 : debugParams.parentalRatingHours) !== null && _a !== void 0 ? _a : parentalRatingHours;
    }
    return parentalRatingHours;
}
/**
 * Simulate Parental code not created
 * @param state - the entire Redux store state
 * @param data - the ACM response
 * @returns the new ACM response
 */
export function DEBUGParentalCodeNotCreated(state, data) {
    var _a;
    if (__DEV__) {
        if ((_a = debugSelector(state)) === null || _a === void 0 ? void 0 : _a.mockParentalCodeNotCreated) {
            return {
                exists: false,
                isParentalCodeActivatedForUserOverloadableContents: false,
            };
        }
    }
    return data;
}
/**
 *
 * @param config - the config
 * @returns the new config
 */
export function DEBUGModifyConfig(config) {
    var _a, _b, _c, _d, _e;
    if (__DEV__) {
        var newConfig = config;
        if (((_a = newConfig.debug) === null || _a === void 0 ? void 0 : _a.enableFeatureFlags) &&
            newConfig.debug.featureFlag === 'all') {
            newConfig = __assign(__assign({}, newConfig), { featureFlags: __assign(__assign({}, newConfig.featureFlags), { shouldShowPlaybackRate: true, shouldShowAudioOnly: true, shouldShowDyslexicTextTrack: true }) });
        }
        if (((_b = newConfig.debug) === null || _b === void 0 ? void 0 : _b.enableFeatureFlags) &&
            newConfig.debug.featureFlag === 'audioOnly') {
            newConfig = __assign(__assign({}, newConfig), { featureFlags: __assign(__assign({}, newConfig.featureFlags), { shouldShowAudioOnly: true }) });
        }
        if (((_c = newConfig.debug) === null || _c === void 0 ? void 0 : _c.enableFeatureFlags) &&
            newConfig.debug.featureFlag === 'playbackRate') {
            newConfig = __assign(__assign({}, newConfig), { featureFlags: __assign(__assign({}, newConfig.featureFlags), { shouldShowPlaybackRate: true }) });
        }
        if (((_d = newConfig.debug) === null || _d === void 0 ? void 0 : _d.enableFeatureFlags) &&
            newConfig.debug.featureFlag === 'dyslexicTextTrack') {
            newConfig = __assign(__assign({}, newConfig), { featureFlags: __assign(__assign({}, newConfig.featureFlags), { shouldShowDyslexicTextTrack: true }) });
        }
        if ((_e = newConfig.debug) === null || _e === void 0 ? void 0 : _e.showTvLoadingScreen) {
            newConfig = __assign(__assign({}, newConfig), { params: __assign(__assign({}, newConfig.params), { showTvLoadingScreen: true }) });
        }
        return newConfig;
    }
    return config;
}
