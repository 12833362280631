import { createSelector } from 'reselect';
import { liveTimeSelector, isLiveEdgeSelector } from './coreSelector';
import { currentChannelSelector } from './channelSelector';
import { currentProgramSelector, antiAdSkipCountdownValueSelector, isAntiAdSkipSequenceActiveSelector, } from './programSelector';
import { isLiveSelector } from './dataSelector';
import { errorSelector } from './errorSelector';
import { canStartOver } from '../utils/live';
/**
 * @returns {function(object): boolean}
 */
export var canStartOverSelector = createSelector(liveTimeSelector, currentChannelSelector, currentProgramSelector, isLiveSelector, function (liveTime, channel, program, isLive) {
    return isLive ? canStartOver(channel, program, liveTime) : true;
});
/**
 * Case where we want to block button action but not disable it
 * @returns {function(object): boolean}
 */
export var isButtonActionBlockedSelector = createSelector(isAntiAdSkipSequenceActiveSelector, antiAdSkipCountdownValueSelector, function (isAntiAdSkipSequenceActive, antiAdSkipCountdownValue) {
    return isAntiAdSkipSequenceActive && antiAdSkipCountdownValue > 0;
});
/**
 * @returns {function(object): boolean}
 */
export var canSeekBackwardSelector = createSelector(currentProgramSelector, canStartOverSelector, isLiveSelector, errorSelector, isButtonActionBlockedSelector, function (program, canSO, isLive, error, isButtonActionBlocked) {
    return isLive
        ? !!(program && program.startTime) &&
            canSO &&
            !error &&
            !isButtonActionBlocked
        : true;
});
/**
 * @returns {function(object): boolean}
 */
export var canSeekForwardSelector = createSelector(isLiveSelector, canStartOverSelector, isLiveEdgeSelector, errorSelector, isButtonActionBlockedSelector, function (isLive, canSO, isLiveEdge, error, isButtonActionBlocked) {
    return isLive ? !isLiveEdge && canSO && !error && !isButtonActionBlocked : true;
});
