import React, { PureComponent } from 'react';
import Polyglot from 'node-polyglot';

const createProvider =
  (Provider) =>
  // We change the default interpolation to support our error messages
  ({ locale, phrases, update, interpolation = { prefix: '{', suffix: '}' } }) =>
  (WrappedComponent) => {
    class ConnectedComponent extends PureComponent {
      static getT({
        locale: tLocale,
        phrases: tPhrases = {},
        interpolation: tInterpolation,
      }) {
        const polyglotData = {
          locale: tLocale,
          phrases: tPhrases,
          interpolation: tInterpolation,
        };
        const polyglot = new Polyglot(polyglotData);
        return (ref, args = {}) => polyglot.t(ref, args);
      }

      constructor(props) {
        super(props);
        this.state = {
          t: ConnectedComponent.getT({ locale, phrases, interpolation }),
          u: update || this.getU(),
        };
      }

      getU() {
        return ({ locale: uLocale, phrases: uPhrases = {} }) => {
          this.setState({
            t: ConnectedComponent.getT({ locale: uLocale, phrases: uPhrases }),
          });
        };
      }

      render() {
        const { t, u } = this.state;
        return (
          <Provider value={{ t, u, locale }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <WrappedComponent {...this.props} />
          </Provider>
        );
      }
    }

    ConnectedComponent.displayName = `I18n.Provider(${
      WrappedComponent.displayName || WrappedComponent.name || 'Unknown'
    })`;

    return ConnectedComponent;
  };

export default createProvider;
