import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { i18nConsumer } from '../../../../utils/i18n/i18n';
import Button from '../button/Button';
var styles = require('../tverror/TvErrorInfos.css');
/**
 * @param arg0 all the props of the component
 * @param arg0.t function that translate texts in the UI language
 * @param arg0.triggerExit the trigger exit function, to exit the player
 * @param arg0.i18nKey the i18n key
 * @returns a component with the errors triggered by the InitErrorManager before the player is fully instanciated
 * We don't have access to the redux store, and react-keys
 */
var TvInitError = function (_a) {
    var t = _a.t, triggerExit = _a.triggerExit, i18nKey = _a.i18nKey;
    return (_jsxs("div", { className: styles.tvErrorInfos, children: [_jsx("p", { className: styles.header, children: t('texts.ErrorTitle') }), _jsx("p", { className: styles.message, children: t(i18nKey) }), _jsx("div", { className: styles.buttons, children: _jsx(Button, { autoFocus: true, onClick: triggerExit, customStyle: classNames(styles.tvErrorButton, styles.tvErrorButtonActive), "data-testid": "errorInfo", children: t('texts.BackToHome') }) })] }));
};
export default i18nConsumer(TvInitError);
