import {
  BusinessTypes,
  KeyMapping,
  LanguageTypes,
} from '@canalplus/oneplayer-constants';
import {
  IAPICallbacksRaw,
  IAPIConfig,
  IAPIConfigEventsRaw,
  IAPIConfigFeatureFlags,
  IAPIConfigParams,
  IAPIConfigParamsRaw,
  IAPIConfigRaw,
  IDeviceInfo,
  IMappingConfig,
  TOfferZone,
  TPlatform,
} from '@canalplus/oneplayer-types';

import { logger } from '../logger';
import { isEmptyObject, isNullOrUndefined, isObject } from '../object';

const {
  DEVICE_TYPES: {
    CHROMECAST,
    G9_TECHNICOLOR,
    G9L_TECHNICOLOR,
    G9MINI_HUMAX,
    G9MINIVE_HUMAX,
    G10_HUMAX,
    G10_SAGEMCOM,
    G10CPI_SAGEMCOM,
    G11,
    PLAYSTATION_4,
    PLAYSTATION_5,
    DESKTOP_MOBILE,
    PHILIPS,
    SAMSUNG,
    LG,
    HISENSE,
    FIRETVREACT,
    TV_KEY,
    UWP,
    XBOX,
    SFR_STB7_IPTV,
    SFR_STB8_IPTV,
    SFR_STB8_CABLE,
  },
  XBOX_DEVICE_NAMES: { XBOX_SERIES_S },
  VARIANTS,
  OFFER_ZONE: { CPFRA },
  PLATFORMS: { HAPI, LIVE, MULTI, PLATFORM_DIRECTFILE },
  STREAMING_TYPES: { LOCAL },
} = BusinessTypes;

/**
 * A little util function that permit to assert a boolean property that is optional.
 * @param prop - the value to parse
 * @param defaultValue - default value if the provided value is null or undefined
 * @returns a normalize version of it.
 */
function parseOptionalBooleanProperty(
  prop: boolean | undefined,
  defaultValue = false,
): boolean {
  const propValue = isNullOrUndefined(prop) ? defaultValue : prop;
  if (typeof propValue !== 'boolean') {
    logger.warnOnce(`Params Options for '${prop}' is incorrectly set:
      Possible values:
       - a 'boolean'
      Fallback value: '${defaultValue}'
      Provided: '${prop}'
    `);
    return defaultValue;
  }
  return propValue;
}

/**
 * Parse the callbacks config property and assert that every property is well defined as a function.
 * If the property given is a not a function, it will be deleted of the config, in order to keep data we receive safe.
 * @param onePlayerConfigCallbacks The callbacks object as defined by ppl that integrate us.
 * @returns A sanitized version of the callbacks config.
 */
export function parseConfigCallbacks(
  onePlayerConfigCallbacks: IAPICallbacksRaw,
): IAPICallbacksRaw {
  if (
    isNullOrUndefined(onePlayerConfigCallbacks) ||
    isEmptyObject(onePlayerConfigCallbacks)
  ) {
    return {};
  }

  const callbackNames = Object.keys(onePlayerConfigCallbacks) as Array<
    keyof IAPICallbacksRaw
  >;
  return callbackNames.reduce((callbacks, callbackName) => {
    if (typeof onePlayerConfigCallbacks[callbackName] === 'function') {
      return {
        ...callbacks,
        [callbackName]: onePlayerConfigCallbacks[callbackName],
      };
    }

    logger.warnOnce(`Callbacks params '${callbackName}' is incorrectly set:
      Possible value:
        - a 'function'
      Since, the property cant be used, we will delete it from the Callbacks config object.
    `);
    return callbacks;
  }, {} as IAPICallbacksRaw);
}

/**
 * Parse the events config property and assert that every property is well defined as a function.
 * If the property given is a not a function, it will be deleted of the config, in order to keep data we receive safe.
 * @param onePlayerConfigEvents The events object as defined by ppl that integrate us.
 * @returns A sanitized version of the events config.
 */
export function parseConfigEvents(
  onePlayerConfigEvents: IAPIConfigEventsRaw,
): IAPIConfigEventsRaw {
  if (
    isNullOrUndefined(onePlayerConfigEvents) ||
    isEmptyObject(onePlayerConfigEvents)
  ) {
    return {};
  }

  const eventNames = Object.keys(onePlayerConfigEvents) as Array<
    keyof IAPIConfigEventsRaw
  >;
  return eventNames.reduce((events, eventName) => {
    if (typeof onePlayerConfigEvents[eventName] === 'function') {
      return {
        ...events,
        [eventName]: onePlayerConfigEvents[eventName],
      };
    }

    logger.warnOnce(`Events params '${eventName}' is incorrectly set:
      Possible value:
        - a 'function'
      Since, the property cant be used, we will delete it from the Events config object.
    `);
    return events;
  }, {} as IAPIConfigEventsRaw);
}

/**
 * Parse the deviceInfo config property and assert that every property is well defined.
 * @param _deviceInfo - The deviceInfo object
 * @returns deviceInfo - The sanitized/parsed version of _deviceInfo
 */
export function parseConfigDeviceInfo(
  _deviceInfo: IDeviceInfo,
): IDeviceInfo | null {
  const deviceInfo =
    !isEmptyObject(_deviceInfo) && !isNullOrUndefined(_deviceInfo)
      ? _deviceInfo
      : null;
  if (deviceInfo !== null) {
    if (deviceInfo.resolutionCompatibility) {
      const resolutionCompatibilityWarnMessage = `'resolutionCompatibility' in 'deviceInfo' is incorrectly set:
                Possible values:
                    - an object: '{
                        hd?: boolean
                        uhd?: boolean
                    }'
                    - undefined
                Fallback value: 'undefined'
                Provided: '${deviceInfo.resolutionCompatibility}'
                `;

      if (!isObject(deviceInfo.resolutionCompatibility)) {
        logger.warnOnce(resolutionCompatibilityWarnMessage);
        deviceInfo.resolutionCompatibility = undefined;
      } else {
        const { hd, uhd } = deviceInfo.resolutionCompatibility;

        if (hd !== undefined && typeof hd !== 'boolean') {
          logger.warnOnce(resolutionCompatibilityWarnMessage);
          deviceInfo.resolutionCompatibility.hd = undefined;
        }

        if (uhd !== undefined && typeof uhd !== 'boolean') {
          logger.warnOnce(resolutionCompatibilityWarnMessage);
          deviceInfo.resolutionCompatibility.uhd = undefined;
        }

        if (hd === undefined && uhd === undefined) {
          deviceInfo.resolutionCompatibility = undefined;
        }
      }
    }
    if (deviceInfo.hdrCompatibility) {
      const hdrCompatibilityWarnMessage = `'hdrCompatibility' in 'deviceInfo' is incorrectly set:
        Possible values:
        - an object: '{
            hdr10?: boolean
            hdr10Plus?: boolean
            dolbyVision?: boolean
            hlg?: boolean
        }'
        - undefined
        Fallback value: 'undefined'
        Provided: '${deviceInfo.hdrCompatibility}'
        `;
      if (!isObject(deviceInfo.hdrCompatibility)) {
        logger.warnOnce(hdrCompatibilityWarnMessage);
        deviceInfo.hdrCompatibility = undefined;
      } else {
        const { hdr10, hdr10Plus, dolbyVision, hlg } =
          deviceInfo.hdrCompatibility;

        if (hdr10 !== undefined && typeof hdr10 !== 'boolean') {
          logger.warnOnce(hdrCompatibilityWarnMessage);
          deviceInfo.hdrCompatibility.hdr10 = undefined;
        }

        if (hdr10Plus !== undefined && typeof hdr10Plus !== 'boolean') {
          logger.warnOnce(hdrCompatibilityWarnMessage);
          deviceInfo.hdrCompatibility.hdr10Plus = undefined;
        }

        if (dolbyVision !== undefined && typeof dolbyVision !== 'boolean') {
          logger.warnOnce(hdrCompatibilityWarnMessage);
          deviceInfo.hdrCompatibility.dolbyVision = undefined;
        }

        if (hlg !== undefined && typeof hlg !== 'boolean') {
          logger.warnOnce(hdrCompatibilityWarnMessage);
          deviceInfo.hdrCompatibility.hlg = undefined;
        }

        if (
          hdr10 === undefined &&
          hdr10Plus === undefined &&
          dolbyVision === undefined &&
          hlg === undefined
        ) {
          deviceInfo.hdrCompatibility = undefined;
        }
      }
    }
    if (!isNullOrUndefined(deviceInfo.deviceName)) {
      if (typeof deviceInfo.deviceName !== 'string') {
        logger.warnOnce(`'deviceName' in 'deviceInfo' is incorrectly set:
                  Possible values:
                   - string
                   - undefined
                   - null
                  Fallback value: 'undefined'
                  Provided: '${deviceInfo.deviceName}'
                `);
        deviceInfo.deviceName = undefined;
      } else if (deviceInfo.deviceName === XBOX_SERIES_S) {
        // XBOX_SERIES_S Doesn't support UHD
        deviceInfo.resolutionCompatibility = {
          hd: true,
          uhd: false,
        };
      }
    }
  }
  return deviceInfo;
}

/**
 * Parse the featureFlags config property and assert that every property is well defined.
 * @param _featureFlags - The featureFlags object
 * @returns featureFlags - The sanitized/parsed version of _featureFlags
 */
export function parseConfigFeatureFlags(
  _featureFlags: IAPIConfigRaw['featureFlags'],
): IAPIConfigFeatureFlags {
  if (isEmptyObject(_featureFlags) || isNullOrUndefined(_featureFlags)) {
    return {
      isHdrRequestedOnLivePlatform: undefined,
      isLowLatencyRequested: false,
    };
  }

  const featureFlagsWarnMessage = `'featureFlags' is incorrectly set:
  Possible values:
    - an object: '{
      hdr?: boolean | undefined (if u want the Player to decide))
      lowLatency?: boolean
    }'
  Fallback value: '{ hdr: undefined, lowLatency: false }'
  Provided: '${_featureFlags}'`;

  let { hdr } = _featureFlags;
  const { lowLatency } = _featureFlags;

  if (hdr !== undefined && typeof hdr !== 'boolean') {
    logger.warnOnce(featureFlagsWarnMessage);
    hdr = undefined;
  }

  if (lowLatency !== undefined && typeof lowLatency !== 'boolean') {
    logger.warnOnce(featureFlagsWarnMessage);
  }

  return {
    isHdrRequestedOnLivePlatform: hdr,
    isLowLatencyRequested: lowLatency === true,
  };
}

/**
 * Will parse the params object of the config we receive from ppl that integrate us.
 * Then, warn when it doesnt satisfy our need and fallback on expected value when possible.
 * Also, normalize the object schema in order to be les prone to error.
 * @param paramsConfig The params object we receive from the config (provided by ppl that integrate us)
 * @param platform The platform used to play content (E.g: hapi,live,...)
 * @returns a normalize version of the object understood by the application.
 */
function parseConfigParams(
  paramsConfig: IAPIConfigParamsRaw,
  platform: TPlatform,
): IAPIConfigParams {
  const autoplay = parseOptionalBooleanProperty(paramsConfig?.autoplay);
  const muted = parseOptionalBooleanProperty(paramsConfig?.muted);

  const showPosterHeading = parseOptionalBooleanProperty(
    paramsConfig.showPosterHeading,
    true,
  );
  const showContentHeading = parseOptionalBooleanProperty(
    paramsConfig.showContentHeading,
    true,
  );
  const showContentInfo = parseOptionalBooleanProperty(
    paramsConfig.showContentInfo,
    true,
  );
  const showContentControls = parseOptionalBooleanProperty(
    paramsConfig.showContentControls,
    true,
  );
  const showContentTitle = parseOptionalBooleanProperty(
    paramsConfig.showContentTitle,
    true,
  );
  const showAvailabilityDate = parseOptionalBooleanProperty(
    paramsConfig.showAvailabilityDate,
    true,
  );

  const enableAd = parseOptionalBooleanProperty(paramsConfig.enableAd, true);
  const enableParentalRating = parseOptionalBooleanProperty(
    paramsConfig.enableParentalRating,
    true,
  );
  const enableAirplay = parseOptionalBooleanProperty(
    paramsConfig.enableAirplay,
    true,
  );
  const enableCcast = parseOptionalBooleanProperty(
    paramsConfig.enableCcast,
    true,
  );
  const enablePiP = parseOptionalBooleanProperty(paramsConfig.enablePiP, true);
  const isTabKeyNavigationCapturedByPlayer = parseOptionalBooleanProperty(
    paramsConfig.isTabKeyNavigationCapturedByPlayer,
    true,
  );
  const isNationalChannelNumbering = parseOptionalBooleanProperty(
    paramsConfig.isNationalChannelNumbering,
    false,
  );
  const skipAuto = parseOptionalBooleanProperty(paramsConfig.skipAuto);
  const fetchContentSeries = parseOptionalBooleanProperty(
    paramsConfig.fetchContentSeries,
    true,
  );

  const startLiveProgramFromBeginning = parseOptionalBooleanProperty(
    paramsConfig.startLiveProgramFromBeginning,
  );
  const nextEpisodeAutoplay = parseOptionalBooleanProperty(
    paramsConfig.nextEpisodeAutoplay,
    true,
  );

  let endingBehavior = isNullOrUndefined(paramsConfig.endingBehavior)
    ? 'poster'
    : paramsConfig.endingBehavior;
  if (!['loop', 'poster', 'none'].includes(endingBehavior)) {
    logger.warnOnce(`Params option 'endingBehavior' parameter is incorrectly set:
      Possible values:
       - 'loop'
       - 'poster'
       - 'none'
      Fallback to 'poster'
      Provided: '${endingBehavior}'
    `);
    endingBehavior = 'poster';
  }

  /**
   * unauthorizedChannelErrorContent is an object that may contain the config to display on a unauthorized channel web page
   * e.g: { buttonLabel: string, buttonUrl: string, textLabel: string }
   */
  let { unauthorizedChannelErrorContent } = paramsConfig;
  if (
    isNullOrUndefined(unauthorizedChannelErrorContent) ||
    Array.isArray(unauthorizedChannelErrorContent)
  ) {
    unauthorizedChannelErrorContent = null;
  } else if (
    typeof unauthorizedChannelErrorContent.buttonLabel !== 'string' ||
    typeof unauthorizedChannelErrorContent.buttonUrl !== 'string' ||
    typeof unauthorizedChannelErrorContent.textLabel !== 'string'
  ) {
    unauthorizedChannelErrorContent = null;
    logger.warnOnce(`Params option 'unauthorizedChannelErrorContent' parameter is incorrectly set:
      Possible values:
      - { buttonLabel: string, buttonUrl: string, textLabel: string }
      - null
      Fallback to 'null'
      Provided: '${unauthorizedChannelErrorContent}'
    `);
  }

  let startAtEnsured: number | Date | null = null;

  // DEPRECATED - If timeFragment exists, it overrides the value of startAtEnsured
  const timeFragment = isNullOrUndefined(paramsConfig.timeFragment)
    ? null
    : paramsConfig.timeFragment;
  if (timeFragment) {
    logger.warnOnce('OnePlayer > warning', 'timeFragment API is deprecated');
    startAtEnsured = timeFragment.start || undefined;
  }

  // Ignore startAt for hapi content as we get the value from a spyro call
  const startAt =
    isNullOrUndefined(paramsConfig.startAt) || platform === HAPI
      ? null
      : paramsConfig.startAt;
  if (startAt !== null) {
    if (typeof startAt !== 'string' && typeof startAt !== 'number') {
      logger.warnOnce(`Params option 'startAt' parameter is incorrectly set:
      Possible values
       - a 'string'
       - a 'number'
       Fallback to 'null'\n
       Provided: '${startAt}'
    `);
    } else if (platform === LIVE) {
      if (typeof startAt === 'number') {
        // Convert startAt into a date
        // We multiply the startAt by 1000 in the second case in order to be a valid timestamp
        // because JavaScript uses milliseconds internally while normal UNIX timestamps are usually in seconds
        startAtEnsured = new Date(startAt * 1000);
      } else {
        // Verify the startAt is given with a 8601 format
        const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
        if (dateRegex.test(startAt)) {
          startAtEnsured = new Date(startAt);
        }
      }

      if (startAtEnsured) {
        // Verify if the date is valid
        if (startAtEnsured.toString() === 'Invalid Date') {
          logger.warnOnce('OnePlayer > warning', 'startAt is not a valid date');
          startAtEnsured = null;
        }
      }
    } else if (typeof startAt === 'string') {
      // If the platform is not live and startAt is a string, then we have to transform it into a number
      startAtEnsured = parseInt(startAt, 10);
    } else if (typeof startAt === 'number') {
      startAtEnsured = startAt;
    }
  }

  let theme = isNullOrUndefined(paramsConfig.theme)
    ? 'web'
    : paramsConfig.theme;
  if (!['web', 'tv'].includes(theme)) {
    logger.warnOnce(`Params option 'theme' parameter is incorrectly set:
      Possible values:
       - 'web'
       - 'tv'
      Fallback to 'web'
      Provided: '${theme}'
    `);
    theme = 'web';
  }

  let offerZone: TOfferZone = CPFRA;
  /**
   *
   * @param offerZoneScope - an offerZone value
   * @returns true if it's a valid offerZone and false otherwise
   */
  function typeGuardOfferZone(
    offerZoneScope: string | undefined,
  ): offerZoneScope is TOfferZone {
    if (
      offerZoneScope &&
      Object.values<string>(BusinessTypes.OFFER_ZONE).includes(offerZoneScope)
    ) {
      return true;
    }
    return false;
  }

  if (typeGuardOfferZone(paramsConfig.offerZone)) {
    offerZone = paramsConfig.offerZone;
  }

  const offerLocation = isNullOrUndefined(paramsConfig.offerLocation)
    ? null
    : paramsConfig.offerLocation;

  let broadcastId =
    isNullOrUndefined(paramsConfig.broadcastId) ||
    paramsConfig.broadcastId === ''
      ? null
      : String(paramsConfig.broadcastId);
  if (broadcastId !== null && typeof broadcastId !== 'string') {
    logger.warnOnce(`Params option 'broadcastId' parameter is incorrectly set:
      Possible values
       - a 'string'
       Fallback to 'null'
       Provided: '${broadcastId}'
    `);
    broadcastId = null;
  }

  const defaultKeyMapping =
    theme === 'tv' ? KeyMapping.TV_KEY_MAPPING : KeyMapping.WEB_KEY_MAPPING;

  let keyMapping = isNullOrUndefined(paramsConfig.keyMapping)
    ? defaultKeyMapping
    : paramsConfig.keyMapping;
  if (typeof keyMapping !== 'object') {
    logger.warnOnce(`Params option 'keyMapping' parameter is incorrectly set:
        Possible values
         - '{
           key: number | number[]
           ...
         }'
         Fallback to '${defaultKeyMapping}'
         Provided: '${paramsConfig.keyMapping}'
      `);
    keyMapping = defaultKeyMapping;
  }

  let url = isNullOrUndefined(paramsConfig.url) ? null : paramsConfig.url;
  if (platform === PLATFORM_DIRECTFILE && typeof url !== 'string') {
    logger.warnOnce(`Params option 'url' parameter is incorrectly set:
      Possible values
       - a 'string'
       /!/ required with 'directfile' platform
       Fallback to 'null'
       Provided: '${url}'
    `);
    url = null;
  } else if (platform !== PLATFORM_DIRECTFILE) {
    url = null;
  }

  let transport = isNullOrUndefined(paramsConfig.transport)
    ? null
    : paramsConfig.transport;
  if (
    transport &&
    !['dash', 'smooth', 'hls', 'directfile', 'local'].includes(transport)
  ) {
    logger.warnOnce(`Params option 'transport' parameter is incorrectly set:
      Possible values
       - 'dash'
       - 'smooth'
       - 'hls'
       - 'directfile'
       /!/ recommended with 'directfile' platform, or it will be detected automatically
       Fallback to 'null'
       Provided: '${transport}'
    `);
    transport = null;

    // if platform is directfile, we always want to keep the transport value
  } else if (platform !== PLATFORM_DIRECTFILE) {
    // On canal+ platform (live/vod) we always want to reset the transport except on PDL cases.
    if (platform !== HAPI || transport !== LOCAL) {
      transport = null;
    }
  }

  const exitButton = isNullOrUndefined(paramsConfig.exitButton)
    ? {
        showFullScreen: true,
        showWindowed: true,
      }
    : paramsConfig.exitButton;
  let showFullScreen = isNullOrUndefined(exitButton.showFullScreen)
    ? true
    : exitButton.showFullScreen;

  if (typeof showFullScreen !== 'boolean') {
    logger.warnOnce(`Params Options in 'exitButton' for 'showFullScreen' is incorrectly set:
        Possible values:
         - a 'boolean'
        Fallback value: 'true'
        Provided: '${showFullScreen}'
      `);
    showFullScreen = true;
  }

  let showWindowed = isNullOrUndefined(exitButton.showWindowed)
    ? true
    : exitButton.showWindowed;
  if (typeof showWindowed !== 'boolean') {
    logger.warnOnce(`Params Options in 'exitButton' for 'showWindowed' is incorrectly set:
        Possible values:
         - a 'boolean'
        Fallback value: 'true'
        Provided: '${showWindowed}'
      `);
    showWindowed = true;
  }

  let preferredLanguage = isNullOrUndefined(paramsConfig.preferredLanguage)
    ? undefined
    : paramsConfig.preferredLanguage;
  if (
    preferredLanguage !== undefined &&
    (typeof preferredLanguage.language !== 'string' ||
      typeof preferredLanguage.audioDescription !== 'boolean')
  ) {
    logger.warnOnce(`Params Options in 'preferredLanguage' is incorrectly set:
    Possible values:
     - an object: '{ language: string, audioDescription: boolean }'
     - undefined (if u want the Player to decide)
    Fallback value: 'undefined'
    Provided: '${preferredLanguage}'
  `);
    preferredLanguage = undefined;
  }

  let { preferredSubtitle } = paramsConfig;
  if (
    preferredSubtitle !== null &&
    preferredSubtitle !== undefined &&
    (typeof preferredSubtitle.language !== 'string' ||
      typeof preferredSubtitle.closedCaption !== 'boolean')
  ) {
    logger.warnOnce(`Params Options in 'preferredSubtitle' is incorrectly set:
    Possible values:
     - an object: '{ language: string, closedCaption: boolean }'
     - 'null' (if u want to disable subtitles)
     - 'undefined' (if u want the Player to decide)
    Fallback value: 'undefined'
    Provided: '${preferredSubtitle}'
  `);
    preferredSubtitle = undefined;
  }

  const contentDisruptingModalAt = isNullOrUndefined(
    paramsConfig?.contentDisruptingModalAt,
  )
    ? null
    : paramsConfig?.contentDisruptingModalAt;

  let unauthorizedChannelMode = isNullOrUndefined(
    paramsConfig.unauthorizedChannelMode,
  )
    ? 'qrCode'
    : paramsConfig.unauthorizedChannelMode;
  if (!['qrCode', 'callback'].includes(unauthorizedChannelMode)) {
    logger.warnOnce(`Params option 'unauthorizedChannelMode' parameter is incorrectly set:
      Possible values:
       - 'qrCode'
       - 'callback'
      Fallback to 'qrCode'
      Provided: '${unauthorizedChannelMode}'
    `);
    unauthorizedChannelMode = 'qrCode';
  }

  const maxCompatibleVideoOutput = isNullOrUndefined(
    paramsConfig?.maxCompatibleVideoOutput,
  )
    ? null
    : paramsConfig?.maxCompatibleVideoOutput;

  let manifestLoader = isNullOrUndefined(paramsConfig?.manifestLoader)
    ? null
    : paramsConfig.manifestLoader;

  if (manifestLoader && typeof manifestLoader !== 'function') {
    logger.warnOnce(`Params option 'manifestLoader' is incorrectly set:
        Possible values:
         - a 'function'
        Fallback to 'undefined'
        Provided: '${manifestLoader}'
      `);
    manifestLoader = null;
  }

  return {
    autoplay,
    muted,
    showPosterHeading,
    showContentHeading,
    showContentInfo,
    showContentControls,
    showContentTitle,
    showAvailabilityDate,
    enableAntiAdSkip: paramsConfig.enableAntiAdSkip,
    enableAd,
    enableParentalRating,
    enableAirplay,
    enableCcast,
    enablePiP,
    isTabKeyNavigationCapturedByPlayer,
    isNationalChannelNumbering,
    skipAuto,
    fetchContentSeries,
    startLiveProgramFromBeginning,
    nextEpisodeAutoplay,
    endingBehavior,
    unauthorizedChannelErrorContent,
    timeFragment,
    startAt: startAtEnsured,
    offerZone,
    offerLocation,
    theme,
    broadcastId,
    keyMapping,
    url,
    transport,
    preferredLanguage,
    preferredSubtitle,
    exitButton: {
      showFullScreen,
      showWindowed,
    },
    contentDisruptingModalAt,
    unauthorizedChannelMode,
    maxCompatibleVideoOutput,
    manifestLoader,
  };
}

/**
 * Parse the config we receive as parameter from the client.
 * Then, make sure that it satisfy our need in order to play a content.
 * Also, normalize the config we receive.
 * @param config The config we receive from the client that integrate us
 * @returns The parsed/normalized config ready to be used.
 */
function parseConfig(config: IAPIConfigRaw): IAPIConfig | null {
  if (isEmptyObject(config)) {
    logger.error(`Missing or empty config: A populated config is mandatory when initializing the OnePlayer.
    Go to https://gitlab.canalplus.pro/one-players/one-player-monorepo/-/wikis/1.-Public-Documentation/1.1-API-Instanciation
    `);
    return null;
  }

  const { content, platform, context } = config;

  if (![LIVE, HAPI, MULTI, PLATFORM_DIRECTFILE].includes(platform)) {
    logger.error(`You must provide a valid platform parameter:
      Here are the possible values:
       - 'live'
       - 'hapi'
       - 'multi'
       - 'directfile'
       Provided: '${platform}'
    `);
    return null;
  }

  if (platform === MULTI && !Array.isArray(content)) {
    logger.error(`You must provide a valid 'content' parameter:
      You asked for 'multi' platform
      Possible value:
       - '[{ content: '301', platform: 'live' }, ...]'
       Provided: '${content}'
    `);
    return null;
  }

  if (
    platform !== MULTI &&
    platform !== LIVE &&
    platform !== PLATFORM_DIRECTFILE &&
    (typeof content !== 'string' || content === '')
  ) {
    logger.error(`You must provide a valid 'content' parameter:
      Possible value (a string or an object):
       - { "epgId": 301 }
       - { "channelNumber": 4 }
       - "301" or "11950732_40099"
       Provided: '${content}'
       - "multi" is not working with a string for "content" parameter.
       - "live" could also work without "content", in case of initLiveTV retrieving logic.
       - "directfile" could also work without "content", in case of directfile content.
    `);
    return null;
  }

  let configContentValue = content;
  if (
    platform === LIVE &&
    (typeof content === 'string' || typeof content === 'number')
  ) {
    // Setting content parameter as a string when using live platform is not recommended,
    // we tend to use it as an object such as { epgId } or { channelNumber } instead
    configContentValue = { epgId: parseInt(content, 10) };
  }

  const possibleContext: Array<keyof IMappingConfig> = [
    'mycanal',
    'playstation',
    'samsung',
    'philips',
    'lg',
    'hisense',
    'firetvreact',
    'g9',
    'g9mini',
    'g10',
    'g11',
    'mycanalcos',
    'cplay',
    'intranet',
    'mycanalpl',
    'piwi',
    'teletoon',
    'orange',
    'sfr',
    'microsoft',
    'default',
  ];
  if (typeof context !== 'string') {
    logger.error(`You must provide a valid context parameter:
      Here are the possibles values:
       - 'mycanal'
       - 'philips'
       - 'samsung'
       - 'lg'
       - 'hisense'
       - 'firetvreact'
       - 'g9'
       - 'g9mini'
       - 'g10'
       - 'g11'
       - 'playstation'
       - 'canalplay'
       - 'mycanalcos'
       - 'mycanalcosbis'
       - 'mycanalpl'
       - 'mycanalch'
       - 'mapping'
       - 'canalplayvod'
       - 'piwi'
       - 'teletoon'
       - 'cnews'
       - 'cplus'
       - 'c8'
       - 'cstart'
       - 'orange'
       - 'microsoft'
       Provided: '${context}'
    `);
    return null;
  }
  if (!possibleContext.includes(context)) {
    return null;
  }

  let contentFunctionalType = isNullOrUndefined(config.contentFunctionalType)
    ? 'primary'
    : config.contentFunctionalType;
  if (
    platform === HAPI &&
    !['primary', 'trailer'].includes(contentFunctionalType)
  ) {
    logger.warnOnce(`'contentFunctionalType' parameter is incorrectly set but needed for hapi platform:
      Possible values:
       - 'primary'
       - 'trailer'
      Fallback to 'primary'
      Provided: '${contentFunctionalType}'
    `);
    contentFunctionalType = 'primary';
  }

  // If config.deviceType is not set, then we set 'web' as the default value
  let deviceType = isNullOrUndefined(config.deviceType)
    ? DESKTOP_MOBILE
    : config.deviceType;
  const possibleDeviceType: BusinessTypes.DEVICE_TYPES[] = [
    CHROMECAST,
    G9_TECHNICOLOR,
    G9L_TECHNICOLOR,
    G9MINI_HUMAX,
    G9MINIVE_HUMAX,
    G10_HUMAX,
    G10_SAGEMCOM,
    G10CPI_SAGEMCOM,
    G11,
    PLAYSTATION_4,
    PLAYSTATION_5,
    DESKTOP_MOBILE,
    PHILIPS,
    SAMSUNG,
    LG,
    HISENSE,
    FIRETVREACT,
    TV_KEY,
    UWP,
    XBOX,
    SFR_STB7_IPTV,
    SFR_STB8_IPTV,
    SFR_STB8_CABLE,
  ];
  if (!possibleDeviceType.includes(deviceType)) {
    logger.warnOnce(`'deviceType parameter is incorrectly set:
      Possible values:
       - 'web'
       - 'chromecast'
       - 'android'
       - 'playstation4',
       - 'playstation5',
       - 'philips',
       - 'samsung',
       - 'lg',
       - 'hisense',
       - 'firetvreact',
       - 'g9_technicolor',
       - 'g9l_technicolor',
       - 'g9mini_humax',
       - 'g9minive_humax',
       - 'g10_humax',
       - 'g10_sagemcom',
       - 'g10cpi_sagemcom',
       - 'g11',
       - 'cletv',
       - 'uwp',
       - 'xbox',
       - 'sfrstb7iptv',
       - 'sfrstb8iptv',
       - 'sfrstb8cable',
      Fallback to 'web'
      Provided: '${deviceType}'
    `);
    deviceType = DESKTOP_MOBILE;
  }

  const deviceInfo = parseConfigDeviceInfo(config.deviceInfo as IDeviceInfo);
  const featureFlags = parseConfigFeatureFlags(config.featureFlags || {});

  let variant: BusinessTypes.VARIANTS;

  // TODO: This is a temporary constant which can be related to "variant" or "skin"
  // It has to be deleted when "skin" won't be used anymore
  const configVariantValue = config.variant || config.skin;
  switch (configVariantValue) {
    case 'tvod':
      variant = VARIANTS.MYCANAL_TVOD;
      break;
    case 'default':
      variant = VARIANTS.MYCANAL;
      break;
    default:
      // This temporary constant is ts-ignored because its initial type is VARIANTS and not string
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variant = isNullOrUndefined(configVariantValue)
        ? VARIANTS.MYCANAL
        : configVariantValue;
      break;
  }

  if (
    ![
      VARIANTS.MYCANAL_TVOD,
      VARIANTS.MYCANAL,
      VARIANTS.TELECOMITALIA,
      VARIANTS.TELECOMITALIA_BLUE,
    ].includes(variant)
  ) {
    logger.warnOnce(`variant parameter is incorrectly set:
      Possible values:
       - 'mycanal_tvod'
       - 'mycanal'
       - 'telecomitalia'
       - 'telecomitalia_blue'
      Fallback to 'MYCANAL'
      Provided: '${variant}'
    `);
    variant = VARIANTS.MYCANAL;
  }

  let refererTrackingId = isNullOrUndefined(config.refererTrackingId)
    ? null
    : config.refererTrackingId;
  if (refererTrackingId !== null && typeof refererTrackingId !== 'string') {
    logger.warnOnce(`'refererTrackingId' is incorrectly set:
      Possible values:
       - a string
      Fallback to 'null'
      Provided: '${refererTrackingId}'
    `);
    refererTrackingId = null;
  }

  let uiLanguage = isNullOrUndefined(config.uiLanguage)
    ? LanguageTypes.FR_FR
    : config.uiLanguage;
  if (typeof uiLanguage !== 'string') {
    logger.warnOnce(`'uiLanguage' is incorrectly set:
      Possible values:
       - a string
      Fallback to '${LanguageTypes.FR_FR}'
      Provided: '${uiLanguage}'
    `);
    uiLanguage = LanguageTypes.FR_FR;
  }

  let env = isNullOrUndefined(config.env) ? 'prod' : config.env;
  if (!['prod', 'preprod'].includes(env)) {
    logger.warnOnce(`env is incorrectly set:
      Possible values:
       - "prod"
       - "preprod"
      Fallback to 'prod'
      Provided: '${env}'
    `);
    env = 'prod';
  }

  let base: string | null = null;
  if (typeof config.base === 'string') {
    base = config.base;
  } else if (config.base) {
    logger.warnOnce(`'base' is incorrectly set:
      Possible values:
       - a string
      Fallback to null
      That parameter is only for developement purpose /!\
      It will not be used in production /!\
      In production base will be detected or fallback to //secure-player.canal-plus.com/one/prod/v2/ /!\
      Provided: '${config.base}'
    `);
  }

  const configBaseUrl =
    typeof config.configBaseUrl === 'string' ? config.configBaseUrl : null;

  return {
    content: configContentValue,
    platform,
    context,
    contentFunctionalType,
    deviceType,
    deviceInfo,
    featureFlags,
    variant,
    refererTrackingId,
    uiLanguage,
    env,
    base,
    configBaseUrl,
    params: parseConfigParams(config.params ?? {}, platform), // Parse the 'params' object we receive
    events: parseConfigEvents(config.events ?? {}), // Parse the 'events' object we receive
    callbacks: parseConfigCallbacks(config.callbacks ?? {}), // Parse the 'callbacks' object we receive,
    ...(__DEV__ && { debug: config.debug }),
  };
}

export default parseConfig;
