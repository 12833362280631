import { BusinessTypes } from '@canalplus/oneplayer-constants';

import styles from './Spinner.css';

interface IDefaultProps {
  variant?: BusinessTypes.VARIANTS;
  isSpinnerPositionBottom?: boolean;
}

export type IProps = IDefaultProps;

const Spinner = ({
  variant = BusinessTypes.VARIANTS.MYCANAL,
  isSpinnerPositionBottom = false,
}: IProps): JSX.Element => {
  return (
    <div
      className={
        isSpinnerPositionBottom ? styles.spinnerBottom : styles.spinnerCentered
      }
      data-testid="spinner"
    >
      <span className={styles[`circle--${variant}`]} />
    </div>
  );
};

export { Spinner };
