export enum SEQUENCES_TYPES {
  PENDING = 'PENDING',
  READY = 'READY',
  POSTER = 'POSTER',
  PREROLL = 'PREROLL',
  PARENTAL_RATING = 'PARENTAL_RATING',
  CONTENT = 'CONTENT',
  MIDROLL = 'MIDROLL',
  POSTROLL = 'POSTROLL',
  BLOCKING_ERROR = 'BLOCKING_ERROR',
  CAST = 'CAST',

  // PIP is not a real Sequence but more a state in a sequence here...
  PIP = 'PIP',
}

export const STACK: SEQUENCES_TYPES[] = [
  SEQUENCES_TYPES.PENDING,
  SEQUENCES_TYPES.POSTER,
  SEQUENCES_TYPES.PREROLL,
  SEQUENCES_TYPES.PARENTAL_RATING,
  SEQUENCES_TYPES.CONTENT,
  SEQUENCES_TYPES.POSTROLL,
];

export const NO_SPINNER_SEQUENCES = [
  SEQUENCES_TYPES.POSTER,
  SEQUENCES_TYPES.PARENTAL_RATING,
  SEQUENCES_TYPES.BLOCKING_ERROR,
];

export const AD_SEQUENCES = [
  SEQUENCES_TYPES.PREROLL,
  SEQUENCES_TYPES.MIDROLL,
  SEQUENCES_TYPES.POSTROLL,
];

export const AIRPLAY_ENABLED_SEQUENCES = [
  SEQUENCES_TYPES.PREROLL,
  SEQUENCES_TYPES.CONTENT,
  SEQUENCES_TYPES.MIDROLL,
  SEQUENCES_TYPES.POSTROLL,
];

export const PIP_ENABLED_SEQUENCES = [
  SEQUENCES_TYPES.PREROLL,
  SEQUENCES_TYPES.CONTENT,
  SEQUENCES_TYPES.MIDROLL,
  SEQUENCES_TYPES.POSTROLL,
];
