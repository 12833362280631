import { BreakPoints } from '@canalplus/oneplayer-constants';
var SMALL = BreakPoints.SMALL, MEDIUM = BreakPoints.MEDIUM, sidepanelWidth = BreakPoints.sidepanelWidth;
export var DEFAULT_VIDEO_RATIO = 0.5625; // 16:9 ratio
function getVideoWrapperDimensions(_a) {
    var videoFrameWidth = _a.videoFrameWidth, videoFrameHeight = _a.videoFrameHeight, currentBreakpoint = _a.currentBreakpoint, isSidepanelOpen = _a.isSidepanelOpen, isMobilePortraitLayout = _a.isMobilePortraitLayout, mobilePortraitVideoHeight = _a.mobilePortraitVideoHeight, mobilePortraitVideoTop = _a.mobilePortraitVideoTop, isTvPlayer = _a.isTvPlayer, isPlaystation = _a.isPlaystation, tvPlayerPanel = _a.tvPlayerPanel;
    var videoRatio = DEFAULT_VIDEO_RATIO; // 16:9 ratio
    var windowAvailableWidth = videoFrameWidth;
    // substract the size of the sidePanel to the available width
    if (isSidepanelOpen) {
        // determine the size of the current sidepanel depending on the breakpoint
        var sidePanelWidth = sidepanelWidth.LARGE_WIDTH;
        if (currentBreakpoint === SMALL) {
            sidePanelWidth = sidepanelWidth.SMALL_WIDTH;
        }
        else if (currentBreakpoint === MEDIUM) {
            sidePanelWidth = sidepanelWidth.MEDIUM_WIDTH;
        }
        windowAvailableWidth = sidePanelWidth
            ? videoFrameWidth - sidePanelWidth
            : videoFrameWidth;
    }
    // define 16:9 dimensions in the space available
    // first case: windowHeight is too big to calculate a 16:9 height
    // based on the window iwdth available for the video, so we resize the height
    // if the 16:9 width based on the window height is inferior to the window width
    // we resize the width based on the height.
    var position = {};
    var transform = {};
    // We should use round() because CSS height and width are integer values
    // Also using non-integer and let the browser interpret the value leaded to display anomalies on LG TVs;
    // particularly when calculous were involved (ie. windowAvailableWidth * videoRatio)
    if (videoFrameHeight >= Math.round(windowAvailableWidth * videoRatio)) {
        position = {
            height: "".concat(windowAvailableWidth * videoRatio, "px"),
            top: "".concat(videoFrameHeight / 2 - (windowAvailableWidth * videoRatio) / 2, "px"),
        };
    }
    else {
        position = {
            // Here we use Math.round to avoid the error between the rounded value of
            // the wrapper and the content (it depends on which browser we are but the CSS
            // value might not be exactly the same, by rounded it to the next integer we assure
            // that we don't have a wrapper smaller than the video)
            width: "".concat(Math.round(videoFrameHeight / videoRatio), "px"),
            left: "".concat(windowAvailableWidth / 2 - videoFrameHeight / videoRatio / 2, "px"),
        };
    }
    // case we are on mobile and the side panel comes from the bottom
    if (isMobilePortraitLayout && isSidepanelOpen) {
        position = {
            height: "".concat(mobilePortraitVideoHeight, "px"),
            top: "".concat(mobilePortraitVideoTop, "px"),
        };
        transform = {
            transform: "translate3d(0, -".concat(mobilePortraitVideoTop, "px, 0)"),
        };
    }
    // Resize the video on the variant tv except for Playstation for now
    // as we can't resize the video for now.
    var tvCoeff = 0.615;
    var fixedPaddingLeft = 50; // px (request from design to be fixed)
    if (isTvPlayer && !isPlaystation && tvPlayerPanel === 'EXPERT_MODE') {
        position = {
            width: "".concat((windowAvailableWidth - fixedPaddingLeft) * tvCoeff, "px"),
            height: "".concat((windowAvailableWidth - fixedPaddingLeft) * tvCoeff * videoRatio, "px"),
            top: videoFrameHeight >
                (windowAvailableWidth - fixedPaddingLeft) * videoRatio
                ? "".concat(videoFrameHeight / 2 -
                    ((windowAvailableWidth - fixedPaddingLeft) *
                        tvCoeff *
                        videoRatio) /
                        2, "px")
                : "".concat(videoFrameHeight / 5, "px"),
            left: "".concat(fixedPaddingLeft, "px"),
        };
    }
    return { position: position, transform: transform };
}
export default getVideoWrapperDimensions;
