import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import screenfull from 'screenfull';
import { i18nConsumer } from '../../../../utils/i18n/i18n';
import ExitButton from '../exitbutton/ExitButton';
var styles = require('../error/WebErrorInfos.css');
var WebInitError = function (_a) {
    var i18nKey = _a.i18nKey, exitButtonClicked = _a.exitButtonClicked, exitButton = _a.exitButton, t = _a.t;
    var isFullscreen = screenfull && screenfull.isFullscreen;
    var showExitButton = (isFullscreen && exitButton.showFullScreen) ||
        (!isFullscreen && exitButton.showWindowed);
    return (_jsxs("div", { className: styles.wrapper, children: [showExitButton && _jsx(ExitButton, { onClick: exitButtonClicked }), _jsx("h1", { className: styles.header, children: t('texts.ErrorTitle') }), _jsx("p", { className: styles.message, children: t(i18nKey) })] }));
};
export default i18nConsumer(WebInitError);
