/**
 * Omit props from an array of key in the provided object.
 * It doesnt mutate the original object!
 *
 * @param obj - object to omit props
 * @param arrPropsToDelete - array of props to delete
 * @returns Object with the omited props
 */
const omit = <O extends Record<string, unknown>, K extends keyof O>(
  obj: O,
  arrPropsToDelete: K[],
): Record<string, unknown> => {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return {};
  }
  if (!Array.isArray(arrPropsToDelete)) {
    return obj;
  }
  const result = {} as Record<string, unknown>;
  Object.keys(obj)
    .filter((key) => !arrPropsToDelete.includes(key as K))
    .forEach((key) => {
      result[key] = obj[key as K];
    });
  return result;
};

export default omit;
