import { PlayerStates } from '@canalplus/oneplayer-constants';
var BUFFERING = PlayerStates.BUFFERING, FREEZING = PlayerStates.FREEZING, ENDED = PlayerStates.ENDED, LOADING = PlayerStates.LOADING, RELOADING = PlayerStates.RELOADING, PLAYING = PlayerStates.PLAYING, SEEKING = PlayerStates.SEEKING, STOPPED = PlayerStates.STOPPED;
export var playingStates = [PLAYING];
export var waitingStates = [RELOADING, LOADING, BUFFERING, FREEZING, SEEKING];
export var stoppedStates = [ENDED, STOPPED];
export var dataShortageStates = [BUFFERING, FREEZING, SEEKING];
export function isWaiting(state) {
    return waitingStates.includes(state);
}
export function isStopped(state) {
    return stoppedStates.includes(state);
}
export function isPlaying(state) {
    return playingStates.includes(state);
}
export function isInDataShortage(state) {
    return dataShortageStates.includes(state);
}
export function isFullscreenable(state) {
    return !stoppedStates.includes(state);
}
export function isBuffering(state) {
    return state === BUFFERING;
}
export function isFreezing(state) {
    return state === FREEZING;
}
export function isLoading(state) {
    return state === LOADING;
}
