import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
export var contextMenuSelector = function (state) {
    return state.contextMenu;
};
/**
 * @returns {function(object): Object}
 */
export var mouseDeepSelector = createDeepEqualSelector(contextMenuSelector, function (contextMenu) { return contextMenu.mouse; });
/**
 * @returns {function(object): boolean}
 */
export var isVisibleSelector = createSelector(contextMenuSelector, function (contextMenu) { return contextMenu.isVisible; });
/**
 * @returns {function(object): boolean}
 */
export var isRxPlayerDebugModalEnabledSelector = createSelector(contextMenuSelector, function (contextMenu) { return contextMenu.isRxPlayerDebugModalEnabled; });
