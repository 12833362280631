import * as Utils from './utils';

export default class VASTCompanionAd {
  constructor(xmlElement) {
    this.id = null;
    this.width = 0;
    this.height = 0;
    this.type = null;
    this.staticResource = null;
    this.htmlResource = null;
    this.iframeResource = null;
    this.companionClickThroughURL = null;

    // WARNING: dans la spec VAST 4.0, remplacé par CompanionClickTracking !
    this.trackingEvents = [];

    this.parse(xmlElement);
  }

  setId(id) {
    this.id = id || null;
  }

  setSize(width, height) {
    this.width = parseInt(width, 10) || 0;
    this.height = parseInt(height, 10) || 0;
  }

  setType(type) {
    this.type = type || null;
  }

  setHtmlResource(htmlResourceElement) {
    this.htmlResource = Utils.parseNodeText(htmlResourceElement);
  }

  setIframeResource(iframeResourceElement) {
    this.iframeResource = Utils.parseNodeText(iframeResourceElement);
  }

  setStaticResource(staticResourceElement) {
    this.staticResource = Utils.parseNodeText(staticResourceElement);
  }

  setTrackingEvents(trackingEvents) {
    this.trackingEvents = this.trackingEvents.concat(trackingEvents);
  }

  setCompanionClickThroughURL(companionClickThroughElement) {
    this.companionClickThroughURL = Utils.parseNodeText(
      companionClickThroughElement,
    );
  }

  parse(xmlElement) {
    this.setId(xmlElement.getAttribute('id'));
    this.setSize(
      xmlElement.getAttribute('width'),
      xmlElement.getAttribute('height'),
    );

    const htmlResourceElements = Utils.childrenByName(
      xmlElement,
      'HTMLResource',
    );
    htmlResourceElements.forEach((htmlResourceElement) => {
      const type =
        htmlResourceElement.getAttribute('creativeType') || 'text/html';
      this.setType(type);
      this.setHtmlResource(htmlResourceElement);
    });

    const iframeResourceElements = Utils.childrenByName(
      xmlElement,
      'IFrameResource',
    );
    iframeResourceElements.forEach((iframeResourceElement) => {
      this.setType(iframeResourceElement.getAttribute('creativeType'));
      this.setIframeResource(iframeResourceElement);
    });

    const staticResourceElements = Utils.childrenByName(
      xmlElement,
      'StaticResource',
    );
    staticResourceElements.forEach((staticResourceElement) => {
      this.setType(staticResourceElement.getAttribute('creativeType'));
      this.setStaticResource(staticResourceElement);
    });

    const trackingEventsElement = Utils.childByName(
      xmlElement,
      'TrackingEvents',
    );
    const trackingEvents = Utils.parseTrackingEventsElement(
      trackingEventsElement,
    );
    this.setTrackingEvents(trackingEvents);

    const companionClickThroughElement = Utils.childByName(
      xmlElement,
      'CompanionClickThrough',
    );
    this.setCompanionClickThroughURL(companionClickThroughElement);
  }
}
