// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u1jFKSQBEo8cMIbRrxmo {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/ui/hoc/withTooltip.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".withTooltip {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withTooltip": `u1jFKSQBEo8cMIbRrxmo`
};
module.exports = ___CSS_LOADER_EXPORT___;
