import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
export var frameSelector = function (state) { return state.frame; };
/**
 * @returns {function(object): number}
 */
export var widthSelector = createSelector(frameSelector, function (frame) { return frame.width; });
/**
 * @returns {function(object): number}
 */
export var heightSelector = createSelector(frameSelector, function (frame) { return frame.height; });
/**
 * @returns {function(object): bool}
 */
export var hasFocusSelector = createSelector(frameSelector, function (frame) { return frame.hasFocus; });
/**
 * @returns {function(object): string}
 */
export var breakpointSelector = createSelector(frameSelector, function (frame) { return frame.breakpoint; });
/**
 * @returns {function(object): object}
 */
export var deepEqualFrameSelector = createDeepEqualSelector(frameSelector, function (frame) { return frame; });
/**
 * @returns {function(object): number}
 */
export var offsetLeftSelector = createSelector(frameSelector, function (frame) { return frame.offsetLeft; });
/**
 * @returns {function(object): number}
 */
export var offsetTopSelector = createSelector(frameSelector, function (frame) { return frame.offsetTop; });
/**
 * @returns {function(object): object}
 */
export var frameSizeDeepEqualSelector = createDeepEqualSelector(offsetLeftSelector, offsetTopSelector, widthSelector, heightSelector, function (offsetLeft, offsetTop, width, height) { return ({
    offsetLeft: offsetLeft,
    offsetTop: offsetTop,
    width: width,
    height: height,
}); });
