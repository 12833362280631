export const STOPPED = 'STOPPED';
export const LOADING = 'LOADING';
export const LOADED = 'LOADED';
export const ENDED = 'ENDED';
export const PLAYING = 'PLAYING';
export const PAUSED = 'PAUSED';
export const BUFFERING = 'BUFFERING';
export const FREEZING = 'FREEZING';
export const SEEKING = 'SEEKING';
export const RELOADING = 'RELOADING';
export const IDLE = 'IDLE'; // specific to chromecast
export const ERROR = 'ERROR';
