export var FR_FR = 'fr-FR';
export var FR_CH = 'fr-CH';
export var EN_EN = 'en-EN';
export var EN_US = 'en-US';
export var PL_PL = 'pl-PL';
export var IT_IT = 'it-IT';
export var DE_CH = 'de-CH';
export var CS_CS = 'cs-CS';
export var SK_SK = 'sk-SK';
export var SL_SL = 'sl-SL';
// Use fallback languages if provided config language exists but locale does not
export var FALLBACK_LANGUAGES = {
    en: EN_US,
    fr: FR_FR,
    pl: PL_PL,
    it: IT_IT,
    de: DE_CH,
    cs: CS_CS,
    sk: SK_SK,
    sl: SL_SL,
};
