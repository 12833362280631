import uuid from '../../uuid';
import LocalStorage from './LocalStorage';

const DEV_DEFAULT_ID = 'fab11f5b-e049-468a-a9bf-3316e2119d77';

class UserStorage extends LocalStorage {
  private static userInstance: UserStorage | undefined;

  public static getInstance(): UserStorage {
    if (!UserStorage.userInstance) {
      UserStorage.userInstance = new UserStorage();
    }

    return UserStorage.userInstance;
  }

  constructor() {
    super('user');
  }

  async getOrGenerate(name: string): Promise<any> {
    let value = await this.getItem(name);
    if (!value) {
      // For dev purposes, always use the same ids
      value = __DEV__ ? DEV_DEFAULT_ID : uuid();
      await this.setItem(name, value);
    }
    return value;
  }

  async getCredentials(): Promise<{ userKeyId: string; deviceKeyId: string }> {
    return {
      userKeyId: await this.getOrGenerate('usr'),
      deviceKeyId: await this.getOrGenerate('dev'),
    };
  }
}

export const userStorage = UserStorage.getInstance();

export default UserStorage;
