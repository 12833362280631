import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { createSelector } from 'reselect';
import { isPipAvailable } from '@canalplus/oneplayer-utils';
import { canSkipCurrentAdSelector, canSkipAfterSelector, canSkipAllAdsSelector, } from './adsSelector';
import { isAirplayAvailableSelector } from './airplaySelector';
import { isCastAvailableSelector, isCastPendingSelector, isCastActiveSelector, } from './castSelector';
import { getGroupTypeCurrentChannelSelector, isCurrentChannelAdAntiSkipSelector, isCastableSelector, isExternalServiceChannelSelector, } from './channelSelector';
import { contentTypesSelector, enableAirplaySelector, exitButtonShowFullscreenSelector, exitButtonShowWindowedSelector, fetchContentSeriesSelector, enablePiPSelector, showContentInfoConfigSelector, contentFunctionalTypeConfigSelector, isAntiAdSkipEnabledSelector, shouldShowPlaybackRateSelector, shouldShowAudioOnlySelector, isTvPlayerSelector, } from './configSelector';
import { isControlsActiveSelector, isLiveEdgeSelector, isStoppedSelector, isFullscreenSelector, hasMultipleLanguagesOrSubtitlesSelector, availableVideoBitratesSelector, } from './coreSelector';
import { dataSelector, isLiveSelector, isPremiumSelector, itemTypeSelector, nextEpisodeIdSelector, } from './dataSelector';
import { isIphoneSelector } from './deviceSelector';
import { isExpertModeAvailableSelector, shouldShowExpertModeNotificationSelector, } from './expertModeSelector';
import { canStartOverSelector } from './liveSelector';
import { isModalTypeLanguageSelector, modalTypeSelector, } from './uiManagerSelector';
import { isCurrentSequenceAirplayEnabledSelector, isCurrentSequenceBookendSelector, isCurrentSequenceAdSelector, isCurrentSequencePipEnabledSelector, } from './sequenceSelector';
import { isProgramReadySelector, allCurrentProgramsSelector, allProgramsSelector, isAntiAdSkipSequenceActiveSelector, timeRemainingInAdSequenceSelector, } from './programSelector';
import { currentSeasonDataSelector } from './seriesSelector';
import { isSidepanelOpenSelector } from './sidepanelSelector';
import { isToggledControlsShown, isSidebarControlsShown, isContentProgressBarShown, isAirplayControlShown, isCastControlShown, isExitPlayerControlShown, isLiveControlShown, isLiveAdsControlShown, isLiveControlDisabled, isLanguageControlShown, isLanguageControlDisabled, isNextEpisodeControlShown, isProgramControlDisabled, isSeekControlShown, isPipControlShown, isSeriesControlShown, isVideoQualityControlShown, isTimelineControlShown, isZaplistControlShown, isSettingsControlShown, } from '../utils/controls';
var PRIMARY = BusinessTypes.PRIMARY;
/**
 * @returns {function(object): boolean}
 */
export var shouldShowAirplayControlSelector = createSelector(isAirplayAvailableSelector, enableAirplaySelector, isCurrentSequenceAirplayEnabledSelector, isPremiumSelector, function (isAirplayAvailable, isAirplayEnabled, isCurrentSequenceAirplayEnabled, isPremium) {
    return isAirplayControlShown(isAirplayAvailable, isAirplayEnabled, isCurrentSequenceAirplayEnabled, isPremium);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowToggleControlsSelector = createSelector(isCastPendingSelector, isCastActiveSelector, isSidepanelOpenSelector, isControlsActiveSelector, function (isCastPending, isCastActive, isSidepanelOpen, isActive) {
    return isToggledControlsShown(isCastPending, isCastActive, isSidepanelOpen, isActive);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowSidebarControlsSelector = createSelector(shouldShowToggleControlsSelector, shouldShowExpertModeNotificationSelector, getGroupTypeCurrentChannelSelector, modalTypeSelector, function (shouldShowToggleControls, shouldShowExpertModeNotification, getGroupType, modalType) {
    return isSidebarControlsShown(shouldShowToggleControls, shouldShowExpertModeNotification, getGroupType, modalType);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowContentProgressBarSelector = createSelector(isStoppedSelector, isProgramReadySelector, isExternalServiceChannelSelector, function (isStopped, isProgramReady, isExternalServiceChannel) {
    return isContentProgressBarShown(isStopped, isProgramReady, isExternalServiceChannel);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowCastControlSelector = createSelector(isCastAvailableSelector, isCastableSelector, isCurrentSequenceBookendSelector, isCastActiveSelector, function (isCastAvailable, isCastable, isCurrentSequenceBookend, isCastActive) {
    return isCastControlShown(isCastAvailable, isCastable, isCurrentSequenceBookend, isCastActive);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowExitPlayerControlSelector = createSelector(isFullscreenSelector, exitButtonShowFullscreenSelector, exitButtonShowWindowedSelector, function (isFullscreen, showFullscreen, showWindowed) {
    return isExitPlayerControlShown(isFullscreen, showFullscreen, showWindowed);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowLanguageControlSelector = createSelector(isPremiumSelector, isCurrentSequenceAdSelector, hasMultipleLanguagesOrSubtitlesSelector, function (isPremium, isCurrentSequenceAd, hasLanguages) {
    return isLanguageControlShown(isPremium, isCurrentSequenceAd, hasLanguages);
});
/**
 * @returns {function(object): boolean}
 */
export var isLanguageControlDisabledSelector = createSelector(hasMultipleLanguagesOrSubtitlesSelector, isModalTypeLanguageSelector, function (hasMultipleLanguagesOrSubtitles, isModalTypeLanguage) {
    return isLanguageControlDisabled(hasMultipleLanguagesOrSubtitles, isModalTypeLanguage);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowLiveControlSelector = createSelector(isPremiumSelector, isLiveSelector, isCurrentSequenceAdSelector, function (isPremium, isLive, isCurrentSequenceAd) {
    return isLiveControlShown(isPremium, isLive, isCurrentSequenceAd);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowLiveAdsControlSelector = createSelector(isAntiAdSkipSequenceActiveSelector, isCurrentChannelAdAntiSkipSelector, timeRemainingInAdSequenceSelector, isAntiAdSkipEnabledSelector, function (isAntiAdSkipSequenceActive, isAdAntiSkip, timeRemainingInAdSequence, isAntiAdSkipEnabled) {
    return isLiveAdsControlShown(isAntiAdSkipSequenceActive, isAdAntiSkip, timeRemainingInAdSequence, isAntiAdSkipEnabled);
});
/**
 * @returns {function(object): boolean}
 */
export var isLiveControlDisabledSelector = createSelector(isLiveEdgeSelector, isStoppedSelector, function (isLiveEdge, isStopped) { return isLiveControlDisabled(isLiveEdge, isStopped); });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowPipControlSelector = createSelector(enablePiPSelector, isCurrentSequencePipEnabledSelector, isIphoneSelector, isCastActiveSelector, function (isPiPEnabled, isCurrentSequencePipEnabled, isIphone, isCastActive) {
    return isPipControlShown(isPiPEnabled, isPipAvailable(), isCurrentSequencePipEnabled, isIphone, isCastActive);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowSoundControlSelector = createSelector(isCurrentSequenceBookendSelector, function (isCurrentSequenceBookend) { return !isCurrentSequenceBookend; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowVideoQualityControlSelector = createSelector(isTvPlayerSelector, isCurrentSequenceBookendSelector, isCastActiveSelector, function (isTvPlayer, isCurrentSequenceBookend, isCastActive) {
    return isVideoQualityControlShown(isTvPlayer, isCurrentSequenceBookend, isCastActive);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowSettingsControlSelector = createSelector(shouldShowAudioOnlySelector, shouldShowPlaybackRateSelector, shouldShowVideoQualityControlSelector, function (shouldShowAudioOnly, shouldShowPlaybackRate, shouldShowVideoQualityControl) {
    return isSettingsControlShown(shouldShowAudioOnly, shouldShowPlaybackRate, shouldShowVideoQualityControl);
});
/**
 * @returns {function(object): boolean}
 */
export var hasAvailableVideoBitratesSelector = createSelector(availableVideoBitratesSelector, function (bitrates) { return !!bitrates.length; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowNextEpisodeControlSelector = createSelector(fetchContentSeriesSelector, isLiveSelector, isPremiumSelector, isCurrentSequenceAdSelector, currentSeasonDataSelector, nextEpisodeIdSelector, function (fetchContentSeries, isLive, isPremium, isCurrentSequenceAd, currentSeasonData, nextEpisodeId) {
    return isNextEpisodeControlShown(fetchContentSeries, isLive, isPremium, isCurrentSequenceAd, !!currentSeasonData, !!nextEpisodeId);
});
/**
 * @returns {function(object): bool}
 */
export var isProgramControlDisabledSelector = createSelector(isExpertModeAvailableSelector, isLiveEdgeSelector, dataSelector, function (isExpertModeAvailable, isLiveEdge, data) {
    return isProgramControlDisabled(isExpertModeAvailable, isLiveEdge, !!data);
});
/**
 * @returns {function(object): bool}
 */
export var shouldShowSeekControlSelector = createSelector(isPremiumSelector, isCurrentSequenceAdSelector, canStartOverSelector, function (isPremium, isCurrentSequenceAd, canStartOver) {
    return isSeekControlShown(isPremium, isCurrentSequenceAd, canStartOver);
});
/**
 * @returns {function(object): bool}
 */
export var shouldShowSeriesControlSelector = createSelector(itemTypeSelector, contentTypesSelector, fetchContentSeriesSelector, currentSeasonDataSelector, function (itemType, contentTypes, fetchContentSeries, currentSeasonData) {
    return isSeriesControlShown(itemType, fetchContentSeries, !!currentSeasonData, contentTypes === null || contentTypes === void 0 ? void 0 : contentTypes.SERIES, contentTypes === null || contentTypes === void 0 ? void 0 : contentTypes.PROGRAM);
});
/**
 * @returns {function(object): bool}
 */
export var shouldShowTimelineControlSelector = createSelector(isLiveSelector, function (isLive) { return isTimelineControlShown(isLive); });
/**
 * @returns {function(object): bool}
 */
export var isTimelineControlDisabledSelector = createSelector(allProgramsSelector, function (allPrograms) { return !allPrograms.length; });
/**
 * @returns {function(object): bool}
 */
export var shouldShowZaplistControlSelector = createSelector(isLiveSelector, function (isLive) { return isZaplistControlShown(isLive); });
/**
 * @returns {function(object): bool}
 */
export var shouldShowContentInfoControlSelector = createSelector(showContentInfoConfigSelector, contentFunctionalTypeConfigSelector, function (showContentInfo, functionalType) {
    return !!showContentInfo && functionalType === PRIMARY;
});
/**
 * @returns {function(object): boolean}
 */
export var isZaplistControlDisabledSelector = createSelector(allCurrentProgramsSelector, function (allCurrentPrograms) { return !allCurrentPrograms.length; });
/**
 * @returns {function(object): boolean}
 */
export var shoudShowSkipOneAdControlSelector = createSelector(isCastActiveSelector, canSkipCurrentAdSelector, canSkipAfterSelector, function (isCastActive, canSkipCurrentAd, canSkipAfter) {
    return !isCastActive && (canSkipCurrentAd || canSkipAfter != null);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldShowSkipAllAdsControlSelector = createSelector(isCastActiveSelector, canSkipAllAdsSelector, function (isCastActive, canSkipAllAds) { return !isCastActive && canSkipAllAds; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowPlayPauseControlSelector = createSelector(canStartOverSelector, function (canStartOver) { return canStartOver; });
/**
 * @returns {function(object): boolean}
 */
export var isTvMenuAvailableSelector = createSelector(shouldShowLanguageControlSelector, shouldShowZaplistControlSelector, shouldShowTimelineControlSelector, shouldShowNextEpisodeControlSelector, shouldShowSeriesControlSelector, shouldShowContentInfoControlSelector, shouldShowLiveControlSelector, isExpertModeAvailableSelector, isExternalServiceChannelSelector, function (shouldShowLanguageControl, shouldShowZaplistControl, shouldShowTimelineControl, shouldShowNextEpisodeControl, shouldShowSeriesControl, shouldShowContentInfoControl, shouldShowLiveControl, isExpertModeAvailable, isExternalServiceChannel) {
    return !isExternalServiceChannel &&
        (shouldShowLanguageControl ||
            shouldShowZaplistControl ||
            shouldShowTimelineControl ||
            shouldShowNextEpisodeControl ||
            shouldShowSeriesControl ||
            shouldShowContentInfoControl ||
            shouldShowLiveControl ||
            isExpertModeAvailable);
});
