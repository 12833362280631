import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { TOfferZone } from '@canalplus/oneplayer-types';

import { fetchConfig } from '../../config';

const { DEVICE_ECK } = BusinessTypes;

/**
 *
 * @param offerZone the zone of the user (eg. cpafra, cppol..)
 * @param deviceType type of the device
 * @returns a promise with the HAPI headers info
 */
async function getHapiHeadersInfo(
  offerZone: TOfferZone,
  deviceType: BusinessTypes.DEVICE_TYPES,
): Promise<{ 'xx-service': string; 'xx-operator': string | undefined }> {
  const serverConfig = await fetchConfig({
    env: 'prod',
    offerZone,
    deviceType,
  });
  return {
    'xx-service': serverConfig.hapi.service,
    'xx-operator': DEVICE_ECK[deviceType],
  };
}

export default getHapiHeadersInfo;
