import { createSelector } from 'reselect';
export var switchPlusSelector = function (state) {
    return state.switchplus;
};
/**
 * @returns {function(object): string}
 */
export var actionTypeSelector = createSelector(switchPlusSelector, function (switchPlus) { return switchPlus.actionType; });
/**
 * @returns {function(object): string}
 */
export var actionPayloadSelector = createSelector(switchPlusSelector, function (switchPlus) { return switchPlus.actionPayload; });
