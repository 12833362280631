export const DRM_TYPES = {
  NAGRA: 'nagra',
  WIDEVINE: 'widevine',
  PLAYREADY: 'playready',
  FAIRPLAY: 'fairplay',
} as const;

export const KEY_SYSTEMS = {
  NAGRA: 'com.nagra.prm',
  WIDEVINE: 'com.widevine.alpha',
  PLAYREADY: 'com.microsoft.playready',
  PLAYREADY_SOFTWARE: 'com.microsoft.playready.software',
  PLAYREADY_HARDWARE: 'com.microsoft.playready.hardware',
  PLAYREADY_RECOMMENDATION: 'com.microsoft.playready.recommendation',
  PLAYREADY_RECOMMENDATION_2000: 'com.microsoft.playready.recommendation.2000',
  PLAYREADY_RECOMMENDATION_3000: 'com.microsoft.playready.recommendation.3000',
  PLAYREADY_RECOMMENDATION_ESVM: 'com.microsoft.playready.recommendation.esvm',
  FAIRPLAY: 'com.apple.fps.1_0',
} as const;

export const PLAYREADY_RECOMMENDATION_KEY_SYSTEMS: Array<
  (typeof KEY_SYSTEMS)[keyof typeof KEY_SYSTEMS]
> = [
  KEY_SYSTEMS.PLAYREADY_RECOMMENDATION_ESVM,
  KEY_SYSTEMS.PLAYREADY_RECOMMENDATION_3000,
  KEY_SYSTEMS.PLAYREADY_RECOMMENDATION_2000,
  KEY_SYSTEMS.PLAYREADY_RECOMMENDATION,
];
