import { createSelector } from 'reselect';
import { BusinessTypes, PlayerStates } from '@canalplus/oneplayer-constants';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
import { getDefaultMiniumPosition, isLiveEdge } from '../utils/live';
import { isFullscreenable } from '../utils/playerState';
import { isCurrentChannelStartOverAuthorizedSelector } from './channelSelector';
import { isLowLatencyRequestedSelector, isPlatformLiveSelector, isTvPlayerSelector, showTvLoadingScreenSelector, } from './configSelector';
var SUBTITLES_FONT_TYPE = BusinessTypes.SUBTITLES_FONT_TYPE;
var PAUSED = PlayerStates.PAUSED, LOADING = PlayerStates.LOADING, RELOADING = PlayerStates.RELOADING, STOPPED = PlayerStates.STOPPED, ENDED = PlayerStates.ENDED, LOADED = PlayerStates.LOADED, SEEKING = PlayerStates.SEEKING, BUFFERING = PlayerStates.BUFFERING, FREEZING = PlayerStates.FREEZING;
export var coreSelector = function (state) { return state.core; };
/**
 * @returns the preferred language for VOD
 */
export var vodPreferredLanguageSelector = createSelector(coreSelector, function (core) { return core.vodPreferredLanguage; });
/**
 * @returns the preferred subtitle for VOD
 */
export var vodPreferredSubtitleSelector = createSelector(coreSelector, function (core) { return core.vodPreferredSubtitle; });
/**
 * @returns the preferred language for LIVE
 */
export var livePreferredLanguageSelector = createSelector(coreSelector, function (core) { return core.livePreferredLanguage; });
/**
 * @returns the preferred subtitle for LIVE
 */
export var livePreferredSubtitleSelector = createSelector(coreSelector, function (core) { return core.livePreferredSubtitle; });
/**
 * @returns the current language
 */
export var currentLanguageSelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentLanguage; });
/**
 * @returns the current playback rate
 */
export var currentPlaybackRateSelector = createSelector(coreSelector, function (core) { return core.currentPlaybackRate; });
/**
 * @returns the current subtitle
 */
export var currentSubtitleSelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentSubtitle; });
/*
 * @returns the current subtitle font family
 */
export var currentSubtitlesFontFamilySelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentSubtitlesFontFamily; });
/*
 * @returns the current subtitle font type
 */
export var currentSubtitlesFontTypeSelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentSubtitlesFontType; });
/**
 * @returns whether or not the current subtitle is dyslexic
 */
export var isDyslexicSubtitleSelector = createSelector(currentSubtitlesFontTypeSelector, function (currentSubtitlesFontType) {
    return currentSubtitlesFontType === SUBTITLES_FONT_TYPE.DYSLEXIC;
});
/**
 * @returns the current subtitle size
 */
export var currentSubtitlesSizeSelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentSubtitlesSize; });
/**
 * @returns the current subtitle preset
 */
export var currentSubtitlesPresetSelector = createDeepEqualSelector(coreSelector, function (core) { return core.currentSubtitlesPreset; });
/**
 * @returns the current volume
 */
export var soundVolumeSelector = createSelector(coreSelector, function (core) { return core.soundVolume; });
/**
 * @returns whether or not the player is in audio only mode
 */
export var isAudioOnlySelector = createSelector(coreSelector, function (core) { return core.isAudioOnly; });
/**
 * @returns whether or not controls are active
 */
export var isControlsActiveSelector = createSelector(coreSelector, function (core) { return core.isActive; });
/**
 * @returns the last activity date
 */
export var lastActivitySelector = createSelector(coreSelector, function (core) { return core.lastActivity; });
/**
 * @returns whether or not the player is playing
 */
export var isPlayingSelector = createSelector(coreSelector, function (core) { return core.isPlaying; });
/**
 * @returns whether or not the player is stopping
 */
export var isStoppingSelector = createSelector(coreSelector, function (core) { return core.isStopping; });
/**
 * @returns the live time
 */
export var liveTimeSelector = createSelector(coreSelector, function (core) { return core.liveTime; });
/**
 * @returns the player state
 */
export var playerStateSelector = createSelector(coreSelector, function (core) { return core.playerState; });
/**
 * @returns the live gap
 */
export var liveGapSelector = createSelector(coreSelector, function (core) { return core.liveGap; });
/**
 * @returns the list of available languages
 */
export var availableLanguagesSelector = createDeepEqualSelector(coreSelector, function (core) { return core.availableLanguages || []; });
/**
 * @returns the list of available subtitles
 */
export var availableSubtitlesSelector = createDeepEqualSelector(coreSelector, function (core) { return core.availableSubtitles || []; });
/**
 * @returns the current time
 */
export var currentTimeSelector = createSelector(coreSelector, function (core) { return core.currentTime; });
/**
 * @returns the minimum position
 */
export var minimumPositionSelector = createSelector(coreSelector, isCurrentChannelStartOverAuthorizedSelector, function (core, isCurrentChannelStartOverAuthorized) {
    var _a;
    return ((_a = core.minimumPosition) !== null && _a !== void 0 ? _a : getDefaultMiniumPosition(isCurrentChannelStartOverAuthorized));
});
/**
 * @returns the startAtError
 */
export var startAtErrorSelector = createSelector(coreSelector, function (core) { return core.startAtError; });
/**
 * @returns the loaded time
 */
export var loadedTimeSelector = createSelector(coreSelector, function (core) { return core.loadedTime; });
/**
 * @returns the buffer gap
 */
export var bufferGapSelector = createSelector(coreSelector, function (core) { return core.bufferGap; });
/**
 * @returns the seeking time
 */
export var seekingTimeSelector = createSelector(coreSelector, function (core) { return core.seekingTime; });
/**
 * @returns the video duration
 */
export var videoDurationSelector = createSelector(coreSelector, function (core) { return core.videoDuration; });
/**
 * @returns the video bitrate
 */
export var videoBitrateSelector = createSelector(coreSelector, function (core) { return core.videoBitrate; });
/**
 * @returns the audio bitrate
 */
export var audioBitrateSelector = createSelector(coreSelector, function (core) { return core.audioBitrate; });
/**
 * @returns the maximum video bitrate
 */
export var maxVideoBitrateSelector = createSelector(coreSelector, function (core) { return core.maxVideoBitrate; });
/**
 * @returns the estimated video bitrate
 */
export var estimatedVideoBitrateSelector = createSelector(coreSelector, function (core) { return core.estimatedVideoBitrate; });
/**
 * @returns the estimated audio bitrate
 */
export var estimatedAudioBitrateSelector = createSelector(coreSelector, function (core) { return core.estimatedAudioBitrate; });
/**
 * @returns the video quality profile
 */
export var videoQualityProfileSelector = createSelector(coreSelector, function (core) { return core.videoQualityProfile; });
/**
 * @returns the deepEqualManifest
 */
export var deepEqualManifestSelector = createDeepEqualSelector(coreSelector, function (core) { return core.manifest; });
/**
 * @returns the direct file URL
 */
export var directFileUrlSelector = createSelector(coreSelector, function (core) { return core.url; });
/**
 * @returns the streaming type
 */
export var streamingTypeSelector = createDeepEqualSelector(coreSelector, function (core) { return core.streamingType; });
/**
 * @returns the manifest latency
 */
export var latencySelector = createSelector(deepEqualManifestSelector, function (manifest) { var _a; return (_a = manifest === null || manifest === void 0 ? void 0 : manifest.latency) !== null && _a !== void 0 ? _a : null; });
/**
 * @returns whether or not the player is in fullscreen
 */
export var isFullscreenSelector = createSelector(coreSelector, function (core) { return !!core.isFullscreen; });
/**
 * @returns whether or not the request to preload the video has been made
 */
export var videoPreloadRequestedSelector = createSelector(coreSelector, function (core) { return core.videoPreloadRequested; });
/**
 * @returns the live start time
 */
export var liveStartTimeSelector = createSelector(coreSelector, function (core) { return core.liveStartTime; });
/**
 * @returns the list of available video bitrates
 */
export var availableVideoBitratesSelector = createDeepEqualSelector(coreSelector, function (core) { return core.availableVideoBitrates || []; });
/**
 * @returns whether or not the player is stopped
 */
export var isStoppedSelector = createSelector(playerStateSelector, function (state) { return state === STOPPED; });
/**
 * @returns whether or not the player is paused
 */
export var isPausedSelector = createSelector(playerStateSelector, function (state) { return state === PAUSED; });
/**
 * @returns whether or not the player has ended
 */
export var isEndedSelector = createSelector(playerStateSelector, function (state) { return state === ENDED; });
/**
 * @returns whether or not the player is loaded
 */
export var isLoadedSelector = createSelector(playerStateSelector, function (state) { return state === LOADED; });
/**
 * @returns whether or not the player is loading
 */
export var isLoadingSelector = createSelector(playerStateSelector, function (state) { return state === LOADING; });
/**
 * @returns whether or not the player is in live edge
 */
export var isReloadingSelector = createSelector(playerStateSelector, function (state) { return state === RELOADING; });
// TODO: The function should use isLowLatencyPlaying to an accurate value. At the moment,
// the selector does not take into consideration if it's the LL stream or the DVR stream which is playing.
/**
 * @returns {function(object): boolean}
 */
export var isLiveEdgeSelector = createSelector(liveGapSelector, latencySelector, isLowLatencyRequestedSelector, function (liveGap, latency, isLowLatencyRequested) {
    return isLiveEdge({
        liveGap: liveGap,
        latency: latency,
        isLowLatencyEnabled: isLowLatencyRequested,
    });
});
/**
 * @returns whether or not there are multiple languages or subtitles available
 */
export var hasMultipleLanguagesOrSubtitlesSelector = createSelector(availableLanguagesSelector, availableSubtitlesSelector, playerStateSelector, function (languages, subtitles, state) {
    return state !== STOPPED &&
        !!(Object.keys(languages).length > 1 || Object.keys(subtitles).length);
});
/**
 * @returns whether or not the player is fullscreenable
 */
export var isFullScreenableStateSelector = createSelector(playerStateSelector, function (playerState) { return isFullscreenable(playerState); });
/**
 * @returns whether or not the volume is muted
 */
export var isMutedSelector = createSelector(coreSelector, function (core) { return core.isMuted; });
/**
 * @returns whether or not the PIP is active
 */
export var isPipActiveSelector = createSelector(coreSelector, function (core) { return core.isPipActive; });
/**
 * @returns the server live time offset
 */
export var serverLiveTimeOffsetSelector = createSelector(coreSelector, function (core) { return core.serverLiveTimeOffset; });
/**
 * @returns the server timestamp
 */
export var serverTimestampSelector = createSelector(coreSelector, function (core) { return core.serverTimestamp; });
/**
 * @returns the server live time
 */
export var serverLiveTimeSelector = createSelector(serverLiveTimeOffsetSelector, liveTimeSelector, function (serverLiveTimeOffset, liveTime) {
    return new Date(liveTime || Date.now()).getTime() + serverLiveTimeOffset;
});
/**
 * @returns the last tracked user command
 */
export var lastTrackedUserCommandSelector = createDeepEqualSelector(coreSelector, function (core) { return core.lastTrackedUserCommand; });
/**
 * @returns the last tracked user command type
 */
export var lastTrackedUserCommandTypeSelector = createSelector(lastTrackedUserCommandSelector, function (lastTrackedUserCommand) { return lastTrackedUserCommand.commandType; });
/**
 * @returns the last tracked user key prop
 */
export var lastTrackedUserKeyPropSelector = createSelector(lastTrackedUserCommandSelector, function (lastTrackedUserCommand) { return lastTrackedUserCommand.keyProp; });
/**
 * @returns whether or not the user activated the sound on mobile with popin
 */
export var isUserActivatedSoundOnMobileWithPopinSelector = createSelector(coreSelector, function (core) { return core.isUserActivatedSoundOnMobileWithPopin; });
/**
 * @returns the current CDN location URL
 */
export var currentCdnLocationUrlSelector = createSelector(coreSelector, function (core) { return core.currentCdnLocationUrl; });
/**
 * @returns the current distribution
 */
export var currentDistributionSelector = createSelector(coreSelector, function (core) { return core.currentDistribution; });
/**
 * @returns whether or not the spinner should be displayed at the bottom of the player
 */
export var isSpinnerPositionBottomSelector = createSelector(isPlatformLiveSelector, isTvPlayerSelector, showTvLoadingScreenSelector, playerStateSelector, function (isPlatformLive, isTvPlayer, showTvLoadingScreen, playerState) {
    return isPlatformLive &&
        isTvPlayer &&
        showTvLoadingScreen &&
        playerState &&
        ![SEEKING, BUFFERING, FREEZING].includes(playerState);
});
/**
 * @returns the core player type
 */
export var corePlayerTypeSelector = createSelector(coreSelector, function (core) { return core.corePlayerType; });
/**
 * @returns the core player version
 */
export var corePlayerVersionSelector = createSelector(coreSelector, function (core) { return core.corePlayerVersion; });
/**
 * @returns the security level
 */
export var securityLevelSelector = createSelector(coreSelector, function (core) { return core.securityLevel; });
/**
 * @returns whether or not the UHD playset should be fetched
 */
export var shouldNotFetchUHDPlaysetSelector = createSelector(coreSelector, function (core) { return core.shouldNotFetchUHDPlayset; });
