import isEqual from 'fast-deep-equal';
import { createSelectorCreator, defaultMemoize } from 'reselect';
export var createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
export var createDateEqualSelector = createSelectorCreator(defaultMemoize, function (previousValue, nextValue) {
    if (previousValue instanceof Date && nextValue instanceof Date) {
        return previousValue.getTime() === nextValue.getTime();
    }
    if (previousValue instanceof Number && nextValue instanceof Number) {
        return previousValue === nextValue;
    }
    return false;
});
