import { createSelector } from 'reselect';
export var airplaySelector = function (state) {
    return state.airplay;
};
/**
 * @returns {function(object): bool}
 */
export var isAirplayActiveSelector = createSelector(airplaySelector, function (airplay) { return airplay.isActive; });
/**
 * @returns {function(object): bool}
 */
export var isAirplayAvailableSelector = createSelector(airplaySelector, function (airplay) { return airplay.isAvailable; });
