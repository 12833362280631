/**
 * Generate an random unique identifier thanks to random consecutive letters and numbers.
 * Such as: _n6vu9jkz8
 * This function has been picked from: https://gist.github.com/gordonbrander/2230317
 *
 * /!\ Using the uuid library seems overkill sometimes, that's why this function exist.
 *
 * @returns a string
 */
function uuid(): string {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

export default uuid;
