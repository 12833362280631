export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const VERYLARGE = 'verylarge';

export const THRESHOLDS = {
  [SMALL]: 0,
  [MEDIUM]: 640,
  [LARGE]: 1050,
  [VERYLARGE]: 1440,
};

export const containerQuery = {
  [SMALL]: {
    maxWidth: 639,
  },
  [MEDIUM]: {
    minWidth: 640,
    maxWidth: 1049,
  },
  [LARGE]: {
    minWidth: 1050,
    maxWidth: 1439,
  },
  [VERYLARGE]: {
    minWidth: 1440,
  },
};

export const containerOptions = {
  setAttribute: true,
};

export const subtitles = {
  MIN_SIZE: 10,
  REF_SIZE: 20,
  MAX_SIZE: 200,
  REF_WIDTH: containerQuery[MEDIUM].minWidth,
};

export const sidepanelWidth = {
  SMALL_WIDTH: 300,
  MEDIUM_WIDTH: 320,
  LARGE_WIDTH: 360,
  VERYLARGE_WIDTH: 360,
};

// Size of program list items depending on breakpoints
export const programListItemsWidth = {
  [SMALL]: 71,
  [MEDIUM]: 76,
  [LARGE]: 95,
  [VERYLARGE]: 95,
};
