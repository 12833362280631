// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Generated on https://cssgradient.io/ */
`, "",{"version":3,"sources":["webpack://./src/ui/assets/styles/telecomitalia/colors.css"],"names":[],"mappings":"AAKA,yCAAyC","sourcesContent":["@value actionColor--telecomitalia: #bb930b;\n@value lightActionColor--telecomitalia: #e9ea75;\n@value darkActionColor--telecomitalia: #794d01;\n@value hoverActionColor--telecomitalia: #6A4C18;\n\n/* Generated on https://cssgradient.io/ */\n@value gradientActionColor--telecomitalia: linear-gradient(90deg, rgba(121,77,1,1) 0%, rgba(121,77,1,1) 0%, rgba(158,105,0,1) 10%, rgba(163,122,10,1) 25%, rgba(187,147,11,1) 50%, rgba(219,198,62,1) 75%, rgba(231,225,101,1) 90%, rgba(233,234,117,1) 100%);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionColor--telecomitalia": `#bb930b`,
	"lightActionColor--telecomitalia": `#e9ea75`,
	"darkActionColor--telecomitalia": `#794d01`,
	"hoverActionColor--telecomitalia": `#6A4C18`,
	"gradientActionColor--telecomitalia": `linear-gradient(90deg, rgba(121,77,1,1) 0%, rgba(121,77,1,1) 0%, rgba(158,105,0,1) 10%, rgba(163,122,10,1) 25%, rgba(187,147,11,1) 50%, rgba(219,198,62,1) 75%, rgba(231,225,101,1) 90%, rgba(233,234,117,1) 100%)`
};
module.exports = ___CSS_LOADER_EXPORT___;
