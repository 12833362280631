import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselect';
export var pinglogSelector = function (state) {
    return state.pinglog;
};
/**
 * @returns {function(object): array}
 */
export var pinglogErrorSelector = createSelector(pinglogSelector, function (pinglog) { return pinglog.error || null; });
/**
 * @returns {function(object): array}
 */
export var pinglogConfigSelector = createDeepEqualSelector(pinglogSelector, function (pinglog) { return pinglog.config || {}; });
