import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
export var adsSelector = function (state) { return state.ads; };
/**
 * @returns {function(object): object}
 */
export var midrollSelector = createSelector(adsSelector, function (ads) { return ads.midroll || {}; });
/**
 * @returns {function(object): object}
 */
export var midrollStartTimesSelector = createSelector(midrollSelector, function (midroll) { return midroll.startTimes || []; });
/**
 * @returns {function(object): object}
 */
export var currentAdSelector = createSelector(adsSelector, function (ads) { return ads.currentAd; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentAdSelector = createSelector(currentAdSelector, function (currentAd) { return !!currentAd; });
/**
 * @returns {function(object): number}
 */
export var currentAdDurationSelector = createSelector(currentAdSelector, function (currentAd) { var _a; return ((_a = currentAd === null || currentAd === void 0 ? void 0 : currentAd.creatives) === null || _a === void 0 ? void 0 : _a[0]) ? currentAd.creatives[0].duration : 0; });
/**
 * @returns {function(object): object}
 */
export var vpaidSelector = createSelector(currentAdSelector, function (currentAd) { return currentAd === null || currentAd === void 0 ? void 0 : currentAd.vpaid; });
/**
 * @returns {function(object): bool}
 */
export var adFocusSelector = createSelector(adsSelector, function (ads) { return ads.adFocus; });
/**
 * @returns {function(object): number}
 */
export var adsTotalDurationSelector = createSelector(adsSelector, function (ads) { return ads.totalDuration; });
/**
 * @returns {function(object): boolean}
 */
export var canSkipCurrentAdSelector = createSelector(adsSelector, function (ads) { return ads.canSkipCurrentAd; });
/**
 * @returns {function(object): boolean}
 */
export var canSkipAfterSelector = createSelector(adsSelector, function (ads) { return ads.canSkipAfter; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowAdSkipButtonsSelector = createSelector(adsSelector, function (ads) { return ads.shouldShowAdSkipButtons; });
/**
 * @returns {function(object): integer}
 */
export var adsRemainingTimeSelector = createSelector(adsSelector, function (ads) { return ads.remainingTime; });
/**
 * @returns {function(object): boolean}
 */
export var canSkipAllAdsSelector = createSelector(adsSelector, function (ads) { return ads.canSkipAllAds; });
/**
 * @returns {function(object): number}
 */
export var adsWillStartInSelector = createSelector(adsSelector, function (ads) { return ads.adWillStartIn; });
/**
 * @returns {function(object): array}
 */
export var adsPlaylistSelector = createSelector(adsSelector, function (ads) { return ads.playlist || []; });
/**
 * @returns {function(object): number}
 */
export var adSkipCountdownValueSelector = createSelector(canSkipAfterSelector, adsTotalDurationSelector, adsRemainingTimeSelector, function (canSkipAfter, totalDuration, remainingTime) {
    return canSkipAfter
        ? Math.max(canSkipAfter - (totalDuration - remainingTime), 0)
        : 0;
});
/**
 * @returns {function(object): number}
 */
export var currentAdProgressSelector = createSelector(adsTotalDurationSelector, adsRemainingTimeSelector, function (totalDuration, remainingTime) { return totalDuration - remainingTime; });
/**
 * @returns {function(object): object}
 */
export var adsCounterSelector = createSelector(adsSelector, function (ads) { return ads.counter || {}; });
/**
 * @returns {function(object): number}
 */
export var adsCounterCurrentSelector = createSelector(adsCounterSelector, function (adsCounter) { return adsCounter.current; });
/**
 * @returns {function(object): string | undefined}
 */
export var adSponsorGenreSelector = createSelector(adsSelector, function (ads) { return ads.sponsorGenre; });
/**
 * @returns {function(object): boolean}
 */
export var currentAdIsClickableSelector = createSelector(vpaidSelector, function (vpaid) { return !vpaid; });
/**
 * @returns {function(object): object}
 */
export var adScheduleSelector = createDeepEqualSelector(adsSelector, function (ads) { return ads.schedule; });
/**
 * @returns {function(object): boolean}
 */
export var showAntiAdSkipNotificationSelector = createSelector(adsSelector, function (ads) { return ads.showAntiAdSkipNotification; });
