import { createSelector } from 'reselect';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { isAndroid, isSafariMobile } from '@canalplus/oneplayer-utils';
import { getInstanceVideoElement } from '../../utils/instance';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
var THEMES = BusinessTypes.THEMES, DEVICE_TYPES = BusinessTypes.DEVICE_TYPES, NON_AUTHORIZED = BusinessTypes.LIVE_TV_GROUP_TYPES.NON_AUTHORIZED;
var HOURS_3_MS = 10800000;
export var configSelector = function (state) {
    return state.config;
};
/**
 * @returns {function(object): object}
 */
export var contentSelector = createSelector(configSelector, function (config) { return config.content; });
/**
 * @returns {function(object): string|number|undefined}
 */
export var contentEpgIdSelector = createSelector(contentSelector, function (content) {
    return typeof content !== 'string' && 'epgId' in content ? content.epgId : undefined;
});
/**
 * @returns {function(object): string|number|undefined}
 */
export var contentChannelNumberSelector = createSelector(contentSelector, function (content) {
    return typeof content !== 'string' && 'channelNumber' in content
        ? content.channelNumber
        : undefined;
});
/**
 * @returns {function(object): string|number}
 */
export var multiContentSelector = createSelector(configSelector, function (config) { return config.multiContent; });
/**
 * @returns {function(object): object}
 */
export var debugSelector = createSelector(configSelector, function (config) { return config.debug; });
/**
 * @returns {function(object): string | undefined}
 */
export var customAppIdSelector = createSelector(configSelector, function (config) { var _a; return (_a = config.debug) === null || _a === void 0 ? void 0 : _a.customAppId; });
/**
 * @returns {function(object): string}
 */
export var playerUidSelector = createSelector(configSelector, function (config) { return config.uid; });
/**
 * @returns {function(object): string}
 */
export var referenceDateTimeSelector = createSelector(configSelector, function (config) { return config.live.referenceDateTime; });
/**
 * @returns {function(object): string}
 */
export var playerVideoElementSelector = createSelector(playerUidSelector, function (id) { return getInstanceVideoElement(id); });
/**
 * @returns {function(object): string}
 */
export var deviceTypeSelector = createSelector(configSelector, function (config) { return config.deviceType; });
/**
 * @returns Device informations
 */
export var deviceInfoSelector = createSelector(configSelector, function (config) { return config.deviceInfo; });
/**
 * @returns {function(object): string}
 */
export var platformSelector = createSelector(configSelector, function (config) { return config.platform; });
/**
 * @returns {function(object): boolean}
 */
export var isPlatformLiveSelector = createSelector(platformSelector, function (platform) { return platform === 'live'; });
/**
 * @returns {function(object): boolean}
 */
export var isPlatformHapiSelector = createSelector(platformSelector, function (platform) { return platform === 'hapi'; });
/**
 * @returns {function(object): string}
 */
export var drmEnvSelector = createSelector(configSelector, function (config) { return config.drmEnv; });
/**
 * @returns {function(object): object}
 */
export var configDrmCertificatesSelector = createSelector(configSelector, function (config) { var _a; return (_a = config.drm) === null || _a === void 0 ? void 0 : _a.certificates; });
/**
 * @returns {function(object): bool}
 */
export var drmNeededSelector = createSelector(configSelector, function (config) { return config.drmNeeded; });
/**
 * @returns {function(object): string}
 */
export var baseSelector = createSelector(configSelector, function (config) { return config.base; });
/**
 * @returns {function(object): boolean}
 */
export var isWebSelector = createSelector(deviceTypeSelector, function (deviceType) { return deviceType === DEVICE_TYPES.DESKTOP_MOBILE; });
/**
 * @returns {function(object): boolean}
 */
export var isChromecastSelector = createSelector(deviceTypeSelector, function (deviceType) { return deviceType === DEVICE_TYPES.CHROMECAST; });
/**
 * @returns {function(object): boolean}
 */
export var isPlaystation4Selector = createSelector(deviceTypeSelector, function (deviceType) { return deviceType === DEVICE_TYPES.PLAYSTATION_4; });
/**
 * @returns {function(object): boolean}
 */
export var isPlaystation5Selector = createSelector(deviceTypeSelector, function (deviceType) { return deviceType === DEVICE_TYPES.PLAYSTATION_5; });
/**
 * @returns {function(object): boolean}
 */
export var isPlaystationSelector = createSelector(deviceTypeSelector, function (deviceType) {
    return [DEVICE_TYPES.PLAYSTATION_4, DEVICE_TYPES.PLAYSTATION_5].includes(deviceType);
});
/**
 * @returns {function(object): boolean}
 */
export var isLgSelector = createSelector(deviceTypeSelector, function (deviceType) { return deviceType === 'lg'; });
/**
 * @returns {function(object): object}
 */
export var liveReloadConfigSelector = createSelector(configSelector, function (config) { return config.live.reload; });
/**
 * @returns {function(object): object}
 */
export var paramsSelector = createSelector(configSelector, function (config) { return config.params; });
/**
 * @returns manifest loader received in the config api
 */
export var manifestLoaderSelector = createSelector(paramsSelector, function (params) { return params.manifestLoader; });
/**
 * @returns {function(object): number}
 */
export var baseBandwidthSelector = createSelector(paramsSelector, function (params) { return params.baseBandwidth; });
/**
 * @returns {function(object): number}
 */
export var configLiveGapSelector = createSelector(paramsSelector, function (params) { return params.liveGap; });
/**
 * @returns {function(object): number}
 */
export var lowLatencyLiveGapSelector = createSelector(paramsSelector, function (params) { return params.lowLatencyLiveGap; });
/**
 * @returns {function(object): boolean}
 */
export var videoResolutionLimitSelector = createSelector(paramsSelector, function (params) { return params.videoResolutionLimit; });
/**
 * @returns {function(object): boolean}
 */
export var isChromecastEnabledSelector = createSelector(paramsSelector, function (params) { return params.enableCcast; });
/**
 * @returns {function(object): boolean}
 */
export var showContentInfoConfigSelector = createSelector(paramsSelector, function (params) { return params.showContentInfo; });
/**
 * @returns {function(object): string}
 */
export var endingBehaviorConfigSelector = createSelector(paramsSelector, function (params) { return params.endingBehavior; });
/**
 * @returns {function(object): string}
 */
export var offerZoneSelector = createSelector(paramsSelector, function (params) { return params.offerZone; });
export var unauthorizedChannelErrorContentSelector = createSelector(paramsSelector, function (params) { return params.unauthorizedChannelErrorContent; });
/**
 * @returns {function(object): string}
 */
export var unauthorizedChannelModeSelector = createSelector(paramsSelector, function (params) { return params.unauthorizedChannelMode; });
/**
 * @returns {function(object): number | null}
 */
export var maxCompatibleVideoOutputSelector = createSelector(paramsSelector, function (params) { return params.maxCompatibleVideoOutput; });
/**
 * @returns {function(object): string}
 */
export var offerLocationSelector = createSelector(paramsSelector, function (params) { return params.offerLocation; });
/**
 * @returns {function(object): boolean}
 */
export var shouldPersistLicenseSelector = createSelector(paramsSelector, function (params) { return params.shouldPersistLicense; });
/**
 * @returns {function(object): string}
 */
export var contentFunctionalTypeConfigSelector = createSelector(configSelector, function (config) { return config.contentFunctionalType; });
/**
 * @returns {function(object): object}
 */
export var adsConfigSelector = createSelector(configSelector, function (config) { return config.ads; });
/**
 * @returns {function(object): string | undefined}
 */
export var acmBaseUrlSelector = createSelector(configSelector, function (config) { return config.acm; });
/**
 * @returns {function(object): object}
 */
export var configPreferredLanguageSelector = createSelector(paramsSelector, function (params) { return params.preferredLanguage; });
/**
 * @returns {function(object): object}
 */
export var configPreferredSubtitleSelector = createSelector(paramsSelector, function (params) { return params.preferredSubtitle; });
/**
 * @returns {function(object): boolean}
 */
export var showContentHeadingSelector = createSelector(paramsSelector, function (params) { return params.showContentHeading; });
/**
 * @returns {function(object): boolean}
 */
export var showContentControlsSelector = createSelector(paramsSelector, function (params) { return params.showContentControls; });
/**
 * @returns {function(object): boolean}
 */
export var nextEpisodeAutoplaySelector = createSelector(paramsSelector, function (params) {
    return params.nextEpisodeAutoplay === undefined || params.nextEpisodeAutoplay;
});
/**
 * @returns {function(object): object}
 */
export var playerPinlogConfigSelector = createSelector(configSelector, function (config) { return config.playerPingLog || {}; });
/**
 * @returns {function(object): object}
 */
export var switchPlusConfigSelector = createSelector(configSelector, function (config) { return config.switchPlus || {}; });
/**
 * @returns {function(object): boolean}
 */
export var enablePiPSelector = createSelector(paramsSelector, function (params) { return params.enablePiP; });
/**
 * @returns {function(object): boolean}
 */
export var skipAutoSelector = createSelector(paramsSelector, function (params) { return params.skipAuto; });
/**
 * @returns {function(object): boolean}
 */
export var enableAirplaySelector = createSelector(paramsSelector, function (params) { return params.enableAirplay; });
/**
 * @returns {function(object): boolean}
 */
export var shouldBeMutedAtStartSelector = createSelector(paramsSelector, function (params) { return Boolean(params.muted); });
/**
 * @returns {function(object): boolean}
 */
export var autoPlaySelector = createSelector(paramsSelector, function (params) {
    return Boolean(params.autoplay);
});
/**
 * @returns {function(object): boolean}
 */
export var shouldBeMutedAtStartForMobileSelector = createSelector(paramsSelector, autoPlaySelector, function (params, autoplay) {
    return Boolean(params.muted) && autoplay && (isSafariMobile() || isAndroid());
});
/**
 * @returns {function(object): object}
 */
export var exitButtonSelector = createSelector(paramsSelector, function (params) { return params.exitButton || {}; });
/**
 * @returns {function(object): boolean}
 */
export var isAntiAdSkipEnabledSelector = createSelector(paramsSelector, function (params) { return params.enableAntiAdSkip; });
/**
 * @returns {function(object): boolean}
 */
export var exitButtonShowFullscreenSelector = createSelector(exitButtonSelector, function (exitButton) { return !!exitButton.showFullScreen; });
/**
 * @returns {function(object): boolean}
 */
export var exitButtonShowWindowedSelector = createSelector(exitButtonSelector, function (exitButton) { return !!exitButton.showWindowed; });
/**
 * @returns start position received in the config api
 */
export var startAtSelector = createSelector(paramsSelector, function (params) { return params.startAt; });
/**
 * @returns program is received in the config api
 */
export var broadcastIdSelector = createSelector(paramsSelector, function (params) { return params.broadcastId; });
/**
 * @returns {function(object): boolean}
 */
export var fetchContentSeriesSelector = createSelector(paramsSelector, function (params) { return params.fetchContentSeries; });
/**
 * @returns {function(object): number | null}
 */
export var contentDisruptingModalAtSelector = createSelector(paramsSelector, function (params) { return params.contentDisruptingModalAt; });
/**
 * @returns {function(object): boolean}
 */
export var isTabKeyNavigationCapturedByPlayerSelector = createSelector(paramsSelector, function (params) { return params.isTabKeyNavigationCapturedByPlayer; });
/**
 * @returns {function(object): boolean}
 */
export var isNationalChannelNumberingSelector = createSelector(paramsSelector, function (params) { return params.isNationalChannelNumbering; });
/**
 * @returns {function(object): boolean}
 */
export var displayMidrollMarkersSelector = createSelector(adsConfigSelector, function (ads) { return ads.displayMidrollMarkers; });
/**
 * @returns {function(object): object}
 */
export var contentTypesSelector = createDeepEqualSelector(configSelector, function (config) { return config.contentTypes; });
/**
 * @returns {function(object): boolean}
 */
export var isAdPauseEnabledSelector = createSelector(adsConfigSelector, function (ads) { return ads.enableAdPause; });
/**
 * @returns {function(object): boolean}
 */
export var isAdSwitchingEnabledSelector = createSelector(adsConfigSelector, function (ads) { return ads.enableAdSwitching; });
/**
 * @returns {function(object): string}
 */
export var adsDisplayElementIdSelector = createSelector(adsConfigSelector, function (ads) { return ads.displayElementId; });
/**
 * @returns {function(object): boolean}
 */
export var enableAntiAdBlockingSelector = createSelector(adsConfigSelector, function (config) { return config.enableAntiAdBlocking; });
/**
 * @returns {function(object): number | undefined}
 */
export var adsMaxPreferredBitrateSelector = createSelector(adsConfigSelector, function (ads) { return ads.maxPreferredBitrate; });
/**
 * @returns {function(object): string | undefined}
 */
export var adsPmkUrlParametersSelector = createSelector(adsConfigSelector, function (ads) { var _a; return (_a = ads.pmk) === null || _a === void 0 ? void 0 : _a.urlParameters; });
/**
 * @returns {function(object): Array}
 */
export var configLanguagesSelector = createDeepEqualSelector(configSelector, function (config) { return config.languages || []; });
/**
 * @returns {function(object): Array}
 */
export var configSubtitlesSelector = createDeepEqualSelector(configSelector, function (config) { return config.subtitles || []; });
/**
 * @returns {function(object): boolean}
 */
export var configEnableAdSelector = createSelector(paramsSelector, function (params) { return params.enableAd; });
/**
 * @returns {function(object): boolean}
 */
export var configEnableParentalRatingSelector = createSelector(paramsSelector, function (params) { return params.enableParentalRating; });
/**
 * @returns {function(object): boolean}
 */
export var showContentTitleSelector = createSelector(paramsSelector, function (params) { return params.showContentTitle; });
/**
 * @returns {function(object): boolean}
 */
export var skipContentSelector = createSelector(configSelector, function (config) { return config.skipContent; });
export var showAvailabilityDateSelector = createSelector(paramsSelector, function (params) { return params.showAvailabilityDate; });
export var showTvLoadingScreenSelector = createSelector(paramsSelector, function (params) { return params.showTvLoadingScreen; });
export var showPosterHeadingSelector = createSelector(paramsSelector, function (params) { return params.showPosterHeading; });
/**
 * @returns {function(object): object}
 */
export var configContextMenuSelector = createDeepEqualSelector(configSelector, function (config) { return config.contextMenu; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowQualityControlContextMenuSelector = createSelector(configContextMenuSelector, function (contextMenu) { return contextMenu.shouldShowQualityControl; });
/**
 * @returns {function(object): boolean}
 */
export var enableContextMenuSelector = createSelector(configContextMenuSelector, function (contextMenu) { return contextMenu.enable; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowShortcutsContextMenuSelector = createSelector(configContextMenuSelector, function (contextMenu) { return contextMenu.shouldShowShortcuts; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowDebugContextMenuSelector = createSelector(configContextMenuSelector, function (contextMenu) { return contextMenu.shouldShowDebug; });
/**
 * @returns the context menu's links
 */
export var contextMenuLinksSelector = createDeepEqualSelector(configContextMenuSelector, function (contextMenu) { return contextMenu.links; });
/**
 * @returns {function(object): object}
 */
export var configFeatureFlagsSelector = createDeepEqualSelector(configSelector, function (config) { return config.featureFlags; });
/**
 * @returns {function(object): string}
 */
export var uiLanguagesSelector = createSelector(configSelector, function (config) { return config.uiLanguage; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowPlaybackRateSelector = createSelector(configFeatureFlagsSelector, function (configFeatureFlags) { return Boolean(configFeatureFlags === null || configFeatureFlags === void 0 ? void 0 : configFeatureFlags.shouldShowPlaybackRate); });
/**
 * @returns {function(object): object}
 */
export var isLowLatencyRequestedSelector = createSelector(configFeatureFlagsSelector, function (configFeatureFlags) { return Boolean(configFeatureFlags === null || configFeatureFlags === void 0 ? void 0 : configFeatureFlags.isLowLatencyRequested); });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowAudioOnlySelector = createSelector(configFeatureFlagsSelector, function (configFeatureFlags) { return Boolean(configFeatureFlags === null || configFeatureFlags === void 0 ? void 0 : configFeatureFlags.shouldShowAudioOnly); });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowDyslexicTextTrackSelector = createSelector(configFeatureFlagsSelector, function (configFeatureFlags) {
    return Boolean(configFeatureFlags === null || configFeatureFlags === void 0 ? void 0 : configFeatureFlags.shouldShowDyslexicTextTrack);
});
/**
 * @returns {function(object): boolean | undefined}
 */
export var isHdrRequestedOnLivePlatformFeatureFlagSelector = createSelector(configFeatureFlagsSelector, function (configFeatureFlags) { return configFeatureFlags === null || configFeatureFlags === void 0 ? void 0 : configFeatureFlags.isHdrRequestedOnLivePlatform; });
/**
 * @returns {function(object): string}
 */
export var customerServiceLinkSelector = createSelector(configSelector, function (config) { return config.customerServiceLink; });
/**
 * @returns {function(object): string}
 */
export var refererTrackingIdSelector = createSelector(configSelector, function (config) { return config.refererTrackingId; });
/**
 * @returns {function(object): string}
 */
export var variantSelector = createSelector(configSelector, function (config) { return config.variant; });
/**
 * @returns {function(object): number}
 */
export var maxLiveSessionSelector = createSelector(configSelector, function (config) { return config.live.maxLiveSessionInMinutes; });
/**
 * @returns {function(object): number}
 */
export var globalChannelsPollIntervalSelector = createSelector(configSelector, function (config) { return config.live.globalChannels.pollInterval; });
/**
 * @returns {function(object): object}
 */
export var globalChannelsSelector = createSelector(configSelector, function (config) { return config.live.globalChannels; });
/**
 * @returns {function(object): string}
 */
export var genresSelector = createSelector(configSelector, function (config) { return config.live.genres; });
/**
 * @returns {function(object): number}
 */
export var liveTvGroupTypeSelector = createSelector(configSelector, offerZoneSelector, function (config, offerZone) { var _a; return (_a = config.live.liveTvGroupType[offerZone]) !== null && _a !== void 0 ? _a : ''; });
/**
 * @returns {function(object): string}
 */
export var initSelector = createSelector(configSelector, function (config) { return config.live.init; });
/**
 * @returns {function(object): string}
 */
export var fallbackEpgIdSelector = createSelector(configSelector, function (config) { return config.live.fallbackEpgId; });
/**
 * @returns {function(object): string}
 */
export var configBaseUrlSelector = createSelector(configSelector, function (config) { return config.configBaseUrl; });
/**
 * @returns {function(object): ArrayBuffer}
 */
export var drmCertificateSelector = createSelector(configSelector, function (config) { return config.drmCertificate; });
/**
 * @returns {function(object): number}
 */
export var shouldUseNonAuthorizedChannelsSelector = createSelector(liveTvGroupTypeSelector, function (liveTvGroupType) { return liveTvGroupType === null || liveTvGroupType === void 0 ? void 0 : liveTvGroupType.includes(NON_AUTHORIZED.toString()); });
export var qrCodeSelector = createSelector(configSelector, function (config) { return config.live.qrCode; });
/**
 * @returns {function(object): string}
 */
export var contextSelector = createSelector(configSelector, function (config) { return config.context; });
/**
 * @returns {function(object): string}
 */
export var envSelector = createSelector(configSelector, function (config) { return config.env; });
/**
 * @returns {function(object): object}
 */
export var parentalRatingHoursSelector = createSelector(configSelector, function (config) { return config.live.parentalRatingHours; });
/**
 * @returns {function(object): object}
 */
export var parentalLinksSelector = createSelector(configSelector, function (config) { var _a, _b; return (_b = (_a = config.live.parental) === null || _a === void 0 ? void 0 : _a.links) !== null && _b !== void 0 ? _b : {}; });
/**
 * @returns {function(object): object}
 */
export var externalLinksSelector = createSelector(configSelector, function (config) { return config.live.externalLinks; });
/**
 * @returns {function(object): string}
 */
export var chidSelector = createSelector(configSelector, function (config) { return config.live.chid; });
/**
 * @returns {function(object): string}
 */
export var isTvPlayerSelector = createSelector(paramsSelector, function (params) { return params.theme === THEMES.TV; });
/**
 * @returns {function(object): object}
 */
export var keyMappingSelector = createSelector(paramsSelector, function (params) { return params.keyMapping; });
/**
 * @returns {function(object): number}
 */
export var wantedBufferAheadSelector = createSelector(paramsSelector, function (params) { return params.wantedBufferAhead; });
/**
 * @returns {function(object): number}
 */
export var maxVideoBufferSizeSelector = createSelector(paramsSelector, function (params) { return params.maxVideoBufferSize; });
/**
 * @returns {function(object): number}
 */
export var maxBufferAheadSelector = createSelector(paramsSelector, function (params) { return params.maxBufferAhead; });
/**
 * @returns {function(object): number}
 */
export var maxBufferBehindSelector = createSelector(paramsSelector, function (params) { return params.maxBufferBehind; });
/**
 * @returns {function(object): number}
 */
export var maxSessionCacheSizeSelector = createSelector(paramsSelector, function (params) { return params.maxSessionCacheSize; });
/**
 * @returns {function(object): boolean}
 */
export var closeSessionsOnStopSelector = createSelector(paramsSelector, function (params) { return params.closeSessionsOnStop; });
/**
 * @returns {function(object): number}
 */
export var manifestRequestTimeoutSelector = createSelector(paramsSelector, function (params) { return params.manifestRequestTimeout; });
/**
 * @returns {function(object): number}
 */
export var segmentRequestTimeoutSelector = createSelector(paramsSelector, function (params) { return params.segmentRequestTimeout; });
/**
 * @returns {function(object): boolean}
 */
export var enableMultiThreadingSelector = createSelector(configSelector, function (config) { var _a; return (_a = config.rxPlayer) === null || _a === void 0 ? void 0 : _a.enableMultiThreading; });
/**
 * @returns {function(object): boolean}
 */
export var enableFastSwitchingSelector = createSelector(paramsSelector, function (params) { return params.enableFastSwitching; });
/**
 * @returns {function(object): number}
 */
export var sleepTimerSelector = createSelector(configSelector, function (config) { return config.sleepTimer || HOURS_3_MS; });
/**
 * @returns {function(object): string}
 */
export var currentKeySystemSelector = createSelector(configSelector, function (config) { return config.keySystem; });
/**
 * @returns the supported drms on the browser ordered by preference
 */
export var supportedDrmsSelector = createSelector(configSelector, function (config) { return config.supportedDrms; });
/**
 * @returns {function(object): string}
 */
export var configSecurityLevelSelector = createSelector(configSelector, function (config) { return config.securityLevel; });
/**
 * @returns {function(object): string}
 */
export var environmentSelector = createSelector(contextSelector, envSelector, function (ctx, env) { return "".concat(ctx, "-").concat(env); });
/**
 * @returns {function(object): object}
 */
export var hapiConfigSelector = createDeepEqualSelector(configSelector, function (config) { return config.hapi; });
/**
 * @returns {function(object): string}
 */
export var hapiPictureTemplateURLSelector = createSelector(hapiConfigSelector, function (hapiConfig) { return hapiConfig.picture; });
/**
 * @returns {function(object): string}
 */
export var hapiApiVersionSelector = createSelector(hapiConfigSelector, function (hapiConfig) { return hapiConfig.apiVersion; });
/**
 * @returns {function(object): string}
 */
export var hapiServiceSelector = createSelector(hapiConfigSelector, function (hapiConfig) { return hapiConfig.service; });
export var callbacksSelector = createSelector(configSelector, function (config) { return config === null || config === void 0 ? void 0 : config.callbacks; });
export var passSelector = createSelector(configSelector, function (config) { return config.pass; });
/**
 * @returns {function(object): string | null | undefined}
 */
export var originFromScriptSelector = createSelector(configSelector, function (config) { return config.originFromScript; });
/**
 * @returns {function(object): object}
 */
export var hodorConfigSelector = createSelector(configSelector, function (config) { return config.hodor; });
/**
 * @returns {function(object): object}
 */
export var expertModeConfigSelector = createSelector(hodorConfigSelector, function (hodorConfig) { return hodorConfig.expertMode; });
/**
 * @returns {function(object): string | null | undefined}
 */
export var parentalCodeCreationUrlSelector = createSelector(hodorConfigSelector, function (hodorConfig) { return hodorConfig.parentalCodeCreationUrl; });
/**
 * @returns {function(object): string | undefined}
 */
export var castSplashScreenDefaultImageSelector = createSelector(configSelector, function (config) { var _a; return (_a = config.cast) === null || _a === void 0 ? void 0 : _a.splashScreenDefaultImage; });
/**
 * @returns {function(object): string}
 */
export var transportSelector = createSelector(paramsSelector, function (params) { return params.transport; });
