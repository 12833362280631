import { actionTypes, BusinessTypes } from '@canalplus/oneplayer-constants';
import { getElementPositionAttributes } from '../utils/positions';
var TOOLTIP_MOUSE_SHOW = actionTypes.TOOLTIP_MOUSE_SHOW, TOOLTIP_SHOW = actionTypes.TOOLTIP_SHOW, TOOLTIP_HIDE = actionTypes.TOOLTIP_HIDE, TOOLTIP_SET_CONTENT = actionTypes.TOOLTIP_SET_CONTENT;
var TOP = BusinessTypes.TOOLTIP_POSITIONS.TOP;
/**
 * Wrapper for showFollowingMouse action to get dimensions of container
 * @param {JSX.Element} content Component to display in tooltip
 * @param {HTMLElement} element Component used as boudaries
 * @param {IMouse} mousePosition {x, y} coordinates to follow
 * @param {string} position top, bottom...
 * @param {JSX.Element} thumbnail img tag to display in tooltip
 * @returns {{type, payload}}
 */
export function showFollowingMouseFromHtmlElementAction(_a) {
    var content = _a.content, container = _a.container, _b = _a.mouse, x = _b.x, y = _b.y, position = _a.position, thumbnail = _a.thumbnail;
    return {
        type: TOOLTIP_MOUSE_SHOW,
        payload: {
            content: content,
            element: getElementPositionAttributes(container),
            x: x,
            y: y,
            position: position,
            thumbnail: thumbnail,
        },
    };
}
/**
 * @param content
 * @param htmlElement
 * @param position
 * @returns {{type, payload: {content: *, element: *, position}}}
 */
export function showFromHtmlElementAction(content, htmlElement, position, theme) {
    if (position === void 0) { position = TOP; }
    if (theme === void 0) { theme = 'info'; }
    return {
        type: TOOLTIP_SHOW,
        payload: {
            content: content,
            element: getElementPositionAttributes(htmlElement),
            position: position,
            theme: theme,
        },
    };
}
export function hideAction() {
    return {
        type: TOOLTIP_HIDE,
    };
}
export function setContentAction(content) {
    return {
        type: TOOLTIP_SET_CONTENT,
        payload: content,
    };
}
