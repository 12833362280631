/**
 * Returns all focusable element descendants of container node (or document if none given).
 * @param container element containing the focusable element
 */
export const getFocusableElements = (
  container?: Element,
): NodeListOf<HTMLElement> | undefined => {
  const containerElement = container || document;
  return containerElement.querySelectorAll(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
  );
};
