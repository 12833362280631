// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../assets/styles/durations.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y9W4P7ieeEAa2R6d1E0b {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all ${___CSS_LOADER_ICSS_IMPORT_0___.locals["layoutShiftDuration"]} ease;
  transform: translate3d(0, 0, 0);
  will-change: transform, width, height, left, top;
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/layout/videowrapper/VideoWrapper.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,iEAAwC;EACxC,+BAA+B;EAC/B,gDAAgD;AAClD","sourcesContent":["@value layoutShiftDuration from '../../../../assets/styles/durations.css';\n\n.VideoWrapper {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  transition: all layoutShiftDuration ease;\n  transform: translate3d(0, 0, 0);\n  will-change: transform, width, height, left, top;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutShiftDuration": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["layoutShiftDuration"]}`,
	"VideoWrapper": `Y9W4P7ieeEAa2R6d1E0b`
};
module.exports = ___CSS_LOADER_EXPORT___;
