/**
 * Map each element using a mapping function, then flat the result into
 * a new array.
 * @param {Array.<*>} originalArray
 * @param {Function} fn
 */
export default function flatMap<T, U>(
  originalArray: T[],
  fn: (arg: T) => U[] | U,
): U[] {
  // If possible keep the original flatMap
  if (typeof (Array.prototype as any).flatMap === 'function') {
    return (originalArray as any).flatMap(fn);
  }

  return originalArray.reduce((acc: U[], arg: T): U[] => {
    const r = fn(arg);
    if (Array.isArray(r)) {
      acc.push(...r);
      return acc;
    }
    acc.push(r);
    return acc;
  }, []);
}
