export function isModernMacOS(userAgent: string): boolean {
  // We will match anything between 'Mac Os X ' and ')' to find
  // the version number of the os
  const matchedResult = userAgent.match(/Mac OS X (.*?)\)/);

  // The matched result must return an array.
  // The second index of the array will contain the captured group.
  if (matchedResult && matchedResult.length === 2) {
    // The version is always expressed with underscores. Eg: 10_12_10
    const versionStr = matchedResult[1].split('_');
    const formattedVersion = [];

    for (let n = 0, len = versionStr.length; n < len; n += 1) {
      const num = versionStr[n];

      if (num.match(/^\d+$/)) {
        // Each value within the array must be an integer
        formattedVersion.push(parseInt(num, 10));
      } else {
        // If value within the array is not an integer
        return true;
      }
    }

    // An os version must have at least a major and minor version number
    if (formattedVersion.length >= 2) {
      // Check if os version is supported
      if (
        formattedVersion[0] > 10 ||
        (formattedVersion[0] === 10 && formattedVersion[1] >= 11)
      ) {
        return true;
      }

      // If the os version is not supported
      return false;
    }

    // If the os version doesn't have a major and/or minor version number
    return true;
  }

  // If we could not find the os version within the user agent
  return true;
}

export function isUhdMacOS(userAgent: string): boolean {
  // We will match anything between 'Mac Os X ' and ')' to find
  // the version number of the os
  const matchedResult = userAgent.match(/Mac OS X (.*?)\)/);

  // The matched result must return an array.
  // The second index of the array will contain the captured group.
  if (matchedResult?.length === 2) {
    // The version is always expressed with underscores. Eg: 10_12_10
    const [majorVersion, minorVersion] = matchedResult[1].split('_');
    // we verify that the major and minor versions are numbers
    const majorVersionNb = parseInt(majorVersion, 10);
    const minorVersionNb = parseInt(minorVersion, 10);
    if (majorVersion?.match(/^\d+$/) && minorVersion?.match(/^\d+$/)) {
      if (majorVersionNb > 10) {
        return true;
      }
      if (majorVersionNb >= 10 && minorVersionNb >= 12) {
        return true;
      }
    }
  }
  return false;
}
