import { logger } from '@canalplus/oneplayer-utils';

export default class VASTExtension {
  static parseAdInfoElement(extensionTypeElement) {
    return {
      campaignId: extensionTypeElement.getAttribute('customcid'),
    };
  }

  constructor(xmlElement) {
    this.type = null;
    this.campaignId = null;
    this.canSkipAfter = null;
    this.adGenre = null;

    this.parse(xmlElement);
  }

  setType(type) {
    this.type = type;
  }

  setAdGenre(genre) {
    this.adGenre = genre;
  }

  setCampaignId(campaignId) {
    this.campaignId = campaignId;
  }

  setCanSkipAfter(time) {
    this.canSkipAfter = time;
  }

  parse(extensionElement) {
    this.setType(extensionElement.getAttribute('type'));

    const extensionTypeElements = extensionElement.childNodes;
    for (let i = 0; i < extensionTypeElements.length; i += 1) {
      const extensionTypeElement = extensionTypeElements[i];
      switch (extensionTypeElement.nodeName) {
        case 'AdInfo': {
          const { campaignId } =
            VASTExtension.parseAdInfoElement(extensionTypeElement);
          if (campaignId) {
            this.setCampaignId(campaignId);
          }
          break;
        }

        case 'CreativeParameters': {
          for (let j = 0; j < extensionTypeElement.children.length; j += 1) {
            const creativeParameter = extensionTypeElement.children[j];
            const paramName = creativeParameter.getAttribute('name');
            if (paramName === 'skip') {
              this.setCanSkipAfter(+creativeParameter.textContent);
            } else if (paramName === 'type') {
              // we call change the naming from type to genre to separate
              // Type : preroll / midroll / postroll from
              // Genre: default / sponsor
              this.setAdGenre(creativeParameter.textContent.trim());
            }
          }
          break;
        }

        default: {
          const { nodeName } = extensionTypeElement;
          if (nodeName !== '#text') {
            logger.warn(
              `OnePlayer Ads > ${nodeName} is not implemented (ExtensionType)`,
            );
          }
        }
      }
    }
  }
}
