import OnePlayerAds from '../business/advertising';

export const MP4_ADS_MIME_TYPES = ['video/mp4'];
export const JS_ADS_MIME_TYPES = [
  'application/x-javascript',
  'application/javascript',
  'text/javascript',
];

export const instancesRegister = {};
let uid = 0;

export function createInstance(instance) {
  uid += 1;
  instancesRegister[uid] = { instance };

  return uid;
}

export function removeInstance(id) {
  delete instancesRegister[id];
}

export function getInstance(id) {
  if (!instancesRegister[id]) {
    return null;
  }

  return instancesRegister[id].instance;
}

export function getInstanceVideoElement(instanceId) {
  const instance = getInstance(instanceId);
  return instance?.videoElement;
}

export function removeAdsManager(id) {
  if (instancesRegister[id] && instancesRegister[id].adsManager) {
    instancesRegister[id].adsManager = undefined;
  }
}

export function getAdsManager(id, mp4AdsSupport, jsAdsSupport) {
  if (!instancesRegister[id]) {
    return null;
  }

  if (!instancesRegister[id].adsManager) {
    let mimeTypesSupported = [];

    if (mp4AdsSupport) {
      mimeTypesSupported = mimeTypesSupported.concat(MP4_ADS_MIME_TYPES);
    }
    if (jsAdsSupport) {
      mimeTypesSupported = mimeTypesSupported.concat(JS_ADS_MIME_TYPES);
    }

    instancesRegister[id].adsManager = new OnePlayerAds.Manager(
      mimeTypesSupported,
    );
  }

  return instancesRegister[id].adsManager;
}

export function getAdsTracker(id) {
  if (!instancesRegister[id]) {
    return null;
  }

  if (!instancesRegister[id].adsTracker) {
    instancesRegister[id].adsTracker = new OnePlayerAds.Tracker();
  }

  return instancesRegister[id].adsTracker;
}

export function removeAdsTracker(id) {
  if (instancesRegister[id] && instancesRegister[id].adsTracker) {
    instancesRegister[id].adsTracker = undefined;
  }
}

export function resetInstanceSettings(id) {
  removeAdsManager(id);
  removeAdsTracker(id);
}
