import { createSelector } from 'reselect';
import { castMetadataSelector, isCastActiveSelector } from './castSelector';
import { isAntiAdSkipEnabledSelector } from './configSelector';
import { isLiveSelector, isPremiumSelector, vodChannelLogoUrlSelector, } from './dataSelector';
export var channelSelector = function (state) {
    return state.channel;
};
/**
 * @returns array of all channels
 */
export var allChannelsSelector = createSelector(channelSelector, function (channel) { return channel.allChannels || []; });
/**
 * @returns the channels index
 */
export var channelsIndexSelector = createSelector(channelSelector, function (channel) { return channel.channelsIndex; });
export var currentChannelSelector = createSelector(channelSelector, function (channel) { return channel.currentChannel; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentChannelAuthorizedSelector = createSelector(currentChannelSelector, function (currentChannel) { return !!(currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isChannelAuthorized); });
/**
 * @returns {function(object): string}
 */
export var currentChannelEpgIdSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.epgId; });
/**
 * @returns {function(object): string}
 */
export var currentChannelNameSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.name; });
/**
 * @returns {function(object): string}
 */
export var currentChannelLogoUrlSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.logoUrlLight; });
/**
 * @returns {function(object): bool}
 */
export var isCurrentChannelCastableSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isCastable; });
/**
 * @returns {function(object): bool}
 */
export var isCurrentChannelStartOverAuthorizedSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isStartOverAuthorized; });
/**
 * @returns {function(object): bool}
 */
export var getGroupTypeCurrentChannelSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.groupType; });
/**
 * @returns {function(object): object}
 */
export var currentChannelManifestSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.manifest; });
/**
 * @returns {function(object): object}
 */
export var currentChannelMulticastSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.multicast; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentChannelAdAntiSkipSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isAdAntiSkip; });
/**
 * @returns duration of no seek for ad at the beginning of the program
 */
export var noSeekDurationForAdAtBeginningSelector = createSelector(currentChannelSelector, function (currentChannel) { return (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.noSeekDurationForAdAtBeginning) || null; });
/**
 * @returns duration of no seek for ad duringg program
 */
export var noSeekDurationForAdAfterBeginningSelector = createSelector(currentChannelSelector, function (currentChannel) { return (currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.noSeekDurationForAdAfterBeginning) || null; });
/**
 *
 * @returns {function(object): boolean}
 */
export var isAntiAdSkipFeatureEnabledOnCurrentChannelSelector = createSelector(isAntiAdSkipEnabledSelector, isCurrentChannelAdAntiSkipSelector, noSeekDurationForAdAtBeginningSelector, noSeekDurationForAdAfterBeginningSelector, function (isAntiAdSkipEnabled, isAdAntiSkip, noSeekDurationForAdAtBeginning, noSeekDurationForAdAfterBeginning) {
    return isAntiAdSkipEnabled &&
        isAdAntiSkip &&
        (noSeekDurationForAdAtBeginning !== null ||
            noSeekDurationForAdAfterBeginning !== null);
});
/**
 * @returns {function(object): ?string}
 */
export var liveChannelLogoUrlSelector = createSelector(currentChannelLogoUrlSelector, isPremiumSelector, isCastActiveSelector, castMetadataSelector, function (currentChannelLogoUrl, isPremium, isCastActive, castMetadata) {
    var _a, _b;
    if (isCastActive) {
        return (_b = (_a = castMetadata.customData) === null || _a === void 0 ? void 0 : _a.channel) === null || _b === void 0 ? void 0 : _b.logoUrl;
    }
    return isPremium ? currentChannelLogoUrl : '';
});
/**
 * @returns {function(object): ?string}
 */
export var channelLogoUrlSelector = createSelector(isLiveSelector, vodChannelLogoUrlSelector, liveChannelLogoUrlSelector, function (isLive, vodChannelLogoUrl, liveChannelLogoUrl) {
    return (isLive ? liveChannelLogoUrl : vodChannelLogoUrl) || '';
});
/**
 * @returns {function(object): bool}
 */
export var isCastableSelector = createSelector(isPremiumSelector, isLiveSelector, isCurrentChannelCastableSelector, function (isPremium, isLive, isCurrentChannelCastable) {
    return !(isPremium && isLive && !isCurrentChannelCastable);
});
/**
 * @returns if the current channel is a an external service
 */
export var isExternalServiceChannelSelector = createSelector(currentChannelSelector, function (currentChannel) { return !!(currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.isExternalServiceChannel); });
/**
 * @returns the number of the current channel
 */
export var channelNumberSelector = createSelector(currentChannelSelector, function (currentChannel) { return currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.number; });
/**
 * @returns the number of the current channel, in the specific requested version
 * if applicable
 * @example If in the PDS CANAL+ can be called with numbers 4 and 9, and the user zaps to 9,
 * this number will be stored and the selector will return 9.
 */
export var channelSpecificNumberSelector = createSelector(currentChannelSelector, function (currentChannel) { var _a; return (_a = currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.specificRequestedNumber) !== null && _a !== void 0 ? _a : currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.number; });
