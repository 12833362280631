import { mediaCapabilitiesProber } from 'rx-player/experimental/tools';

import { BusinessTypes, DrmTypes } from '@canalplus/oneplayer-constants';
import { ISupportedDRM, TPlatform } from '@canalplus/oneplayer-types';

import {
  constructConfig,
  determineSupportedDrm,
  filterUnwantedCencDrmConfigurations,
  isFairplayDrmSupported,
  sortDrmContextList,
} from './utils';

const {
  KEY_SYSTEMS: { FAIRPLAY, PLAYREADY_RECOMMENDATION_ESVM },
  DRM_TYPES,
} = DrmTypes;

/**
 * Get the drm to use for the current browser
 * @param deviceType device we are on
 * @param CENC_KEY_SYSTEMS_PRIORITY DRM array of priority
 * @param platform platform of the content
 * @param shouldPersistLicense whether or not we should persist license
 * @returns array of drm supported by the browser ordred by priority
 */
export async function getDrmContext(
  deviceType: BusinessTypes.DEVICE_TYPES,
  CENC_KEY_SYSTEMS_PRIORITY?: string[] | undefined,
  platform?: TPlatform,
  shouldPersistLicense?: boolean,
): Promise<ISupportedDRM[] | null> {
  if (isFairplayDrmSupported(deviceType)) {
    return [
      {
        keySystem: FAIRPLAY,
        name: DRM_TYPES.FAIRPLAY,
        robustnesses: ['HW_SECURE_ALL'],
        canPersistLicense: false,
      },
    ];
  }

  try {
    mediaCapabilitiesProber.LogLevel = 'NONE';
    const drmConfigs =
      await mediaCapabilitiesProber.getCompatibleDRMConfigurations(
        constructConfig(deviceType, shouldPersistLicense),
      );
    const supportedCencDrms = determineSupportedDrm(
      drmConfigs.filter((config) => !!config),
    );
    if (supportedCencDrms.length === 0) {
      return null;
    }

    const keySystemConfig = sortDrmContextList({
      drmContextList: filterUnwantedCencDrmConfigurations(supportedCencDrms),
      deviceType,
      CENC_KEY_SYSTEMS_PRIORITY,
      platform,
    });
    /**
     * Infortunately, that ugly and hacky condition is mandatory in order to play content on the PS5
     * That browser method on the MediaSource is handled by the low level players as the rx-player.
     * But we decided, that it's too specific to integrate it inside the rx-player.
     * So, for now, we must do that here.
     */
    if (keySystemConfig[0].keySystem === PLAYREADY_RECOMMENDATION_ESVM) {
      const oldAddSourceBuffer = MediaSource.prototype.addSourceBuffer;
      MediaSource.prototype.addSourceBuffer = function (type): SourceBuffer {
        return oldAddSourceBuffer.call(
          this,
          type.includes('esvm=true')
            ? type
            : `${type};esvm=true;pass_through=true`,
        );
      };
    }
    return keySystemConfig;
  } catch {
    return null;
  }
}
