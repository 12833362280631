import { logger } from '@canalplus/oneplayer-utils';
import { VASTCreativeLinear } from './VASTCreative';
import * as Utils from './utils';

export default class Tracker {
  constructor() {
    this.isLinear = false;
    this.vmapTrackingEvents = {};
    this.trackingStreamEvents = {};

    // Global state
    this.muted = false;
    this.skipable = false;
  }

  setAd(ad) {
    this.ad = ad;
  }

  // add a new event in the list of tracking events or directly trigger event
  // if the event doesn't need a user interaction.
  createNewStreamEvent(streamEvent) {
    // events that don't need user interaction to send tracking
    if (
      [
        'breakStart',
        'breakEnd',
        'start',
        'firstQuartile',
        'midpoint',
        'thirdQuartile',
        'complete',
        'impression',
        'creativeView',
      ].includes(streamEvent.eventType)
    ) {
      Utils.track([streamEvent.eventUrl]);
    } else {
      // events that need a user interaction to send tracking
      if (this.trackingStreamEvents[streamEvent.eventType] === undefined) {
        this.trackingStreamEvents[streamEvent.eventType] = [];
      }
      this.trackingStreamEvents[streamEvent.eventType].push({
        id: streamEvent.id,
        url: streamEvent.eventUrl,
      });
    }
  }

  // Send tracking when a specific event we want to track is triggered.
  triggerStreamEvent(eventType) {
    if (Array.isArray(this.trackingStreamEvents[eventType])) {
      const streamEventsUrls = this.trackingStreamEvents[eventType].map(
        (streamEvent) => streamEvent.url,
      );
      Utils.track(streamEventsUrls);
    }
  }

  // Update the list of tracking events by removing the one that expired
  updateStreamEvents(id) {
    Object.keys(this.trackingStreamEvents).forEach((eventType) => {
      this.trackingStreamEvents[eventType] = this.trackingStreamEvents[
        eventType
      ].reduce((acc, event) => {
        if (event.id !== id) {
          acc.push(event);
        }
        return acc;
      }, []);
    });
  }

  setVmapTrackingEvents(trackingEvents, adType) {
    this.vmapTrackingEvents[adType] = trackingEvents;
  }

  setCreative(creative) {
    this.creative = creative;

    if (this.creative instanceof VASTCreativeLinear) {
      this.isLinear = true;
      this.defineQuartiles(this.creative.duration);
    }
  }

  defineQuartiles(duration) {
    // The complete event can be sent when reaching duration - 2s
    // This is to make sure the complete event is sent, espacially when using
    // Vpaids that give us unprecise video durations
    this.quartiles = {
      start: 0,
      firstQuartile: Math.round(25 * duration) / 100,
      midpoint: Math.round(50 * duration) / 100,
      thirdQuartile: Math.round(75 * duration) / 100,
      complete: duration - 2, // make sure complete is sent, especially when using vpaid
    };
  }

  setProgress(progress) {
    if (this.isLinear && this.creative.duration > 0) {
      if (progress >= 0) {
        Object.keys(this.quartiles).forEach((quartile) => {
          if (quartile) {
            const time = this.quartiles[quartile];
            if (progress >= time) {
              this.track(quartile);
              delete this.quartiles[quartile];
            }
          }
        });
      }
    }

    this.progress = progress;
  }

  setMuted(muted) {
    if (this.muted !== muted) {
      this.track(muted ? 'mute' : 'unmute');
      this.triggerStreamEvent(muted ? 'mute' : 'unmute');
    }

    this.muted = muted;
  }

  setPaused(paused) {
    if (this.paused !== paused) {
      this.track(paused ? 'pause' : 'resume');
    }

    this.paused = paused;
    this.triggerStreamEvent(paused ? 'pause' : 'resume');
  }

  setFullscreen(fullscreen) {
    if (this.fullscreen !== fullscreen) {
      this.track(fullscreen ? 'fullscreen' : 'exitFullscreen');
    }

    this.fullscreen = fullscreen;
    if (fullscreen) {
      this.triggerStreamEvent('fullscreen');
    }
  }

  setTrackerByBreakType(adType, breakType) {
    if (this.vmapTrackingEvents[adType]) {
      Utils.track(
        this.vmapTrackingEvents[adType].reduce((acc, event) => {
          if (event.breakType === breakType) {
            acc.push(event.url);
          }
          return acc;
        }, []),
      );
      logger.log(`OnePlayer Ads Tracker > vmap ${breakType}`);
    }
  }

  load() {
    const urls = this.ad.impressionURLTemplates;

    if (urls.length > 0) {
      this.trackURLs(urls);
      logger.log(`OnePlayer Ads Tracker > track load at ${urls}`);
    }
  }

  click() {
    const urls = this.creative.videoClickTrackingURLTemplates;

    if (urls.length > 0) {
      this.trackURLs(urls);
      this.triggerStreamEvent('clickThrough');
      logger.log(`OnePlayer Ads Tracker > track click at ${urls}`);
    }
  }

  track(eventName) {
    let trackingURLTemplates = [];
    if (this.creative) {
      trackingURLTemplates = this.creative.trackingEvents
        .filter((event) => event.eventName === eventName)
        .map((event) => event.url);
    }

    if (trackingURLTemplates.length > 0) {
      this.trackURLs(trackingURLTemplates);
      logger.log(
        `OnePlayer Ads Tracker > track ${eventName} at`,
        trackingURLTemplates,
      );
    }
  }

  trackURLs(urls, variables = {}) {
    const v = variables;
    if (this.isLinear) {
      v.CONTENTPLAYHEAD = this.progressFormated();
    }

    Utils.track(urls, v);
  }

  progressFormated() {
    const seconds = parseInt(this.progress, 10);

    let h = seconds / (60 * 60);
    if (h.length < 2) {
      h = `0${h}`;
    }

    let m = (seconds / 60) % 60;
    if (m.length < 2) {
      m = `0${m}`;
    }

    let s = seconds % 60;
    if (s.length < 2) {
      s = `0${m}`;
    }

    const ms = parseInt((this.progress - seconds) * 100, 10);

    return `${h}:${m}:${s}.${ms}`;
  }
}
