import { jsx as _jsx } from "react/jsx-runtime";
import triggerApi from '../../../utils/api';
import TvInitError from './TvInitError';
import WebInitError from './WebInitError';
var InitError = function (_a) {
    var uid = _a.uid, i18nKey = _a.i18nKey, exitButton = _a.exitButton, isTvPlayer = _a.isTvPlayer;
    var exitButtonClicked = function (event) {
        return triggerApi(uid, 'onExitButtonClicked', {
            event: event === null || event === void 0 ? void 0 : event.nativeEvent,
        });
    };
    return isTvPlayer ? (_jsx(TvInitError, { triggerExit: exitButtonClicked, i18nKey: i18nKey })) : (_jsx(WebInitError, { exitButtonClicked: exitButtonClicked, i18nKey: i18nKey, exitButton: exitButton }));
};
export default InitError;
