var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import getVideoWrapperDimensions from '../../../../utils/videoWrapperDimensions';
var styles = require('./VideoWrapper.css');
var VideoWrapper = function (_a) {
    var _b;
    var isMobilePortraitLayout = _a.isMobilePortraitLayout, mobilePortraitVideoHeight = _a.mobilePortraitVideoHeight, mobilePortraitVideoTop = _a.mobilePortraitVideoTop, frameHeight = _a.frameHeight, frameWidth = _a.frameWidth, currentBreakpoint = _a.currentBreakpoint, isSidepanelOpen = _a.isSidepanelOpen, isTvPlayer = _a.isTvPlayer, isPlaystation = _a.isPlaystation, tvPlayerPanel = _a.tvPlayerPanel, uid = _a.uid, _c = _a.children, children = _c === void 0 ? null : _c;
    var containerWidth = 0;
    var containerHeight = 0;
    if (!frameWidth || !frameHeight) {
        var containerBoundingClientRect = (_b = document
            .querySelector("#oneplayer-container-".concat(uid))) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        containerWidth = (containerBoundingClientRect === null || containerBoundingClientRect === void 0 ? void 0 : containerBoundingClientRect.width) || containerWidth;
        containerHeight = (containerBoundingClientRect === null || containerBoundingClientRect === void 0 ? void 0 : containerBoundingClientRect.height) || containerHeight;
    }
    // We first try to get the frame values coming from the store, if they are 0:0, we select
    // values coming from the container and if nothing works, we select the window dimensions
    // as a last option solution.
    var videoFrameWidth = frameWidth || containerWidth || window.innerWidth;
    var videoFrameHeight = frameHeight || containerHeight || window.innerHeight;
    var videoWrapperDimensions = getVideoWrapperDimensions({
        videoFrameWidth: videoFrameWidth,
        videoFrameHeight: videoFrameHeight,
        currentBreakpoint: currentBreakpoint,
        isSidepanelOpen: isSidepanelOpen,
        isMobilePortraitLayout: isMobilePortraitLayout,
        mobilePortraitVideoHeight: mobilePortraitVideoHeight,
        mobilePortraitVideoTop: mobilePortraitVideoTop,
        isTvPlayer: isTvPlayer,
        isPlaystation: isPlaystation,
        tvPlayerPanel: tvPlayerPanel,
    });
    var style = __assign(__assign({}, videoWrapperDimensions.position), videoWrapperDimensions.transform);
    return (_jsx("div", { className: styles.VideoWrapper, style: style, "data-testid": "videoWrapper", children: children }));
};
export default VideoWrapper;
