import { BusinessTypes, ErrorCodes } from '@canalplus/oneplayer-constants';
import {
  removeDiacritics,
  toMilliseconds,
  sortPrograms,
  isLiveProgramChannel,
} from '@canalplus/oneplayer-utils';

import { showErrorAction } from '../actions/ErrorActions';
import { stopAction } from '../actions/CoreActions';

const {
  ENCRYPTED,
  PARENTAL_CODE_REQUIRED,
  UNAUTHORIZED_PARENTAL_RATING,
  PARENTAL_RATING_RESTRICTED_HOURS,
  UNAUTHORIZED_LIVE,
  UNAUTHORIZED_STARTOVER,
  UNAUTHORIZED_CHANNEL,
} = ErrorCodes;
const { DEFAULT_LATENCY, ZAPLIST_GROUPTYPE_TO_DISPLAY, timeCodeTypes } =
  BusinessTypes;

const IS_LIVE_THRESHOLD = 10;
const IS_LIVE_THRESHOLD_LL = 5;

/**
 * [Performant programs checking]
 * Return the current program in list of programs in function of date
 * => Use this method when you are sure to have all the daily programs
 */
export function findCurrentProgram(date, programs) {
  if (typeof date?.getTime !== 'function' || !date.getTime() || !programs) {
    return null;
  }

  const time = date.getTime();
  for (let i = programs.length - 1; i >= 0; i -= 1) {
    const program = programs[i];
    // First look by date
    // Check that time is between program.startTime.getTime() and program.endTime.getTime() with a margin of 1000 ms
    if (
      program.startTime.getTime() <= time + 1000 &&
      time - 1000 <= program.endTime.getTime()
    ) {
      return program;
    }
    // Else look by hour
    const todayNow = new Date();
    const requestHours = date.getHours();
    const requestMinutes = date.getMinutes();
    const todayAtRequestHoursMinutes = new Date(
      new Date(new Date().setHours(requestHours)).setMinutes(requestMinutes),
    );
    const yesterdayAtRequestHoursMinutes = new Date(
      new Date(
        new Date(Date.now() - toMilliseconds(24 * 3600)).setHours(requestHours),
      ).setMinutes(requestMinutes),
    );
    // We just shifted the requested hours and minutes to today and yesterday
    // If the time now is superior to today at requested hours and minutes, we keep it as virtualTime
    // If not it means that the hours and minutes (that can't be in the futur), are likely in the past, so we use yesterday at requested hours and minutes as virtualTime
    // We do that because we might get a date with the wrong day
    // Why? Because the stream date and the program date might be totally different, but their hours/minutes does match!
    // A wrong day should not be an issue for finding a program
    // Because the available programs to watch does not span beyond eight hours (no risk to mismatch the days)
    // So if a date is at the wrong day, we create a new date the requested hours and minutes, hoping it will match a program today
    const virtualTime =
      todayNow >= todayAtRequestHoursMinutes
        ? todayAtRequestHoursMinutes.getTime()
        : yesterdayAtRequestHoursMinutes.getTime();
    // Check that virtualTime is between program.startTime.getTime() and program.endTime.getTime() with a margin of 1000 ms
    if (
      program.startTime.getTime() <= virtualTime + 1000 &&
      virtualTime - 1000 <= program.endTime.getTime()
    ) {
      return program;
    }
  }

  // no program found
  return null;
}

/**
 * [Less performant programs checking (but more accurate)]
 * Check start and end time for all programs
 * => Use this method when you have incomplete program lists
 */
export function findCurrentProgramFullMatch(date, programs) {
  const time = typeof date?.getTime === 'function' && date.getTime();

  for (let i = 0; i < programs.length; i += 1) {
    const program = programs[i];
    if (
      time >= program.startTime.getTime() &&
      time < program.endTime.getTime()
    ) {
      return program;
    }
  }

  return null;
}

export function isLiveEdge({
  liveGap,
  latency,
  threshold: thresholdParam = null,
  isLowLatencyEnabled = false,
}) {
  let threshold;
  if (thresholdParam === null || typeof thresholdParam !== 'number') {
    threshold = isLowLatencyEnabled ? IS_LIVE_THRESHOLD_LL : IS_LIVE_THRESHOLD;
  } else {
    threshold = thresholdParam;
  }
  return liveGap + (latency || DEFAULT_LATENCY) <= threshold;
}

/**
 * Check if the startover is authorized for a given program
 */
export function isStartOverForbidden(program, liveTime) {
  const isLiveProgram = isLiveProgramChannel(program, liveTime);
  return !program.isStartOverAuthorized && !isLiveProgram;
}

/**
 * Check if the startover on current (live program) is authorized for a given program
 */
export function isStartOverOnCurrentForbidden(program, liveTime) {
  const isLiveProgram = isLiveProgramChannel(program, liveTime);
  return !program.isStartOverOnCurrentAuthorized && isLiveProgram;
}

/**
 * Check if the startover is authorized for given channel & program
 */
export function canStartOver(channel, program, liveTime) {
  return (
    program !== null &&
    !isStartOverForbidden(program, liveTime) &&
    !isStartOverOnCurrentForbidden(program, liveTime) &&
    channel?.isStartOverAuthorized
  );
}

/**
 * Check if the date is between restriction level authorized hours
 *
 * parentalRating if not specified in the config, will be between midnight to 5am
 */
export function isParentalRatingHours(
  parentalRatingHours = { start: 0, end: 5 },
  offset = 0,
) {
  const dateNowWithOffset = Date.now() + offset;
  const now = new Date(dateNowWithOffset);
  const hoursFrom = new Date(dateNowWithOffset);
  const hoursTo = new Date(dateNowWithOffset);

  hoursFrom.setHours(parentalRatingHours.start, 0, 0);
  hoursTo.setHours(parentalRatingHours.end, 0, 0);

  // If we are on two days
  if (
    parentalRatingHours.end < parentalRatingHours.start &&
    now.getHours() >= 0 &&
    now.getHours() < parentalRatingHours.end
  ) {
    hoursFrom.setDate(hoursFrom.getDate() - 1);
    hoursTo.setDate(hoursFrom.getDate() + 1);
  }
  return now >= hoursFrom && now <= hoursTo;
}

/**
 * Check program and channel rights and throw an error code
 */
export function checkRights(
  program,
  currentChannel,
  liveGap,
  liveTime,
  serverLiveTimeOffset,
  parentalRatingHours,
  unlockedBroadcastId,
  unlocked = false,
  isParentalCodeActivatedForUserOverloadableContents,
  doesParentalCodeExists,
) {
  if (!program) {
    return;
  }

  // we use a bigger threshold here to allow 5 minutes of DVR
  // For unauthorized start over on a program.
  // This allows people to pause the content or to have a little buffering
  const threshold = 5 * 60;
  const isLive = isLiveEdge({ liveGap, threshold });

  if (!currentChannel.isChannelAuthorized) {
    const error = {
      code: UNAUTHORIZED_CHANNEL,
      fatal: false,
      customUI: true,
    };
    throw error;
  }

  if (isLive === true && !program.isLiveAuthorized) {
    const error = { code: UNAUTHORIZED_LIVE, program };
    throw error;
  }

  if (currentChannel.isFullCat5) {
    if (!currentChannel.isCat5Authorized) {
      const error = { code: UNAUTHORIZED_PARENTAL_RATING, program };
      throw error;
    } else if (
      !isParentalRatingHours(parentalRatingHours, serverLiveTimeOffset)
    ) {
      const error = { code: PARENTAL_RATING_RESTRICTED_HOURS, program };
      throw error;
    } else if (!unlocked) {
      const error = { code: PARENTAL_CODE_REQUIRED, customUI: true, program };
      throw error;
    }
  }

  // if we receive isUserOverloadable, it means the user could have overloadeded
  //  the standard restrictions. In this case, we need to ask
  // ACM if the content we try to play is protected or not
  if (program.parentalCodeLock.isUserOverloadable) {
    // check if the user wants to protect the content or if he hasn't set
    // a code yet, in that case we want to display the create parental code form
    if (
      isParentalCodeActivatedForUserOverloadableContents ||
      !doesParentalCodeExists
    ) {
      // check morality to determine if we have to block it with ParentalRatingHours error
      if (
        program?.morality === 'PORN' &&
        !isParentalRatingHours(parentalRatingHours, serverLiveTimeOffset)
      ) {
        const error = { code: PARENTAL_RATING_RESTRICTED_HOURS, program };
        throw error;
      } else if (unlockedBroadcastId !== program.broadcastId) {
        const error = {
          code: PARENTAL_CODE_REQUIRED,
          customUI: true,
          program,
        };
        throw error;
      }
    }
    // if it can't be overloaded, we want to simply check if the content is protected or not (by default)
    // if we have playContent (renamed in our codebase by isLockedByDefault)
    // it means the content is automatically protected.
  } else if (program.parentalCodeLock.isLockedByDefault) {
    // check morality to determine if we have to block it with ParentalRatingHours error
    if (
      program?.morality === 'PORN' &&
      !isParentalRatingHours(parentalRatingHours, serverLiveTimeOffset)
    ) {
      const error = { code: PARENTAL_RATING_RESTRICTED_HOURS, program };
      throw error;
    } else if (unlockedBroadcastId !== program.broadcastId) {
      const error = {
        code: PARENTAL_CODE_REQUIRED,
        customUI: true,
        program,
      };
      throw error;
    }
  }

  if (isLive === false) {
    const canSO = canStartOver(currentChannel, program, liveTime);

    if (!canSO) {
      const error = { code: UNAUTHORIZED_STARTOVER, program };
      throw error;
    }
  }

  if (program.isEncrypted && currentChannel.hasEncryptedPrograms) {
    const error = { code: ENCRYPTED, customUI: true, program };
    throw error;
  }
}

/**
 * Some program should not be visible sometimes (occultations).
 */
export function displayProgramRightsError(
  dispatch,
  errorCode,
  program,
  disableStream = true,
) {
  const customUI =
    errorCode === PARENTAL_CODE_REQUIRED ||
    errorCode === PARENTAL_RATING_RESTRICTED_HOURS ||
    errorCode === UNAUTHORIZED_LIVE ||
    errorCode === ENCRYPTED ||
    errorCode === UNAUTHORIZED_CHANNEL;

  const errorOptions = {
    fatal: false,
    customUI,
    variables: {
      programTitle: program.title,
    },
  };

  dispatch(showErrorAction(errorCode, 'live', errorOptions));
  if (disableStream) {
    dispatch(stopAction());
  }
}

/**
 * With a programs list, find the current and the next one
 */
export function findNowAndNextPrograms(
  programs,
  liveTime,
  pollInterval,
  itemsCount = 3,
) {
  const sortedPrograms = sortPrograms(programs);
  const nowAndNext = [];
  for (let i = 0, len = sortedPrograms.length; i < len; i += 1) {
    const program = sortedPrograms[i];

    if (!nowAndNext.length && isLiveProgramChannel(program, liveTime)) {
      nowAndNext.push(program);
    } else if (nowAndNext.length > 0 && nowAndNext.length < itemsCount) {
      nowAndNext.push(program);
    } else if (
      nowAndNext.length >= itemsCount &&
      program.endTime.getTime() < liveTime.getTime() + pollInterval
    ) {
      /* Here we already have the itemsCount number of programs
        But the last program ends before the time of the next poll
        So we need to fill the gap to avoid having no program data
      */
      nowAndNext.push(program);
    }
  }
  return nowAndNext;
}

/**
 * Filter programs by genre
 */
export function filterProgramsByGenre(programs, currentGenre) {
  // By default (genre 0), show all the programs
  if (currentGenre === 0) {
    return programs;
  }

  const filteredPrograms = [];
  programs.map((program) => {
    if (program.nowNextPrograms?.[0]?.genre === currentGenre) {
      filteredPrograms.push(program);
    }

    return program;
  });

  return filteredPrograms;
}

export function filterCurrentLivePrograms(programs, liveTime) {
  // We display only the available channels
  const filteredPrograms = programs.filter(
    (p) =>
      !p.channelParent ||
      (p.channelParent &&
        p.channelParent.isChannelAuthorized &&
        // Only in the authorized grouptype
        p.channelParent.groupType === ZAPLIST_GROUPTYPE_TO_DISPLAY),
  );

  // Keep only current programs
  const programList = [];
  filteredPrograms.forEach(({ channelParent, nowNextPrograms }) => {
    const currentProgram = nowNextPrograms.find((prog) =>
      isLiveProgramChannel(prog, liveTime),
    );

    if (currentProgram) {
      programList.push({
        ...currentProgram,
        channelParent,
      });
    }
  });

  return programList;
}

/**
 * Filter tv programs by genre
 */
export function filterTvProgramsByGenre({
  allCurrentPrograms,
  liveTime,
  zaplistMode,
}) {
  const customPrograms = [];

  allCurrentPrograms?.forEach(({ channelParent, nowNextPrograms }) => {
    // Find current program
    const currentProgram = nowNextPrograms.find((prog) =>
      isLiveProgramChannel(prog, liveTime),
    );

    if (
      currentProgram &&
      // By default (genre 0), show all the programs
      (zaplistMode === 0 || currentProgram.genre === zaplistMode) &&
      channelParent.isChannelAuthorized &&
      // Only in the authorized grouptype
      channelParent.groupType === ZAPLIST_GROUPTYPE_TO_DISPLAY
    ) {
      customPrograms.push({
        program: currentProgram,
        channel: channelParent,
      });
    }
  });

  return customPrograms;
}

/**
 * Filter programs by query
 */
export function filterProgramsByQuery(programs, query) {
  // As long as query is empty, show all the programs
  if (!query) {
    return programs;
  }

  const filteredPrograms = [];
  programs.map((program) => {
    const rgx = new RegExp(query, 'i');

    if (rgx.test(program.channelParent.normalizedName)) {
      // No need to check Program name if the query
      // matches the channel name
      filteredPrograms.push(program);
    } else if (rgx.test(removeDiacritics(program.nowNextPrograms[0]?.title))) {
      filteredPrograms.push(program);
    }

    return program;
  });

  return filteredPrograms;
}

/**
 * Get Live Timebreaks (adSpans, ...) from original timecodes array.
 */
export function getLiveTimeBreaks(timeCodes, duration) {
  return timeCodes.reduce((originalBreaks, timeCode, currentIndex) => {
    let breaksLength = originalBreaks.length;
    const breaks = [...originalBreaks];
    const { startTime, endTime, type } = timeCode;

    if (type === timeCodeTypes.ads) {
      if (breaksLength === 0) {
        breaks.push({ start: startTime.getTime() });
      }
    } else {
      if (breaksLength > 0) {
        breaks[breaksLength - 1].end = startTime.getTime();
      }
      breaksLength = breaks.push({ start: endTime.getTime() });
    }

    if (currentIndex === timeCodes.length - 1) {
      breaks[breaksLength - 1].end =
        timeCodes[0].startTime.getTime() + duration;
    }

    return breaks;
  }, []);
}

/**
 * Get VOD Timebreaks (for midroll separators) from original midroll start times array.
 */
export function getVodTimeBreaks(midrollStartTimes) {
  return midrollStartTimes.map((st) =>
    // We virtually declare the end of the ad at startTime
    ({ start: st, end: st }),
  );
}

/**
 * Get Timebreaks for VOD and Live contents
 */
export function getTimeBreaks(timeSplitters, duration, isLive) {
  return isLive
    ? getLiveTimeBreaks(timeSplitters, duration)
    : getVodTimeBreaks(timeSplitters);
}

/**
 * We don't know the minimPosition if we don't do a loadVideo.
 * So we decided if startOver is possible to have a defaultMinimumDate 8hours ago when we don't know the minimumPosition.
 * TODO: In the future the RxPlayer will have an API to access some information before loadVideo like the minimumPosition.
 */
export function getDefaultMiniumPosition(isCurrentChannelStartOverAuthorized) {
  if (isCurrentChannelStartOverAuthorized) {
    const now = new Date(Date.now());
    now.setHours(now.getHours() - 8);
    return now;
  }
  return null;
}

export function getPreviousChannelEpgId({
  programs,
  channelNumber,
  channelKeyName = 'channel',
}) {
  if (!programs || programs?.length === 0) {
    return null;
  }
  const closestProgram = programs.reduce((a, b) => {
    if (b[channelKeyName].number > channelNumber) {
      return a;
    }
    return channelNumber - b[channelKeyName].number <
      channelNumber - a[channelKeyName].number
      ? b
      : a;
  });

  return closestProgram[channelKeyName].epgId;
}
