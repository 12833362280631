var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { variantSelector } from '@oneplayer/selectors';
var VARIANTS = BusinessTypes.VARIANTS;
/**
 * HOC wrapping a Component with the variant property
 * @param WrappedComponent The wrapped component
 */
export default function withVariant(
// this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
WrappedComponent) {
    return function (wrappedProps) {
        // TODO: Maybe find a better way to set a defaultValue to the props
        var variant = useSelector(variantSelector) || VARIANTS.MYCANAL;
        // the typing for spreading this.props is... very complex. best way right now is to just type it as any
        // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
        return _jsx(WrappedComponent, __assign({ variant: variant }, wrappedProps));
    };
}
