var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var createConsumer = function (Consumer) {
    return function withConsumer(
    // this type allows us to infer P, but grab the type of WrappedComponent separately without it interfering with the inference of P
    WrappedComponent) {
        return function (props) {
            return (_jsx(Consumer, { children: function (_a) {
                    var t = _a.t, u = _a.u, locale = _a.locale;
                    return (
                    // the typing for spreading props is... very complex. best way right now is to just type it as any
                    // data will still be typechecked
                    // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
                    _jsx(WrappedComponent, __assign({}, props, { t: t, u: u, locale: locale })));
                } }));
        };
    };
};
export default createConsumer;
