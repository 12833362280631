import airplayOnGlyph from '../assets/icons/airplay-on.svg';
import airplayOffGlyph from '../assets/icons/airplay-off.svg';
import allocineGlyph from '../assets/icons/allocine.svg';
import backspaceGlyph from '../assets/icons/backspace.svg';
import crossGlyph from '../assets/icons/cross.svg';
import episodesGlyph from '../assets/icons/episodes.svg';
import exitPlayerGlyph from '../assets/icons/exit-player.svg';
import expertModeGlyph from '../assets/icons/expertMode/expertMode.svg';
import fullscreenGlyph from '../assets/icons/fullscreen.svg';
import fullscreenExitGlyph from '../assets/icons/fullscreenExit.svg';
import iInformationGlyph from '../assets/icons/i-information.svg';
import infoGlyph from '../assets/icons/infos.svg';
import languageGlyph from '../assets/icons/language.svg';
import magnifyingGlassGlyph from '../assets/icons/magnifying-glass.svg';
import krritAdvisoryNGlyph from '../assets/icons/krrit-advisory-n.svg';
import krritAdvisoryPGlyph from '../assets/icons/krrit-advisory-p.svg';
import krritAdvisorySGlyph from '../assets/icons/krrit-advisory-s.svg';
import krritAdvisoryWGlyph from '../assets/icons/krrit-advisory-w.svg';
import muteGlyph from '../assets/icons/mute.svg';
import mycanalGlyph from '../assets/icons/mycanal.svg';
import castSplashscreenGlyph from '../assets/icons/castSplashscreen.svg';
import nextGlyph from '../assets/icons/nextepisode.svg';
import pauseGlyph from '../assets/icons/pause.svg';
import pipGlyph from '../assets/icons/pip.svg';
import pipActiveGlyph from '../assets/icons/pip-active.svg';
import playGlyph from '../assets/icons/play.svg';
import playbackRateX025 from '../assets/icons/playback-rate-x025.svg';
import playbackRateX050 from '../assets/icons/playback-rate-x050.svg';
import playbackRateX075 from '../assets/icons/playback-rate-x075.svg';
import playbackRateX100 from '../assets/icons/playback-rate-x100.svg';
import playbackRateX125 from '../assets/icons/playback-rate-x125.svg';
import playbackRateX150 from '../assets/icons/playback-rate-x150.svg';
import playbackRateX175 from '../assets/icons/playback-rate-x175.svg';
import playbackRateX200 from '../assets/icons/playback-rate-x200.svg';
import replayTimelineGlyph from '../assets/icons/replaytimeline.svg';
import replay from '../assets/icons/replay.svg';
import leftArrowGlyph from '../assets/icons/left-arrow.svg';
import parentalRating10Glyph from '../assets/icons/parental-rating-10.svg';
import parentalRating12Glyph from '../assets/icons/parental-rating-12.svg';
import parentalRating16Glyph from '../assets/icons/parental-rating-16.svg';
import parentalRating18Glyph from '../assets/icons/parental-rating-18.svg';
import parentalRatingPolish0Glyph from '../assets/icons/parental-rating-polish-0.svg';
import parentalRatingPolish7Glyph from '../assets/icons/parental-rating-polish-7.svg';
import parentalRatingPolish12Glyph from '../assets/icons/parental-rating-polish-12.svg';
import parentalRatingPolish16Glyph from '../assets/icons/parental-rating-polish-16.svg';
import parentalRatingPolish18Glyph from '../assets/icons/parental-rating-polish-18.svg';
import parentalRatingItalianTGlyph from '../assets/icons/parental-rating-italian-T.svg';
import parentalRatingItalian6Glyph from '../assets/icons/parental-rating-italian-6.svg';
import parentalRatingItalian12Glyph from '../assets/icons/parental-rating-italian-12.svg';
import parentalRatingItalian15Glyph from '../assets/icons/parental-rating-italian-15.svg';
import parentalRatingItalian18Glyph from '../assets/icons/parental-rating-italian-18.svg';
import playstationCircleGlyph from '../assets/icons/playstation-circle.svg';
import playstationCrossGlyph from '../assets/icons/playstation-cross.svg';
import playstationSquareGlyph from '../assets/icons/playstation-square.svg';
import playstationTriangleGlyph from '../assets/icons/playstation-triangle.svg';
import playstationL1Glyph from '../assets/icons/playstation-l1.svg';
import playstationL2Glyph from '../assets/icons/playstation-l2.svg';
import playstationR1Glyph from '../assets/icons/playstation-r1.svg';
import playstationR2Glyph from '../assets/icons/playstation-r2.svg';
import quality4KHDRGlyph from '../assets/icons/quality4KHDR.svg';
import quality4KUHDGlyph from '../assets/icons/quality4KUHD.svg';
import quality4KDBVGlyph from '../assets/icons/qualityDolbyVision.svg';
import qualityDolbyAtmosGlyph from '../assets/icons/qualityDolbyAtmos.svg';
import qualityDolby51Glyph from '../assets/icons/qualityDolby51.svg';
import seekMinusGlyph from '../assets/icons/seek-minus.svg';
import seekPlusGlyph from '../assets/icons/seek-plus.svg';
import settingsGlyph from '../assets/icons/settings.svg';
import skipAllGlyph from '../assets/icons/skip-all.svg';
import starGlyph from '../assets/icons/star.svg';
import startOverGlyph from '../assets/icons/startover.svg';
import teleramaGlyph from '../assets/icons/telerama.svg';
import timelineGlyph from '../assets/icons/timeline.svg';
import tvEpisodesGlyph from '../assets/icons/tv-episodes.svg';
import tvExpertModeGlyph from '../assets/icons/tv-expertMode.svg';
import tvInfoGlyph from '../assets/icons/tv-info.svg';
import tvLanguageGlyph from '../assets/icons/tv-language.svg';
import tvNextGlyph from '../assets/icons/tv-next.svg';
import tvSettingsGlyph from '../assets/icons/tv-settings.svg';
import tvReplayTimelineGlyph from '../assets/icons/tv-replaytimeline.svg';
import tvTimelineGlyph from '../assets/icons/tv-timeline.svg';
import validateGlyph from '../assets/icons/validate.svg';
import volumeGlyph from '../assets/icons/volume.svg';
import castConnected from '../assets/icons/cast-connected.svg';
import checkmarkGlyph from '../assets/icons/checkmark.svg';
import rightArrowGlyph from '../assets/icons/right-arrow.svg';
import owl from '../assets/icons/owl.svg';
export var AIRPLAY_OFF_GLYPH = airplayOffGlyph;
export var AIRPLAY_ON_GLYPH = airplayOnGlyph;
export var ALLOCINE_GLYPH = allocineGlyph;
export var BACKSPACE_GLYPH = backspaceGlyph;
export var CROSS_GLYPH = crossGlyph;
export var EPISODES_GLYPH = episodesGlyph;
export var EXIT_PLAYER_GLYPH = exitPlayerGlyph;
export var EXPERT_MODE_GLYPH = expertModeGlyph;
export var FULLSCREEN_GLYPH = fullscreenGlyph;
export var FULLSCREEN_EXIT_GLYPH = fullscreenExitGlyph;
export var I_INFORMATION_GLYPH = iInformationGlyph;
export var INFO_GLYPH = infoGlyph;
export var LANGUAGE_GLYPH = languageGlyph;
export var KRRIT_ADVISORY_N_GLYPH = krritAdvisoryNGlyph;
export var KRRIT_ADVISORY_P_GLYPH = krritAdvisoryPGlyph;
export var KRRIT_ADVISORY_S_GLYPH = krritAdvisorySGlyph;
export var KRRIT_ADVISORY_W_GLYPH = krritAdvisoryWGlyph;
export var MAGNIFYING_GLASS_GLYPH = magnifyingGlassGlyph;
export var MUTE_GLYPH = muteGlyph;
export var MYCANAL_GLYPH = mycanalGlyph;
export var CAST_SPLASHSCREEN_GLYPH = castSplashscreenGlyph;
export var NEXT_GLYPH = nextGlyph;
export var PAUSE_GLYPH = pauseGlyph;
export var PIP_GLYPH = pipGlyph;
export var PIP_ACTIVE_GLYPH = pipActiveGlyph;
export var PLAY_GLYPH = playGlyph;
export var PLAYBACK_RATE_X025_GLYPH = playbackRateX025;
export var PLAYBACK_RATE_X050_GLYPH = playbackRateX050;
export var PLAYBACK_RATE_X075_GLYPH = playbackRateX075;
export var PLAYBACK_RATE_X100_GLYPH = playbackRateX100;
export var PLAYBACK_RATE_X125_GLYPH = playbackRateX125;
export var PLAYBACK_RATE_X150_GLYPH = playbackRateX150;
export var PLAYBACK_RATE_X175_GLYPH = playbackRateX175;
export var PLAYBACK_RATE_X200_GLYPH = playbackRateX200;
export var REPLAY_TIMELINE_GLYPH = replayTimelineGlyph;
export var REPLAY_GLYPH = replay;
export var LEFT_ARROW_GLYPH = leftArrowGlyph;
export var PARENTAL_RATING_10_GLYPH = parentalRating10Glyph;
export var PARENTAL_RATING_12_GLYPH = parentalRating12Glyph;
export var PARENTAL_RATING_16_GLYPH = parentalRating16Glyph;
export var PARENTAL_RATING_18_GLYPH = parentalRating18Glyph;
export var PARENTAL_RATING_POLISH_18_GLYPH = parentalRatingPolish18Glyph;
export var PARENTAL_RATING_POLISH_16_GLYPH = parentalRatingPolish16Glyph;
export var PARENTAL_RATING_POLISH_12_GLYPH = parentalRatingPolish12Glyph;
export var PARENTAL_RATING_POLISH_7_GLYPH = parentalRatingPolish7Glyph;
export var PARENTAL_RATING_POLISH_0_GLYPH = parentalRatingPolish0Glyph;
export var PARENTAL_RATING_ITALIAN_T_GLYPH = parentalRatingItalianTGlyph;
export var PARENTAL_RATING_ITALIAN_6_GLYPH = parentalRatingItalian6Glyph;
export var PARENTAL_RATING_ITALIAN_12_GLYPH = parentalRatingItalian12Glyph;
export var PARENTAL_RATING_ITALIAN_15_GLYPH = parentalRatingItalian15Glyph;
export var PARENTAL_RATING_ITALIAN_18_GLYPH = parentalRatingItalian18Glyph;
export var PLAYSTATION_CIRCLE_GLYPH = playstationCircleGlyph;
export var PLAYSTATION_CROSS_GLYPH = playstationCrossGlyph;
export var PLAYSTATION_SQUARE_GLYPH = playstationSquareGlyph;
export var PLAYSTATION_TRIANGLE_GLYPH = playstationTriangleGlyph;
export var PLAYSTATION_L1_GLYPH = playstationL1Glyph;
export var PLAYSTATION_L2_GLYPH = playstationL2Glyph;
export var PLAYSTATION_R1_GLYPH = playstationR1Glyph;
export var PLAYSTATION_R2_GLYPH = playstationR2Glyph;
export var QUALITY_4K_HDR_GLYPH = quality4KHDRGlyph;
export var QUALITY_4K_UHD_GLYPH = quality4KUHDGlyph;
export var QUALITY_4K_DBV_GLYPH = quality4KDBVGlyph;
export var QUALITY_DB_ATMOS_GLYPH = qualityDolbyAtmosGlyph;
export var QUALITY_DB_51_GLYPH = qualityDolby51Glyph;
export var SEEK_MINUS_GLYPH = seekMinusGlyph;
export var SEEK_PLUS_GLYPH = seekPlusGlyph;
export var SETTINGS_GLYPH = settingsGlyph;
export var SKIP_ALL_GLYPH = skipAllGlyph;
export var STAR_GLYPH = starGlyph;
export var START_OVER_GLYPH = startOverGlyph;
export var TELERAMA_GLYPH = teleramaGlyph;
export var TIMELINE_GLYPH = timelineGlyph;
export var TV_EPISODES_GLYPH = tvEpisodesGlyph;
export var TV_EXPERT_MODE_GLYPH = tvExpertModeGlyph;
export var TV_INFO_GLYPH = tvInfoGlyph;
export var TV_LANGUAGE_GLYPH = tvLanguageGlyph;
export var TV_NEXT_GLYPH = tvNextGlyph;
export var TV_SETTINGS_GLYPH = tvSettingsGlyph;
export var TV_REPLAY_TIMELINE_GLYPH = tvReplayTimelineGlyph;
export var TV_TIMELINE_GLYPH = tvTimelineGlyph;
export var VALIDATE_GLYPH = validateGlyph;
export var VOLUME_GLYPH = volumeGlyph;
export var CAST_CONNECTED_GLYPH = castConnected;
export var CHECKMARK_GLYPH = checkmarkGlyph;
export var RIGHT_ARROW_GLYPH = rightArrowGlyph;
export var OWL = owl;
