// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default is 1080p for TV (no query) */
`, "",{"version":3,"sources":["webpack://./src/ui/assets/styles/breakpoints.css"],"names":[],"mappings":"AAAA,uCAAuC","sourcesContent":["/* Default is 1080p for TV (no query) */\n@value breakPoint720p: (max-width: 1919px);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakPoint720p": `(max-width: 1919px)`
};
module.exports = ___CSS_LOADER_EXPORT___;
