import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
export var expertModeSelector = function (state) {
    return state.expertMode;
};
/**
 * @returns whether or not the expert mode is fetching
 */
export var isFetchingSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.isFetching; });
/**
 * @returns a list of expert mode strates
 */
export var stratesSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.strates; });
/**
 * @returns whether or not the strate toggle rating is available
 */
export var isToggleRatingAvailableSelector = createDeepEqualSelector(stratesSelector, function (strates) { return !!strates.find(function (strate) { return strate.type === 'toggle'; }); });
/**
 * @returns an error related to the expert mode
 */
export var expertModeErrorSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.error; });
/**
 * @returns whether or not the expert mode notification should be shown
 */
export var shouldShowExpertModeNotificationSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.shouldShowExpertModeNotification; });
/**
 * @returns whether or not the expert mode is available
 */
export var isExpertModeAvailableSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.isExpertModeAvailable; });
/**
 * @returns the videoclipmanifests of the expert mode
 */
export var videoClipManifestsSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.videoClipManifests || {}; });
/**
 * @returns A list of expert mode rubriques
 */
export var rubriquesSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.rubriques; });
/**
 * @returns the expert mode rating error
 */
export var ratingErrorSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.ratingError; });
/**
 * @returns the expert mode timeline error
 */
export var timelineErrorSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.timelineError; });
/**
 * @returns the expert mode rubrique index
 */
export var rubriqueIndexSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.rubriqueIndex; });
/**
 * @returns the list of menu of the expert mode
 */
export var expertModeMenuSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.expertModeMenu; });
/**
 * @returns whether or not the player rating should be shown
 */
export var shouldShowPlayerRatingSelector = createDeepEqualSelector(expertModeSelector, function (expertMode) { return expertMode.shouldShowPlayerRating; });
/**
 * @returns the expert mode survey strate
 */
export var expertModeSurveyStrateSelector = createSelector(stratesSelector, function (strates) {
    var _a;
    return (_a = strates.find(function (strate) { return strate.type === 'survey'; })) !== null && _a !== void 0 ? _a : null;
});
/**
 * @returns the expert mode survery rating content id
 */
export var expertModeSurveyRatingContentIdSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.surveyRatingContentId; });
/**
 * @returns the expert mode current survey rating
 */
export var expertModeCurrentSurveyRatingSelector = createSelector(expertModeSurveyStrateSelector, expertModeSurveyRatingContentIdSelector, function (surveyStrate, currentRatingContentId) {
    return surveyStrate === null || surveyStrate === void 0 ? void 0 : surveyStrate.ratings.find(function (rating) { return rating.contentId === currentRatingContentId; });
});
/**
 * @returns the expert mode survey votes
 */
export var expertModeSurveyVotesSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.surveyVotes; });
/**
 * @returns the expert mode timeline answers
 */
export var expertModeTimelineAnswersSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.expertModeTimelineAnswers; });
/**
 * @returns whether or not the timeline answer of the expert mode is fetching
 */
export var isFetchingTimelineAnswerSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.isFetchingTimelineAnswer; });
/**
 * @returns whether or not the rating answer of the expert mode is fetching
 */
export var isFetchingRatingAnswerSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.isFetchingRatingAnswer; });
/**
 * the timeline active question
 */
export var timelineActiveQuestionSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.timelineActiveQuestion; });
/**
 * @returns the current expert mode template
 */
export var currentTemplateSelector = createSelector(expertModeSelector, function (expertMode) { return expertMode.currentTemplate; });
/**
 * @returns the autorefresh duration of the current template
 */
export var autorefreshSelector = createSelector(currentTemplateSelector, function (currentTemplate) { return currentTemplate.autorefresh; });
/**
 * @returns the current display template of the expert mode
 */
export var currentDisplayTemplateSelector = createSelector(currentTemplateSelector, function (currentTemplate) { return currentTemplate.displayTemplate; });
/**
 * @returns the url of the page of the current template
 */
export var urlPageSelector = createSelector(currentTemplateSelector, function (currentTemplate) { return currentTemplate.urlPage; });
