import { createSelector } from 'reselect';
import { createDeepEqualSelector } from '../../utils/reselectSetup';
export var seriesSelector = function (state) {
    return state.series;
};
/**
 * @returns {function(object): string}
 */
export var currentEpisodeIdSelector = createSelector(seriesSelector, function (series) { return series.currentEpisodeId; });
/**
 * @returns {function(object): boolean}
 */
export var hasSeasonSelector = createSelector(seriesSelector, function (series) { return series.seasonLength > 0; });
/**
 * @returns {function(object): Array}
 */
export var seasonsSelector = createDeepEqualSelector(seriesSelector, function (series) { return series.seasons; });
/**
 * @returns {function(object): Array}
 */
export var hapiEpisodesProgressSelector = createDeepEqualSelector(seriesSelector, function (series) { return series.episodesProgress; });
/**
 * @returns {function(object): object}
 */
export var currentSeasonDataSelector = createSelector(seriesSelector, function (series) { return series.currentSeasonData; });
/**
 * @returns {function(object): number | undefined}
 */
export var currentSeasonNumberSelector = createSelector(currentSeasonDataSelector, function (seasonData) { return seasonData === null || seasonData === void 0 ? void 0 : seasonData.seasonNumber; });
/**
 * @returns {function(object): boolean}
 */
export var areSkipButtonsShownSelector = createSelector(seriesSelector, function (series) { return series.areSkipButtonsShown; });
/**
 * @returns {function(object): object}
 */
export var getSkipButtonSelector = createSelector(seriesSelector, function (series) { return series.skipButton; });
/**
 * @returns {function(object): boolean}
 */
export var forceShowSkipButtonsSelector = createSelector(seriesSelector, function (series) { return series.forceShowSkipButtons; });
/**
 * @returns {function(object): object}
 */
export var isSkipEndCreditsCancelledSelector = createSelector(seriesSelector, function (series) { return series.isSkipEndCreditsCancelled; });
/**
 * @returns {function(object): object}
 */
export var localEpisodesProgressSelector = createDeepEqualSelector(seriesSelector, function (series) { return series.localEpisodesProgress; });
/**
 * @returns {function(object): object}
 */
export var currentEpisodeProgressSelector = createDeepEqualSelector(seriesSelector, function (series) { return series.currentEpisodeProgress; });
/**
 * @returns {function(object): boolean}
 */
export var shouldShowSynopsisSelector = createSelector(seriesSelector, function (series) { return series.shouldShowSynopsis; });
/**
 * @returns {function(object): number}
 */
export var currentSeasonSelector = createSelector(seriesSelector, function (series) { return series.currentSeason; });
