import { actionTypes } from '@canalplus/oneplayer-constants';
var ERROR_SHOW = actionTypes.ERROR_SHOW, ERROR_RESET = actionTypes.ERROR_RESET;
export function showErrorAction(code, category, options) {
    if (options === void 0) { options = {}; }
    return {
        type: ERROR_SHOW,
        payload: { code: code, category: category, options: options },
    };
}
export function resetErrorAction() {
    return {
        type: ERROR_RESET,
    };
}
