import { createSelector } from 'reselect';
export var miniplayerSelector = function (state) {
    return state.miniplayer;
};
/**
 *  * @returns {function(object): boolean}
 */
export var isMiniPlayerFullFrameSelector = createSelector(miniplayerSelector, function (miniplayer) { return miniplayer.isFullFrame; });
/**
 *  * @returns {function(object): string || null}
 */
export var fullFrameMiniPlayerIdSelector = createSelector(miniplayerSelector, function (miniplayer) { return miniplayer.fullFrameMiniPlayerId; });
