export const FR_FR = 'fr-FR';
export const FR_CH = 'fr-CH';
export const EN_EN = 'en-EN';
export const EN_US = 'en-US';
export const PL_PL = 'pl-PL';
export const IT_IT = 'it-IT';
export const DE_CH = 'de-CH';
export const CS_CS = 'cs-CS';
export const SK_SK = 'sk-SK';
export const SL_SL = 'sl-SL';
export const DEFAULT_LANGUAGE_CODE_FROM_OFFER_ZONE = 'fr-FR';

// Normalized (ISO 639-3) language codes
export const FRA = 'fra';
export const ENG = 'eng';
export const POL = 'pol';
export const ITA = 'ita';
export const QAA = 'qaa';
export const QAD = 'qad';

// Normalized id for the disabled text track
export const DISABLED = 'disabled';
