import { logger } from '../logger';

/**
 * Check if Picture in Picture is available
 * @returns - Returns boolean to know if pip is available
 */
export const isPipAvailable = (): boolean =>
  !!(document as any).pictureInPictureEnabled;

/**
 * Toggle Pip features on/off
 * @param videoElement - Get info about the content if loaded (style, preload, airplay, tag...)
 * @param isPipActive - Get the state of the pip
 */
export const togglePip = async (
  videoElement: HTMLMediaElement,
  isPipActive: boolean,
): Promise<void> => {
  if (videoElement) {
    if (isPipActive) {
      if (typeof document.exitPictureInPicture === 'function') {
        try {
          await (document as any).exitPictureInPicture();
        } catch (error) {
          logger.error('OnePlayer > Unable to exit picture in picture', error);
        }
      }
    } else {
      try {
        await (videoElement as any).requestPictureInPicture();
      } catch (error) {
        logger.error('OnePlayer > Unable to request picture in picture', error);
      }
    }
  }
};
