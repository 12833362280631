/* eslint-disable no-undef */
import Bowser from 'bowser';

/**
 * Detection of browser by checking different property
 * See : http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
 */

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace safari {
  function pushNotification(): void;
}

// Check for Node Env
export const isNode = (): boolean => typeof window === 'undefined';

// Firefox 1.0+
export const isFirefox = (): boolean =>
  typeof window.InstallTrigger !== 'undefined';

// Internet Explorer 6-11
export const isIE = (): boolean =>
  /* @cc_on!@ */ false || !!('documentMode' in window);

// Edge Chromium
export const isEdgeChromium = (): boolean =>
  navigator.userAgent.indexOf('Chrome/') !== -1 &&
  navigator.userAgent.indexOf('Edg/') !== -1;

// Edge 20+
export const isEdge = (): boolean =>
  !isIE() && !isEdgeChromium() && !!('StyleMedia' in window);

// Windows
export const isWindows = (): boolean =>
  navigator.userAgent.indexOf('Windows') !== -1 ||
  navigator.userAgent.indexOf('Win64') !== -1;

// Playstation
export const isPlaystation = (): boolean =>
  navigator.userAgent.indexOf('Playstation') !== -1 ||
  navigator.userAgent.indexOf('PlayStation') !== -1;

// Check IPhone Env
export const isSafariMobile = (): boolean =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const isSafari = (): boolean =>
  // At least Safari 3+: "[object HTMLElementConstructor]"
  Object.prototype.toString.call(window.HTMLElement).includes('Constructor') ||
  // the following statement check if the window.safari contains the method
  // "pushNotification", this condition is not met when using web app from the dock
  // on macOS, this is why we also check userAgent.
  (!('safari' in window) || safari.pushNotification).toString() ===
    '[object SafariRemoteNotification]' ||
  // browsers are lying: Chrome reports both as Chrome and Safari in user
  // agent string, So to detect Safari we have to check for the Safari string
  // and the absence of the Chrome string
  // eslint-disable-next-line max-len
  // @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#which_part_of_the_user_agent_contains_the_information_you_are_looking_for
  (/Safari\/(\d+)/.test(navigator.userAgent) &&
    // Safari should contain Version/ in userAgent
    /Version\/(\d+)/.test(navigator.userAgent) &&
    navigator.vendor?.indexOf('Apple') !== -1 &&
    !/Chrome\/(\d+)/.test(navigator.userAgent) &&
    !/Chromium\/(\d+)/.test(navigator.userAgent));

// Chrome 1+
export const isChrome = (): boolean =>
  !!navigator.userAgent.match(/chrome|chromium|crios/i);

// Chrome mobile +1
export const isChromeMobile = (): boolean =>
  isChrome() && typeof window.orientation !== 'undefined';

export const isAndroid = (): boolean =>
  navigator.userAgent.toLowerCase().indexOf('android') > -1;

// Chrome with older versions of widevine
export const IsChromeWithOldWidevineVersion = (): boolean => {
  const chromeVersion = navigator.userAgent.match(/Chrome\/([0-9]*)/);
  if (chromeVersion === null || chromeVersion.length < 1) {
    return false;
  }
  return (
    isChrome() &&
    /Chrome\/([1-9]*)/.test(navigator.userAgent) &&
    parseInt(chromeVersion[1], 10) < 59
  );
};

// Samsung Internet
export const isSamsungInternet = (): boolean =>
  /SamsungBrowser/i.test(navigator.userAgent);

// UC Browsers
export const isUCBrowser = (): boolean =>
  /UCBrowser/i.test(navigator.userAgent);

// Facebook in-app browser has specific user agent that doesn't match with general UIWebView
// we need to verify
const isFacebookWebView = (): boolean =>
  navigator.userAgent?.indexOf('FBAN') > -1 ||
  navigator.userAgent?.indexOf('FBAV') > -1 ||
  navigator.vendor?.indexOf('FBAN') > -1 ||
  navigator.vendor?.indexOf('FBAV') > -1 ||
  window.opera?.indexOf('FBAN') > -1 ||
  window.opera?.indexOf('FBAV') > -1;

// IOS Webview
export const isAppleUIFacebookWebView = (): boolean =>
  /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent) &&
  isFacebookWebView();

export const isAndroidUIFacebookWebView = (): boolean =>
  isAndroid() && isFacebookWebView();

export const isVersionSupported = (): boolean =>
  Bowser.getParser(window.navigator.userAgent).satisfies({
    chrome: '>=66', // Chrome
    safari: '>=5',
    firefox: '>=50',
    edge: '>=16',
    samsung_internet: '>=8',
    opera: '>=8',
    uc: '>=10',
  }) || false;

// Check for customElements (cast framework can't work without customElements)
export const isCustomElementsSupported = (): boolean => {
  return Boolean(window?.customElements);
};

// Do not consider playstation as safari env
export const isSafariEnv = (): boolean => {
  return (
    (isSafari() || isAppleUIFacebookWebView() || isSafariMobile()) &&
    !isPlaystation()
  );
};

export const isSupportedBrowser = (): boolean =>
  // Check browser with supported version
  ((isEdge() ||
    isChrome() ||
    isSafari() ||
    isFirefox() ||
    isSamsungInternet() ||
    isUCBrowser()) &&
    isVersionSupported()) ||
  // Check specific browser on Mobile devices without version
  // (because version is not available in user agent for Chrome and Safari mobile)
  isChromeMobile() ||
  isSafariMobile() ||
  isAppleUIFacebookWebView() ||
  isAndroidUIFacebookWebView();
