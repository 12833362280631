import { createSelector } from 'reselect';
export var sidepanelSelector = function (state) {
    return state.sidepanel;
};
/**
 * @returns {function(object): boolean}
 */
export var isSidepanelOpenSelector = createSelector(sidepanelSelector, function (sidepanel) { return sidepanel.isOpened; });
/**
 * @returns {function(object): string}
 */
export var sidePanelNameSelector = createSelector(sidepanelSelector, function (sidepanel) { return sidepanel.name; });
