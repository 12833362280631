const countDownBeforeSkipEndCredits = 10; // seconds
const countDownBeforeSkipOpeningCreditHidden = 10;
const countDownBeforeSkipRecapHidden = 10;
const showSkippableTimeOffset = 10;

export const skipContentOffsets = {
  countDownBeforeSkipEndCredits,
  countDownBeforeSkipOpeningCreditHidden,
  countDownBeforeSkipRecapHidden,
  showSkippableTimeOffset,
};
