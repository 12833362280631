/**
 * List of domains that should be proxified, just precise a path on the
 * local dev server that will be used as a mask.
 *
 * For example :
 * {
 *  path: '/proxy/example',
 *  domain: 'https://example.com',
 * }
 *
 * When enabled, every request on domain "https://example.com/"
 * will be "proxified" to local devServer on route "/proxy/example"
 *
 * Important: Keep that file in vanilla JS because NodeJS does not support Typescript by default.
 * More info: This file is used by devServer.js and run by NodeJS in development.
 */

const proxies = [
  {
    path: '/proxy/pass-ghome',
    domain: 'https://pass.canal-plus.com',
    enabled: true,
  },
  {
    path: '/proxy/pass-cos',
    domain: 'https://pass-api.canal-overseas.com',
    enabled: true,
  },
  {
    path: '/proxy/liveTv',
    domain: 'https://secure-webtv.canal-plus.com',
    enabled: true,
  },
  {
    path: '/proxy/chid601/chid601.json',
    domain:
      '//secure-webtv-static.canal-plus.com/metadata/cpfra/canal/v2.2/chid601.json',
    fromLocalResources: true,
    enabled: false,
  },
  {
    path: '/proxy/chid301/chid301.json',
    domain:
      '//secure-webtv-static.canal-plus.com/metadata/cpfra/canal/v2.2/chid301.json',
    fromLocalResources: true,
    enabled: false,
  },
  {
    path: '/proxy/chid450/chid450.json',
    domain:
      '//secure-webtv-static.canal-plus.com/metadata/cpfra/canal/v2.2/chid450.json',
    fromLocalResources: true,
    enabled: true,
  },
  {
    path: '/proxy/chid543/chid543.json',
    domain:
      '//secure-webtv-static.canal-plus.com/metadata/cpfra/canal/v2.2/chid543.json',
    fromLocalResources: true,
    enabled: true,
  },
  {
    path: '/proxy/vmap',
    domain: '//test.vmap.com',
    fromLocalResources: true,
    enabled: false,
  },
  {
    path: '/proxy/getEpg/1479295.json',
    domain:
      'https://secure-service.canal-plus.com/video/rest/getEPG/cplus/1479295',
    fromLocalResources: true,
    enabled: false,
  },
  {
    path: '/proxy/expertMode/expertMode-v2.json',
    domain: 'https://hodor.canalplus.pro/api/v2/mycanal/expertMode/display/',
    fromLocalResources: true,
    enabled: true,
    shouldReplaceEntireUrl: true,
  },
];

// This array could be transformed later as a function to parametise which expert mode stored locally
// should we render (depending on sport for example).
const expertModeProxies = (sport) => [
  {
    path: `/proxy/expertMode/${sport}/expertMode.json`,
    domain: 'https://hodor.canalplus.pro/api/v2/mycanal/expertMode/display/',
    enabled: true,
    shouldReplaceEntireUrl: true,
  },
];

// We use a module.exports here in order to be able to use it in devServer.js
// More info: This file is used by devServer.js and run by NodeJS through package.json scripts
module.exports = {
  proxies,
  expertModeProxies,
};
