import { createSelector } from 'reselect';
export var deviceSelector = function (state) {
    return state.device;
};
/**
 * @returns {function(object): boolean}
 */
export var isPhoneSelector = createSelector(deviceSelector, function (device) { return device.isPhone; });
/**
 * @returns {function(object): boolean}
 */
export var isMobileSelector = createSelector(deviceSelector, function (device) { return device.isPhone || device.isTablet; });
/**
 * @returns {function(object): boolean}
 */
export var deviceNameSelector = createSelector(deviceSelector, function (device) { return device.name; });
/**
 * @returns {function(object): boolean}
 */
export var isIphoneSelector = createSelector(deviceNameSelector, function (deviceName) { return deviceName === 'iPhone'; });
/**
 * @returns {function(object): number}
 */
export var deviceOrientationSelector = createSelector(deviceSelector, function (device) { return device.orientation; });
