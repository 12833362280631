import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
var styles = require('./Icon.css');
var Icon = function (_a) {
    var _b;
    var glyph = _a.glyph, _c = _a.width, width = _c === void 0 ? '100%' : _c, _d = _a.height, height = _d === void 0 ? '100%' : _d, fill = _a.fill, fillOpacity = _a.fillOpacity, className = _a.className, dataTestId = _a.dataTestId, isDisabled = _a.isDisabled;
    return (_jsx("svg", { viewBox: "".concat(glyph.viewBox), className: classnames(styles.Icon, className, (_b = {},
            _b[styles.disabled] = isDisabled,
            _b)), width: width, height: height, style: { fill: fill, fillOpacity: fillOpacity }, "data-testid": dataTestId, children: _jsx("use", { xlinkHref: "#".concat(glyph.id) }) }));
};
export default Icon;
