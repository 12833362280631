import { createSelector } from 'reselect';
export var liveTvSelector = function (state) {
    return state.liveTv;
};
/**
 * @returns {function(object): bool}
 */
export var isLiveTvStoppedSelector = createSelector(liveTvSelector, function (liveTv) { return liveTv.isLiveTvStopped; });
/**
 * @returns {function(object): bool}
 */
export var isLiveTvInitiatedSelector = createSelector(liveTvSelector, function (liveTv) { return liveTv.isLiveTvInitiated; });
