import { SequenceTypes } from '@canalplus/oneplayer-constants';
import { createSelector } from 'reselect';
var AIRPLAY_ENABLED_SEQUENCES = SequenceTypes.AIRPLAY_ENABLED_SEQUENCES, AD_SEQUENCES = SequenceTypes.AD_SEQUENCES, PIP_ENABLED_SEQUENCES = SequenceTypes.PIP_ENABLED_SEQUENCES, SEQUENCES_TYPES = SequenceTypes.SEQUENCES_TYPES;
export var sequenceSelector = function (state) {
    return state.sequence;
};
/**
 * @returns {function(object): string}
 */
export var currentSequenceSelector = createSelector(sequenceSelector, function (sequence) { return sequence.current; });
/**
 * @returns {function(object): CONST string}
 */
export var receiverSequenceSelector = createSelector(sequenceSelector, function (sequence) { return sequence.receiver; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceBookendSelector = createSelector(currentSequenceSelector, receiverSequenceSelector, function (currentSequence, receiver) {
    return currentSequence === SEQUENCES_TYPES.POSTER ||
        (currentSequence === SEQUENCES_TYPES.CAST &&
            receiver === SEQUENCES_TYPES.POSTER);
});
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceAdSelector = createSelector(currentSequenceSelector, function (currentSequence) { return AD_SEQUENCES.includes(currentSequence); });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceContentSelector = createSelector(currentSequenceSelector, function (currentSequence) { return currentSequence === SEQUENCES_TYPES.CONTENT; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceCastSelector = createSelector(currentSequenceSelector, function (currentSequence) { return currentSequence === SEQUENCES_TYPES.CAST; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceAirplayEnabledSelector = createSelector(currentSequenceSelector, function (currentSequence) { return AIRPLAY_ENABLED_SEQUENCES.includes(currentSequence); });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequenceParentalRatingSelector = createSelector(currentSequenceSelector, function (currentSequence) { return currentSequence === SEQUENCES_TYPES.PARENTAL_RATING; });
/**
 * @returns {function(object): boolean}
 */
export var isCurrentSequencePipEnabledSelector = createSelector(currentSequenceSelector, function (currentSequence) { return PIP_ENABLED_SEQUENCES.includes(currentSequence); });
/**
 * @returns {function(object): string[]}
 */
export var stackSequenceSelector = createSelector(sequenceSelector, function (sequence) { return sequence.stack; });
/**
 * @returns {function(object): string[]}
 */
export var initializationCountSelector = createSelector(sequenceSelector, function (sequence) { return sequence.initializationCount; });
