/**
 *
 * @param input
 */
export function isNullOrUndefined(
  input: unknown,
): input is null | undefined | void {
  return input === null || input === undefined;
}

/**
 * Check if the input is an object
 * @param {} input - every kind of input.
 */
export function isObject(
  input: unknown,
): input is Record<PropertyKey, unknown> {
  return (
    !isNullOrUndefined(input) &&
    input instanceof Object &&
    !Array.isArray(input)
  );
}

/**
 * Check if the input is an empty object
 * @param {} input - every kind of input.
 */
export function isEmptyObject(input: unknown): boolean {
  return isObject(input) && Object.keys(input).length === 0;
}

/**
 * Check if the input is an object that has a specific property
 * @param {} input - every kind of input.
 * @param prop - a string, number or symbol
 */
export function checkUnknownHasOwnProperty<X, Y extends PropertyKey>(
  input: X,
  prop: Y,
): input is X & Record<Y, unknown> {
  return isObject(input) && prop in input;
}
