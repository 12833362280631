import { createSelector } from 'reselect';
export var castSelector = function (state) { return state.cast; };
/**
 * @returns {function(object): bool}
 */
export var isCastActiveSelector = createSelector(castSelector, function (cast) { return cast.isActive; });
/**
 * @returns {function(object): bool}
 */
export var isCastAvailableSelector = createSelector(castSelector, function (cast) { return cast.isAvailable; });
/**
 * @returns {function(object): bool}
 */
export var isCastPendingSelector = createSelector(castSelector, function (cast) { return cast.isPending; });
/**
 * @returns {function(object): bool}
 */
export var isCastIdleAfterPlayingSelector = createSelector(castSelector, function (cast) { return cast.isIdleAfterPlaying; });
/**
 * @returns {function(object): string}
 */
export var receiverNameSelector = createSelector(castSelector, function (cast) { return cast.receiverName; });
/**
 * @returns {function(object): string}
 */
export var receiverIdSelector = createSelector(castSelector, function (cast) { return cast.receiverId; });
/**
 * @returns {function(object): object}
 */
export var castMetadataSelector = createSelector(castSelector, function (cast) { return cast.metadata; });
/**
 * @returns {function(object): object}
 */
export var castAvailableLanguagesSelector = createSelector(castSelector, function (cast) { return cast.availableLanguages; });
/**
 * @returns {function(object): object}
 */
export var castAvailableSubtitlesSelector = createSelector(castSelector, function (cast) { return cast.availableSubtitles; });
/**
 * @returns {function(object): object}
 */
export var castCurrentSubtitleSelector = createSelector(castSelector, function (cast) { return cast.currentSubtitle; });
/**
 * @returns {function(object): object}
 */
export var castCurrentLanguageSelector = createSelector(castSelector, function (cast) { return cast.currentLanguage; });
export var currentRequestFromReceiverSelector = createSelector(castSelector, function (cast) { return cast.currentRequestFromReceiver; });
/**
 * @returns {function(object): string}
 */
export var castErrorSelector = createSelector(castSelector, function (cast) { return cast.error; });
/**
 * @returns {function(object): number}
 */
export var lastCurrentTimeBeforeEndCastSelector = createSelector(castSelector, function (cast) { return cast.lastCurrentTimeBeforeEndCast; });
/**
 * @returns {function(object): number}
 */
export var receiverReferenceDateTimeSelector = createSelector(castSelector, function (cast) { return cast.receiverReferenceDateTime; });
/**
 * @returns {function(object): boolean}
 */
export var reloadContentSelector = createSelector(castSelector, function (cast) { return cast.reloadContent; });
