/**
 * Takes a string with {scheme}
 * and returns a string with https:// instead.
 * @param str // string with {scheme}
 * @returns string with https:// instead of {scheme}
 */
export function parseSchemeTemplate(str: string): string {
  return typeof str === 'string' ? str.replace('{scheme}', 'https://') : '';
}

/**
 * Ensure HTTPS SSL protocol is used over HTTP
 * So, replace an url containing http:// by https://
 * @param url
 * @returns
 */
export function ensureHttpsOnUrl(url: string | undefined): string {
  return typeof url === 'string' ? url.replace(/^http:\/\//i, 'https://') : '';
}

/**
 * Takes a string and remove his `http://`
 * @param str
 * @returns string without http
 */
export function removeProtocolFromUrl(str: string): string {
  return typeof str === 'string' ? str.replace(/(^\w+:|^)\/\//, '') : '';
}

/**
 * Takes a string return true if the url target a file with an extension
 * WARNING: false positive from domain url, like google.com, gouv.fr...
 * @param str
 * @returns true if the url target a file with an extension
 */
export function isUrlWithFileExtension(str: string): boolean {
  return typeof str === 'string'
    ? Boolean(str.match(/\.([0-9a-zA-Z]+)(?:[?#]|$)/g))
    : false;
}

/**
 * Takes a string with templated value
 * and returns a string with the correct values instead of the templates.
 * @param str // string with templated values
 * @param templateContext // data to use to fill the templated values
 * @returns string with correct values instead of templates
 */
export function template(
  str: string,
  templateContext: { [key: string]: string | number | boolean },
): string {
  if (str === '' || typeof str !== 'string') {
    return '';
  }
  const reTemplate = /{([\s\S]+?)}/g;
  const ternaryTemplate = /([\s\S]+?) \? ([\s\S]+?) : ([\s\S]+?)$/g;
  const quoteTemplate = /^['"].*['"]$/g;

  // replace every occurance of '{*}' with the corresponding value
  return str.replace(reTemplate, (_, submatch): string => {
    const [fullResult, conditionValue, valueTrue, valueFalse] =
      ternaryTemplate.exec(submatch) || [];

    // no ternary expression detected.
    // Returns the value associated with submatch in templateContext.
    if (!fullResult) {
      const templated = templateContext[submatch];
      return templated != null ? templated.toString() : '';
    }

    // The submatch looks like 'x ? y : z'
    // and templateContext[x] is true.
    if (templateContext[conditionValue]) {
      // return y if it is a string  (quoted), otherwise return templateContext[y]
      if (quoteTemplate.test(valueTrue)) {
        return valueTrue.replace(/['"]+/g, '');
      }
      const templated = templateContext[valueTrue];
      return templated != null ? templated.toString() : '';
    }

    // The submatch looks like 'x ? y : z'
    // and templateContext[x] is false.
    // return x if it is a string (quoted), otherwise return templateContext[x]
    if (quoteTemplate.test(valueFalse)) {
      return valueFalse.replace(/['"]+/g, '');
    }
    const templated = templateContext[valueFalse];
    return templated != null ? templated.toString() : '';
  });
}

/**
 * @description remove empty segments inside url and return a clean url with https protocol
 * @param url the url to clean
 * @returns
 */
export const removeEmptySegmentsFromUrl = (url: string): string => {
  const [baseUrl, urlParams] = url.split('?', 2);
  const cleanUrlWithoutHttp = removeProtocolFromUrl(baseUrl).replace(
    /\/+/g,
    '/',
  );
  if (urlParams) {
    return `${'https://'.concat(cleanUrlWithoutHttp)}?${urlParams}`;
  }
  return 'https://'.concat(cleanUrlWithoutHttp);
};
