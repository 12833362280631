import { logger } from '@canalplus/oneplayer-utils';
import * as Utils from './utils';
import VASTAd from './VASTAd';

export default class VASTResponse {
  static parseErrorElement(errorNode) {
    return Utils.parseNodeText(errorNode);
  }

  static parseInLineElement(inLineElement) {
    return new VASTAd(inLineElement);
  }

  static parseWrapperElement(wrapperElement) {
    // Parsing of WrapperElement like an InLineElement
    const ad = this.parseInLineElement(wrapperElement);

    if (ad.nextWrapperURL === null) {
      logger.warn('OnePlayer Ads > VASTAdTagURI is missing in Wrapper');
      return null;
    }
    return ad;
  }

  static parseAdElement(adElement) {
    const adSubElements = adElement.childNodes;
    for (let i = 0; i < adSubElements.length; i += 1) {
      const adTypeElement = adSubElements[i];
      adTypeElement.id = adElement.getAttribute('id');

      switch (adTypeElement.nodeName) {
        case 'Wrapper': {
          return this.parseWrapperElement(adTypeElement);
        }

        case 'InLine': {
          return this.parseInLineElement(adTypeElement);
        }

        default: {
          const curNodeName = adTypeElement.nodeName;
          const parentNodeName = adElement.nodeName;

          if (curNodeName !== '#text') {
            logger.warn(
              `OnePlayer Ads > ${curNodeName} is not implemented (${parentNodeName})`,
            );
          }
        }
      }
    }
    return null;
  }

  constructor(xmlElement) {
    this.ads = [];
    this.errorURLTemplates = [];
    // this.version = null;

    this.parse(xmlElement);
  }

  getAds() {
    return this.ads;
  }

  getAdsSupported(mimeTypes) {
    const ads = [];
    const allAds = this.getAds();

    if (!mimeTypes) {
      return allAds;
    }

    allAds.forEach((ad) => {
      const linear = ad.getLinearCreative();

      if (linear) {
        const hasMediaFilesSupported = linear.hasMediaFilesSupported(mimeTypes);

        if (hasMediaFilesSupported) {
          ads.push(ad);
        } else {
          // ### Error 403: coudn't find MediaFile that is supported by this video player
          Utils.trackErrorWithCode(ad.errorURLTemplates, 403);

          logger.warn(
            'OnePlayer Ads > this Ad Linear is not supported for the moment',
            ad,
          );
        }
      }
    });

    return ads;
  }

  getDuration() {
    let duration = 0;

    this.ads.forEach((ad) => {
      const linear = ad.getLinearCreative();
      if (!ad.getSponsorGenre()) {
        duration += linear.duration;
      }
    });

    return duration;
  }

  getSupportedDuration(mimeTypes) {
    return this.getAdsSupported(mimeTypes).reduce(
      (totalDur, ad) =>
        ad.getSponsorGenre()
          ? totalDur
          : totalDur + ad.getLinearCreative().duration,
      0,
    );
  }

  setAds(adElement) {
    const ad = VASTResponse.parseAdElement(adElement);

    if (ad !== null) {
      this.ads.push(ad);
    } else {
      // ### Error 101: VAST schema validation error
      Utils.trackErrorWithCode(this.errorURLTemplates, 101);
    }
  }

  setErrorURLTemplates(urls) {
    this.errorURLTemplates = this.errorURLTemplates.concat(urls);
  }

  setVersion(version) {
    this.version = version;
  }

  parse(vastElement) {
    const mainSubElements = vastElement ? vastElement.childNodes : [];

    // this.setVersion(vastElement.getAttribute('version'));

    for (let i = 0; i < mainSubElements.length; i += 1) {
      const node = mainSubElements[i];

      switch (node.nodeName) {
        case 'Error': {
          const errorURLTemplate = [VASTResponse.parseErrorElement(node)];
          this.setErrorURLTemplates(errorURLTemplate);
          break;
        }

        case 'Ad': {
          this.setAds(node);
          break;
        }

        default: {
          if (node.nodeName !== '#text') {
            logger.warn(
              `OnePlayer Ads > ${node.nodeName} is not implemented (main node)`,
            );
          }
        }
      }
    }
  }
}
