import * as Utils from './utils';

export default class VASTMediaFile {
  constructor(xmlElement) {
    this.id = null;
    this.fileURL = null;
    this.deliveryType = 'progressive';
    this.mimeType = null;
    this.codec = null;
    this.bitrate = 0;
    this.minBitrate = 0;
    this.maxBitrate = 0;
    this.width = 0;
    this.height = 0;
    this.apiFramework = null;
    this.scalable = null;
    this.maintainAspectRatio = null;

    this.parse(xmlElement);
  }

  setId(id) {
    this.id = id;
  }

  setFileURL(fileURL) {
    this.fileURL = fileURL;
  }

  setDeliveryType(deliveryType) {
    this.deliveryType = deliveryType;
  }

  setMimeType(mimeType) {
    this.mimeType = mimeType;
  }

  setCodec(codec) {
    this.codec = codec || 0;
  }

  setBitrate(bitrate, minBitrate, maxBitrate) {
    this.bitrate = parseInt(bitrate, 10) || 0;
    this.minBitrate = parseInt(minBitrate, 10) || 0;
    this.maxBitrate = parseInt(maxBitrate, 10) || 0;
  }

  setSize(width, height) {
    this.width = parseInt(width, 10) || 0;
    this.height = parseInt(height, 10) || 0;
  }

  setApiFramework(apiFramework) {
    this.apiFramework = apiFramework;
  }

  setScalable(scalable) {
    if (scalable && typeof scalable === 'string') {
      const s = scalable.toLowerCase();
      if (s === 'true') {
        this.scalable = true;
      } else if (s === 'false') {
        this.scalable = false;
      }
    }
  }

  setMaintainAspectRatio(maintainAspectRatio) {
    if (maintainAspectRatio && typeof maintainAspectRatio === 'string') {
      const m = maintainAspectRatio.toLowerCase();
      if (m === 'true') {
        this.maintainAspectRatio = true;
      } else if (m === 'false') {
        this.maintainAspectRatio = false;
      }
    }
  }

  parse(mediaFileElement) {
    this.setId(mediaFileElement.getAttribute('id'));
    this.setFileURL(Utils.parseNodeText(mediaFileElement));
    this.setDeliveryType(mediaFileElement.getAttribute('delivery'));
    this.setCodec(mediaFileElement.getAttribute('codec'));
    this.setMimeType(mediaFileElement.getAttribute('type'));
    this.setApiFramework(mediaFileElement.getAttribute('apiFramework'));
    this.setBitrate(
      mediaFileElement.getAttribute('bitrate'),
      mediaFileElement.getAttribute('minBitrate'),
      mediaFileElement.getAttribute('maxBitrate'),
    );
    this.setSize(
      mediaFileElement.getAttribute('width'),
      mediaFileElement.getAttribute('height'),
    );

    this.setScalable(mediaFileElement.getAttribute('scalable'));
    this.setMaintainAspectRatio(
      mediaFileElement.getAttribute('maintainAspectRatio'),
    );
  }
}
