type TCurrentScript = HTMLOrSVGScriptElement & { src: string };

export function getBaseFromDocumentCurrentScript(): string | null {
  const { src: scriptSrc = '' } =
    (document?.currentScript as TCurrentScript) || {};
  if (scriptSrc) {
    return `${scriptSrc.split('/').slice(0, -1).join('/')}/`;
  }
  return null;
}

export function getOriginFromDocumentCurrentScript(): string | null {
  const { src: scriptSrc = '' } =
    (document?.currentScript as TCurrentScript) || {};
  if (scriptSrc) {
    const url = new URL(scriptSrc);
    return url.origin;
  }
  return null;
}

export default getBaseFromDocumentCurrentScript;
