import { ILiveTime } from '@canalplus/oneplayer-types';

interface IDiffTime {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
}

/**
 * @description Transform a number|Date to a milliseconds representation
 * @param time can be a number or a date
 * @returns the stored time value in milliseconds
 */
export function numOrDateToNum(time: number | Date | undefined): number {
  if (typeof time === 'number') {
    return time;
  }

  if (time instanceof Date) {
    return time.getTime();
  }

  return 0;
}

/**
 * @description Transform a number|string|Date to a milliseconds representation
 * @param time can be a number a string or a date
 * @returns the stored time value in milliseconds
 */
export function toMilliseconds(time: number | string | Date): number {
  if (typeof time === 'number') {
    return time * 1000;
  }

  if (typeof time === 'string') {
    return parseInt(time, 10) * 1000;
  }

  return time?.getTime() || 0;
}

/**
 * @description Transform a number|string milliseconds to a seconds representation
 * @param msDuration can be a number or a string
 * @returns number in seconds
 */
export function milliSecondsToSeconds(msDuration: number | string): number {
  if (typeof msDuration === 'string') {
    return Math.round(parseInt(msDuration, 10) / 1000);
  }
  return Math.round(msDuration / 1000);
}

/**
 * @description An utils class that permits to pause|resume a timer.
 * And also get some informations on the progress and the time remaining of the timer.
 */
export class Timer {
  timerId: number | null;

  lastStart: number | null;

  remaining: number;

  readonly delay: number;

  readonly callback: (...args: any[]) => any;

  constructor(
    callback: (...args: any[]) => any,
    delay: number,
    startOnInit = true,
  ) {
    this.timerId = null;
    this.lastStart = null;
    this.delay = delay;
    this.remaining = delay;
    this.callback = callback;

    if (startOnInit !== false) {
      this.resume();
    }
  }

  getRemaining = (): number => {
    if (this.lastStart !== null) {
      return -(Date.now() - this.lastStart - this.remaining);
    }
    return 0;
  };

  getProgress = (): number => {
    if (this.lastStart !== null) {
      return this.delay + (Date.now() - this.lastStart - this.remaining);
    }
    return 0;
  };

  clearTimer = (): void => {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }
  };

  pause = (): void => {
    this.clearTimer();
    if (this.lastStart !== null) {
      this.remaining -= Date.now() - this.lastStart;
    }
  };

  resume = (): void => {
    this.lastStart = Date.now();
    this.clearTimer();
    this.timerId = window.setTimeout(this.callback, this.remaining);
  };
}

/**
 * @description Parse a date string that comes from the server and output a human readable format
 * This data comes from the LiveTV server under the form: "20200403105345"
 * @param server LiveTV server
 * @returns human readable format of the date string
 */
export function parseDate(server: string): ILiveTime {
  const CANAL_GETTIME_REGEX = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/;
  const parsedServerDate = server.match(CANAL_GETTIME_REGEX);

  if (parsedServerDate === null) {
    return { server, now: new Date(), offset: 0 };
  }
  const [origin, year, month, day, hour, min, sec] = parsedServerDate;
  if (origin === undefined) {
    return { server, now: new Date(), offset: 0 };
  }
  const yearNb = parseInt(year, 10);
  const monthNb = parseInt(month, 10);
  const dayNb = parseInt(day, 10);
  const hourNb = parseInt(hour, 10);
  const minNb = parseInt(min, 10);
  const secNb = parseInt(sec, 10);

  const serverUTC = new Date(
    Date.UTC(yearNb, monthNb - 1, dayNb, hourNb, minNb, secNb),
  );
  const serverTimestamp = Date.UTC(
    yearNb,
    monthNb - 1,
    dayNb,
    hourNb,
    minNb,
    secNb,
  );
  const client = Date.now();
  const clientUTC = new Date();
  const offset = milliSecondsToSeconds(client - serverTimestamp);

  return {
    server,
    client,
    clientUTC,
    serverUTC,
    serverTimestamp,
    offset,
  };
}

/**
 * @description Can be used to pause a flow, to use with await
 * @param duration set to 0
 * @returns a promise
 */
export function pause(duration = 0): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

/**
 * @description Return a formatted time given a number of seconds
 * E.g : secToFormatHours(100) -> 01:30
 * secToFormatHours(5100) -> 01:25:00
 * @param sec a number
 * @returns formatted time
 */
export function secToFormatHours(sec: number): string {
  const applyPad = (secondsBis: number): string => {
    return String(secondsBis).padStart(2, '0');
  };

  const hours = Math.floor(sec / 3600);
  const hoursFormatted = applyPad(hours);
  const minutesFormatted = applyPad(Math.floor(Math.floor(sec % 3600) / 60));
  const secondsFormatted = applyPad(Math.floor(sec % 60));

  switch (true) {
    case hours > 0:
      return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
    default:
      return `${minutesFormatted}:${secondsFormatted}`;
  }
}

/**
 * @description Transform a variable into a Date
 * @param dateCandidate the variable to transform
 * @returns Date
 */
export function toDate(dateCandidate: unknown): Date {
  return dateCandidate instanceof Date
    ? dateCandidate
    : new Date(Number(dateCandidate ?? 0));
}

/**
 * @description Transform a number of seconds into an object containing the number of hours, the number of minutes and the number of seconds
 * @param secondsToTransform a number
 * @returns object containing the number of hours, the number of minutes and the number of seconds
 */
export function objectifyDuration(
  secondsToTransform: number,
): Omit<IDiffTime, 'days'> {
  let s = secondsToTransform;
  if (
    Number.isNaN(s) ||
    s === Infinity ||
    typeof secondsToTransform !== 'number'
  ) {
    s = 0;
  }

  const h = Math.floor(s / 3600);
  s -= h * 3600;
  const m = Math.floor(s / 60);
  s -= m * 60;
  const sec = Math.floor(s);

  return { hours: h, minutes: m, seconds: sec };
}

/**
 * @description Transform a date into an object containing the number of hours, the number of minutes and the number of seconds
 * @param time a Date
 * @returns object containing the number of hours, the number of minutes and the number of seconds
 */
export function objectifyLiveDate(time: Date): Omit<IDiffTime, 'days'> {
  const date = new Date(time);

  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  };
}

/**
 * @description Format a Date object into a string of type "11h25m30s" or "11h25m"
 * @param time a Date
 * @param showSeconds : display or not seconds
 * @returns formatted time or null if not given a Date
 */
export function formatLiveDate(time: Date, showSeconds = false): string | null {
  if (!time || !(time instanceof Date)) {
    return null;
  }
  const liveDate = objectifyLiveDate(time);

  const { hours, minutes, seconds } = liveDate;

  const h = String(hours).padStart(2, '0');
  const m = String(minutes).padStart(2, '0');

  if (showSeconds) {
    const s = String(seconds).padStart(2, '0');
    return `${h}h${m}m${s}s`;
  }

  return `${h}h${m}`;
}

/**
 * @description Format a number into a string of type 11:25:30"
 * @param secondsToFormat the number we want to format
 * @returns formatted time
 */
export function formatDuration(secondsToFormat: number): string {
  const { hours, minutes, seconds } = objectifyDuration(secondsToFormat);

  const m = String(minutes).padStart(2, '0');
  const s = String(seconds).padStart(2, '0');

  return hours > 0 ? `${hours}:${m}:${s}` : `${m}:${s}`;
}

/**
 * @description return an object of time difference
 * @param start - start timestamp
 * @param now - current time value
 * @returns - an object of time diff in seconds, minutes, hours, days
 */
export function getTimeDiff(start: number, now: number): IDiffTime {
  const time = now - start;
  return {
    seconds: Math.floor(time / 1000),
    minutes: Math.floor(time / 1000 / 60),
    hours: Math.floor(time / 1000 / 60 / 60),
    days: Math.floor(time / 1000 / 60 / 60 / 24),
  };
}
