import { createSelector } from 'reselect';
export var reloadSelector = function (state) {
    return state.reload;
};
/**
 * @returns {function(object): integer}
 */
export var countdownTimeSelector = createSelector(reloadSelector, function (reload) { return reload.countdownTime; });
/**
 * @returns {function(object): boolean}
 */
export var isCountdownActiveSelector = createSelector(reloadSelector, function (reload) { return reload.isCountdownActive; });
/**
 * @returns {function(object): integer}
 */
export var reloadAttemptSelector = createSelector(reloadSelector, function (reload) { return reload.reloadAttempt; });
