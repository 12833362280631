// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overlay Controls */ /* 2x for when ad title spills over, 1x for gradient */

/* Expert Mode */
`, "",{"version":3,"sources":["webpack://./src/ui/assets/styles/sizes.css"],"names":[],"mappings":"AAAA,qBAAqB,EAIsD,sDAAsD;;AAajI,gBAAgB","sourcesContent":["/* Overlay Controls */\n@value headerCastButtonSizeSmall: 27px;\n@value headerIconButtonSizeSmall: 36px;\n@value headerIconButtonSizeMedium: 43px;\n@value headerMaxHeightMobilePortrait: calc(headerIconButtonSizeSmall * 3); /* 2x for when ad title spills over, 1x for gradient */\n@value sidebarButtonSizeSmall: 36px;\n@value sidebarButtonSizeMedium: 60px;\n@value sidebarButtonSizeMobilePortrait: sidebarButtonSizeMedium;\n@value sidebarPaddedHeightMobilePortrait: calc(sidebarButtonSizeMedium * 2);\n@value controlBarHeight: 50px;\n@value controlBarHeightMedium: 60px;\n@value controlBarHeightLarge: 60px;\n@value controlBarHeightMobilePortrait: 100px;\n@value overlayButtonSpacing: 12px;\n@value overlayButtonBottom: calc(controlBarHeight + overlayButtonSpacing);\n@value overlayButtonBottomMedium: calc(controlBarHeightMedium + overlayButtonSpacing);\n\n/* Expert Mode */\n@value playerIconWidth: 11px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCastButtonSizeSmall": `27px`,
	"headerIconButtonSizeSmall": `36px`,
	"headerIconButtonSizeMedium": `43px`,
	"headerMaxHeightMobilePortrait": `108px`,
	"sidebarButtonSizeSmall": `36px`,
	"sidebarButtonSizeMedium": `60px`,
	"sidebarButtonSizeMobilePortrait": `60px`,
	"sidebarPaddedHeightMobilePortrait": `120px`,
	"controlBarHeight": `50px`,
	"controlBarHeightMedium": `60px`,
	"controlBarHeightLarge": `60px`,
	"controlBarHeightMobilePortrait": `100px`,
	"overlayButtonSpacing": `12px`,
	"overlayButtonBottom": `62px`,
	"overlayButtonBottomMedium": `72px`,
	"playerIconWidth": `11px`
};
module.exports = ___CSS_LOADER_EXPORT___;
