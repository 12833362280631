import { createSelector } from 'reselect';
import { ErrorCodes } from '@canalplus/oneplayer-constants';
var ENCRYPTED = ErrorCodes.ENCRYPTED, MEDIA_ERROR = ErrorCodes.MEDIA_ERROR, PARENTAL_CODE_REQUIRED = ErrorCodes.PARENTAL_CODE_REQUIRED;
var DEFAULT_HIDE_TIMEOUT = 6000; // default hide timeout on error if not explicitly required
export var errorSelector = function (state) {
    return state.error;
};
/**
 * @returns {function(object): boolean}
 */
export var isMediaErrorSelector = createSelector(errorSelector, function (error) {
    return Boolean((error === null || error === void 0 ? void 0 : error.code) === MEDIA_ERROR);
});
/**
 * @returns {function(object): object}
 */
export var hideTimeoutSelector = createSelector(errorSelector, function (error) { return (error === null || error === void 0 ? void 0 : error.hideTimeout) || DEFAULT_HIDE_TIMEOUT; });
/**
 * @returns {function(object): boolean}
 */
export var isFatalErrorSelector = createSelector(errorSelector, function (error) {
    return Boolean(error === null || error === void 0 ? void 0 : error.fatal);
});
/**
 * @returns {function(object): string}
 */
export var errorCodeSelector = createSelector(errorSelector, function (error) { return error === null || error === void 0 ? void 0 : error.code; });
/**
 * @returns {function(object): boolean}
 */
export var isEncryptedErrorSelector = createSelector(errorSelector, function (error) {
    return Boolean((error === null || error === void 0 ? void 0 : error.code) === ENCRYPTED);
});
/**
 * @returns {function(object): boolean}
 */
export var isParentalCodeRequiredErrorSelector = createSelector(errorSelector, function (error) { return Boolean((error === null || error === void 0 ? void 0 : error.code) === PARENTAL_CODE_REQUIRED); });
