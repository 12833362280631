import { createSelector } from 'reselect';
export var moreInfosSelector = function (state) {
    return state.moreInfos;
};
/**
 * @returns whether or not more info is fetching
 */
export var isFetchingMoreInfosSelector = createSelector(moreInfosSelector, function (moreInfos) { return moreInfos.isFetching; });
/**
 * @returns the more infos data
 */
export var moreInfosDataSelector = createSelector(moreInfosSelector, function (moreInfos) { return moreInfos.moreInfosData; });
/**
 * @returns an error related to more info
 */
export var moreInfosHasErrorSelector = createSelector(moreInfosSelector, function (moreInfos) { return moreInfos.hasError; });
