// Check userAgent for Samsung 2018: if it includes "Tizen 4.0" it means we are on
// a Samsung 2018 (this is the only info we have)
// Chromium 56
export const isSamsung2018 = (): boolean =>
  navigator.userAgent.includes('Tizen 4.0');
// Chromium 63
export const isSamsung2019 = (): boolean =>
  navigator.userAgent.includes('Tizen 5.0');
// Chromium 69
export const isSamsung2020 = (): boolean =>
  navigator.userAgent.includes('Tizen 5.5');
// Chromium 76
export const isSamsung2021 = (): boolean =>
  navigator.userAgent.includes('Tizen 6.0');
// Chromium 85
export const isSamsung2022 = (): boolean =>
  navigator.userAgent.includes('Tizen 6.5');
// Chromium 94
export const isSamsung2023 = (): boolean =>
  navigator.userAgent.includes('Tizen 7.0');

// Chromium 53
export const isLG2019 = (): boolean =>
  (navigator.userAgent.includes('Chrome/53') ||
    navigator.userAgent.includes('Chr0me/53')) &&
  (navigator.userAgent.includes('Web0S') ||
    navigator.userAgent.includes('webOS'));
// Chromium 68
export const isLG2020 = (): boolean =>
  (navigator.userAgent.includes('Chrome/68') ||
    navigator.userAgent.includes('Chr0me/68')) &&
  (navigator.userAgent.includes('Web0S') ||
    navigator.userAgent.includes('webOS'));
// Chromium 79
export const isLG2021 = (): boolean =>
  (navigator.userAgent.includes('Chrome/79') ||
    navigator.userAgent.includes('Chr0me/79')) &&
  (navigator.userAgent.includes('Web0S') ||
    navigator.userAgent.includes('webOS'));
// Chromium 87
export const isLG2022 = (): boolean =>
  (navigator.userAgent.includes('Chrome/87') ||
    navigator.userAgent.includes('Chr0me/87')) &&
  (navigator.userAgent.includes('Web0S') ||
    navigator.userAgent.includes('webOS'));

// Chromium 77
export const isHisense2020 = (): boolean =>
  navigator.userAgent.includes('VIDAA/4.0');
// Chromium 84
export const isHisense2021 = (): boolean =>
  navigator.userAgent.includes('VIDAA/5.0');
// Chromium 88
export const isHisense2022 = (): boolean =>
  navigator.userAgent.includes('VIDAA/6.0');
// Chromium 100
export const isHisense2023 = (): boolean =>
  navigator.userAgent.includes('VIDAA/7.0');

// Safari 9 - WebKit 601.2
export const isPlaystation4 = (): boolean =>
  navigator.userAgent.includes('PlayStation 4') ||
  navigator.userAgent.includes('Playstation 4');
// Safari 11.1 - WebKit 605.1.15
export const isPlaystation5 = (): boolean =>
  navigator.userAgent.includes('PlayStation 5') ||
  navigator.userAgent.includes('Playstation 5');

// Chromium 55
export const isOrangeTvKey = (): boolean =>
  navigator.userAgent.includes('StickTV');
export const isXbox = (): boolean => navigator.userAgent.includes('Xbox');
