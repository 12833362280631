import flatMap from './lodash/flatMap';
import matchAll from './string/matchAll';

/**
 * Return the micro eligibility associated to a Macro
 * as a list of string
 * @param {string} macro
 * @param {string} microEligibility
 * @returns {Array<string>}
 */
export function getMicros(macro: string, microEligibility: string): string[] {
  const reg = new RegExp(`\\b${macro}:\\[(.*?)\\]`);
  const res = reg.exec(microEligibility);
  if (res !== null) {
    return res[1].split(',');
  }
  return [];
}

/**
 * Returns the list of all the micro associated to a list
 * of macro.
 * @export
 * @param {string} microEligibility
 * @param {Array<string>} macros
 * @returns {Array<string>}
 */
export function extractOffers(
  microEligibility: string,
  macros?: string[],
): string[] {
  if (!microEligibility) {
    return [];
  }

  let offers: string[] = [];
  if (macros) {
    macros.forEach((macro) => {
      offers = offers.concat(getMicros(macro, microEligibility));
    });
  } else {
    offers = flatMap(
      matchAll(microEligibility, /\[(.*?)\]+/g),
      (capture: string[]) => capture[1].split(','),
    );
  }
  return offers.filter((name) => name !== undefined && name !== '').sort();
}

/**
 * Returns true if the user is logged in
 * @export
 * @param {object} passInfo
 * @param {string} passInfo.passToken
 * @returns {boolean}
 */
export function isUserLogged({ passToken }: { passToken: string }): boolean {
  return passToken?.[0] !== '0';
}

/**
 * Returns true if the user is subscribed
 * @export
 * @param {object} passInfo
 * @param {string} passInfo.passToken
 * @param {string} passInfo.microEligibility
 * @returns {boolean}
 */
export function isUserSubscriber({
  passToken,
  microEligibility,
}: {
  passToken: string;
  microEligibility: string;
}): boolean {
  return (
    isUserLogged({ passToken }) &&
    extractOffers(microEligibility, ['OFFER']).includes('O_ABO')
  );
}

/**
 * Returns the login status of a user
 * @export
 * @param {object} passInfo
 * @param {string} passInfo.passToken
 * @param {string} passInfo.microEligibility
 * @returns {string} 'non_logged' | 'non_subscriber' | 'subscriber'
 */
export function getUserLoginStatus({
  passToken,
  microEligibility,
}: {
  passToken: string;
  microEligibility: string;
}): 'non_logged' | 'non_subscriber' | 'subscriber' {
  const nonLoggedStatus = 'non_logged';
  const nonSubscriberStatus = 'non_subscriber';
  const subscriberStatus = 'subscriber';
  const isLogged = isUserLogged({ passToken });

  if (!isLogged) {
    return nonLoggedStatus;
  }

  const isSubscriber = isUserSubscriber({ passToken, microEligibility });

  return isSubscriber ? subscriberStatus : nonSubscriberStatus;
}
