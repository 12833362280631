var _a = require('../assets/styles/sizes.css'), headerMaxHeightMobilePortrait = _a.headerMaxHeightMobilePortrait, controlBarHeightMobilePortrait = _a.controlBarHeightMobilePortrait, sidebarPaddedHeightMobilePortrait = _a.sidebarPaddedHeightMobilePortrait;
var headerHeightMP = parseInt(headerMaxHeightMobilePortrait, 10);
var controlBarHeightMP = parseInt(controlBarHeightMobilePortrait, 10);
var sidebarHeightMP = parseInt(sidebarPaddedHeightMobilePortrait, 10);
/**
 * @param {number} videoWidth (pixels)
 * @returns {number} (pixels)
 */
export var calculateVideoHeight = function (videoWidth) {
    return videoWidth * 0.5625;
}; // 16:9 aspect ratio
/**
 * @param {number} frameHeight (pixels)
 * @param {number} videoHeight (pixels)
 * @returns {number} (pixels)
 */
export var getMobilePortraitVideoTop = function (frameHeight, videoHeight) { return frameHeight / 2 - videoHeight / 2; };
/**
 * @param {number} frameHeight (pixels)
 * @param {number} videoHeight (pixels)
 * @returns {number} (pixels)
 */
export var getMobilePortraitVideoBottom = function (frameHeight, videoHeight) { return frameHeight / 2 + videoHeight / 2; };
/**
 * @param {number} frameHeight (pixels)
 * @param {number} videoHeight (pixels)
 * @returns {number} (pixels)
 */
export var getMobilePortraitSidePanelHeight = function (frameHeight, videoHeight) { return frameHeight - videoHeight; };
/**
 * @param {number} videoBottom (pixels)
 * @param {number} controlBarHeight (pixels)
 * @returns {number} (pixels)
 */
export var getMobilePortraitSidebarTop = function (videoBottom, controlBarHeight) { return videoBottom + controlBarHeight; };
/**
 * @param {boolean} isMobilePortraitLayout
 * @param {number} frameHeight (pixels)
 * @param {number} mobilePortraitVideoHeight (pixels)
 * @returns {number} (pixels)
 */
export var getVideoHeight = function (isMobilePortraitLayout, frameHeight, mobilePortraitVideoHeight) {
    if (isMobilePortraitLayout) {
        return mobilePortraitVideoHeight;
    }
    return frameHeight;
};
/**
 * @param {boolean} isControlbarVisible
 * @param {boolean} isMobilePortraitLayout
 * @returns {boolean}
 */
export var isBottomOverlayElementRaised = function (isMobilePortraitLayout, isControlbarVisible) { return !isMobilePortraitLayout && isControlbarVisible; };
/**
 * @param {number} videoHeight (pixels)
 * @param {number} frameWidth (pixels)
 * @param {number} frameHeight (pixels)
 * @returns {boolean}
 */
export var isMobilePortraitLayout = function (videoHeight, frameWidth, frameHeight) {
    if (frameWidth > 600) {
        return false;
    }
    var sumOfControlHeights = headerHeightMP + videoHeight + controlBarHeightMP + sidebarHeightMP;
    return sumOfControlHeights < frameHeight;
};
/**
 * Returns an array of image objects if they could be loaded with the url array provided in parameter
 * if image can't be loaded it will returned as null
 * @param {Array<object>} urls
 * @returns {Promise<Array<HTMLImageElement[] | null>>}
 */
export var transformUrlsIntoImages = function (urlList) {
    return Promise.all(urlList.map(function (imageRaw) {
        return new Promise(function (resolve) {
            var img = new Image();
            img.onload = function () {
                resolve(img);
            };
            img.onerror = function () {
                // We don't want to reject the whole promise if one image fails to load
                // In the future we will use promise.allsettled() to reject one promise only and not all promises
                resolve(null);
            };
            img.src = imageRaw.url;
            if (imageRaw.options) {
                img.style.top = imageRaw.options.top ? imageRaw.options.top : '';
                img.style.left = imageRaw.options.left ? imageRaw.options.left : '';
            }
        });
    }));
};
